.privacy-policy-container{
    width: 60%;
    margin: 20px auto;
    color: #3C3C3C;
}

.privacy-policy-title{
    font-weight: 600;
    font-size: 28px;
    letter-spacing: 0.5px;

}

.privacy-policy-intro{
    margin: 15px 0px;
    font-size: 14px;
    text-align: justify;
    font-weight: 500;

}

.privacy-policy-list {
    font-size: 17px;
    font-weight: 700;
    margin: 0 20px;
  }

  .privacy-policy-section{
    margin: 20px 0px;
  }


.privacy-policy-subtitle{
    font-size: 17px;
    font-weight: 700;
    margin-bottom: 10px;
}

.privacy-policy-details{
    margin-left: 35px;
    text-align: justify;
    list-style-type: disc;
}

.privacy-policy-detail{
    font-size: 13px;
    font-weight: 500;
    line-height: 18px;
    margin: 5px 0px;
}

.privacy-policy-consent{
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0.2px;
    margin-top: 15px;
    text-align: justify;
}

@media screen and (max-width: 2570px) {
    .privacy-policy-container{
        width: 50%;
        margin: 60px auto;
    }
    
    .privacy-policy-title{
        font-weight: 600;
        font-size: 35px;    
    }
    
    .privacy-policy-intro{
        margin: 15px 0px;
        font-size: 21px;
        line-height:30px ;
    }
    
    .privacy-policy-list {
        font-size: 22px;
      }
    
    
    
    .privacy-policy-subtitle{
        font-size: 22px;
        font-weight: 700;
    }
    
    .privacy-policy-detail{
        font-size: 19px;
        margin: 10px 0px;
        line-height: 28px;
    }
    
    .privacy-policy-consent{
        font-size: 23px;
        line-height: 30px;
    }
}

@media screen and (max-width: 2000px) {
    .privacy-policy-container{
        width: 60%;
        margin: 60px auto;
    }
    
    .privacy-policy-title{
        font-weight: 600;
        font-size: 28px;    
    }
    
    .privacy-policy-intro{
        margin: 15px 0px;
        font-size: 16px;
        line-height:25px ;
    }
    
    .privacy-policy-list {
        font-size: 20px;
      } 
    
    .privacy-policy-subtitle{
        font-size: 20px;
    }
    
    .privacy-policy-detail{
        font-size: 16px;
        margin: 10px 0px;
        line-height: 25px;
    }
    
    .privacy-policy-consent{
        font-size: 17px;
        line-height: 25px;
    }
}

@media screen and (max-width: 1400px) {
    .privacy-policy-container{
        width: 60%;
        margin: 40px auto;
    }
    
    .privacy-policy-title{
        font-size: 25px;    
    }
    
    .privacy-policy-intro{
        font-size: 13px;
        line-height:20px ;
    }
    
    .privacy-policy-list {
        font-size: 16px;
      } 
    
    .privacy-policy-subtitle{
        font-size: 16px;
    }
    
    .privacy-policy-detail{
        font-size: 13px;
        line-height: 20px;
    }
    
    .privacy-policy-consent{
        font-size: 14px;
        line-height: 20px;
    }
}

@media screen and (max-width: 1100px) {
    .privacy-policy-container{
        width: 70%;
        margin: 30px auto;
    }
    
    .privacy-policy-title{
        font-size: 25px;    
    }
    
    .privacy-policy-intro{
        font-size: 13px;
        line-height:20px ;
    }
    
    .privacy-policy-list {
        font-size: 15px;
      } 
    
    .privacy-policy-subtitle{
        font-size: 15px;
    }
    
    .privacy-policy-detail{
        font-size: 13px;
        line-height: 20px;
    }
    
    .privacy-policy-consent{
        font-size: 14px;
        line-height: 20px;
    }
}

@media screen and (max-width: 950px) {
    .privacy-policy-container{
        width: 90%;
        margin: 30px auto;
    }
    
    .privacy-policy-title{
        font-size: 25px;    
    }
    
    .privacy-policy-intro{
        font-size: 15px;
        line-height:24px ;
    }
    
    .privacy-policy-list {
        font-size: 17px;
      } 
    
    .privacy-policy-subtitle{
        font-size: 17px;
    }
    
    .privacy-policy-detail{
        font-size: 15px;
        line-height: 24px;
    }
    
    .privacy-policy-consent{
        font-size: 16px;
        line-height: 24px;
    }
}

@media screen and (max-width: 850px) {
    
    .privacy-policy-list {
        font-size: 16px;
      } 
    
    .privacy-policy-subtitle{
        font-size: 16px;
    }

}

@media screen and (max-width: 600px) {
    .privacy-policy-container{
        width: 95%;
        margin: 30px auto;
    }
    
    .privacy-policy-title{
        font-size: 22px;    
    }
    
    .privacy-policy-intro{
        font-size: 13px;
        line-height:20px ;
    }
    
    .privacy-policy-list {
        font-size: 14px;
      } 
    
    .privacy-policy-subtitle{
        font-size: 14px;
    }
    
    .privacy-policy-detail{
        font-size: 13px;
        line-height: 20px;
    }
    
    .privacy-policy-consent{
        font-size: 15px;
        line-height: 20px;
    }
}

@media screen and (max-width: 450px) {
    .privacy-policy-container{
        width: 95%;
        margin: 30px auto;
    }
    
    .privacy-policy-title{
        font-size: 20px;    
    }
    
    .privacy-policy-intro{
        font-size: 13px;
        line-height:20px ;
        margin: 12px 0px;
    }
    
    .privacy-policy-section {
        margin: 15px 0px;
    }

    .privacy-policy-list {
        font-size: 14px;
        margin: 0 15px;
      } 
    
    .privacy-policy-subtitle{
        font-size: 14px;
    }

    .privacy-policy-details {
        margin-left: 25px;
    }
    
    .privacy-policy-detail{
        font-size: 13.5px;
        line-height: 20px;
        margin: 10px 0px;
    }
    
    .privacy-policy-consent{
        font-size: 14.5px;
        line-height: 20px;
    }
}

@media screen and (max-width: 450px) {
    .privacy-policy-container{
        width: 95%;
        margin: 25px auto;
    }
    
    .privacy-policy-title{
        font-size: 20px;    
    }
    
    .privacy-policy-intro{
        font-size: 13.5px;
        line-height:20px ;
        margin: 10px 0px;
    }
    
    .privacy-policy-section {
        margin: 12px 0px;
    }

    .privacy-policy-list {
        font-size: 14px;
        margin: 0 15px;
      } 
    
    .privacy-policy-subtitle{
        font-size: 14px;
    }

    .privacy-policy-details {
        margin-left: 25px;
    }
    
    .privacy-policy-detail{
        font-size: 13.5px;
        line-height: 20px;
        margin: 10px 0px;
    }
    
    .privacy-policy-consent{
        font-size: 14.5px;
        line-height: 20px;
    }
}

@media screen and (max-width: 400px) {
    .privacy-policy-container{
        width: 95%;
        margin: 25px auto;
    }
    
    .privacy-policy-title{
        font-size: 18px;    
    }
    
    .privacy-policy-intro{
        font-size: 13px;
        line-height:20px ;
        margin: 10px 0px;
    }
    
    .privacy-policy-section {
        margin: 12px 0px;
    }

    .privacy-policy-list {
        font-size: 14px;
        margin: 0 15px;
      } 
    
    .privacy-policy-subtitle{
        font-size: 14px;
    }

    .privacy-policy-details {
        margin-left: 25px;
    }
    
    .privacy-policy-detail{
        font-size: 13px;
        line-height: 20px;
        margin: 10px 0px;
    }
    
    .privacy-policy-consent{
        font-size: 14px;
        line-height: 20px;
    }
}