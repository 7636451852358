.announcement {
  width: 70%;
  margin-right: auto;
  margin-left: auto;
}

.announcement-title {
  text-align: center;
  font-size: 40px;
  font-weight: 600;
  color: #f9912d;
}

.announcement-content {
  text-align: justify;
  margin: 45px 0px;
}

.announcement-content p {
  font-size: 14px;
  color: #5a5656;
  margin-bottom: 25px;
}

.announcement-gratitude {
  color: #f37747;
  font-weight: 700;
}

.announcement-info-section {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding: 35px;
  box-shadow: 0px 2px 5px gray;
  border-radius: 12px;
}

.announcement-info-block {
  width: 80%;
  margin: 0px auto;
}

.announcement-info-title {
  text-align: center;
  text-align: center;
  font-size: 27px;
  font-weight: 600;
  color: #f9912d;
  padding: 5px;
  border: 1px solid #f9912d;
  width: 260px;
  border-radius: 5px;
  margin: 0px auto;
}

.announcement-info-subsection {
  margin: 10px 0px;
  font-size: 14.5px;
  text-align: center;
  color: #5a5656;
}

.announcement-info-subsection h6 {
  margin-bottom: 5px;
}

.announcement-info-benefits {
  width: 85%;
  margin: 15px auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.announcement-info-benefit-list p {
  display: flex;
  align-items: center;
  color: #5a5656;
  font-size: 12.5px;
  margin: 6px 0px;
}

.announcement-benefit-list-icon {
  color: #f9912d;
  margin-right: 8px;
}

.announcement-info-summary {
  text-align: justify;
  color: #5a5656;
  font-weight: 600;
  width: 85%;
  margin: 10px auto;
  padding: 10px 5px;
  font-size: 14px;
}

.announcement-designer-section {
  width: 85%;
  margin: 20px auto 0px auto;
  padding: 30px;
  box-shadow: 0px 2px 4px gray;
  border-radius: 8px;
}

.announcement-designer-title {
  text-align: center;
  color: #5a5656;
  font-weight: 600;
  font-size: 22px;
}

.announcement-designer-subtitle {
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  color: #f9912d;
  padding: 7px 5px;
  border: 1px solid #f9912d;
  width: 240px;
  border-radius: 7px;
  margin: 30px auto;
  letter-spacing: 0.7px;
}

.announcement-designer-benefits {
  text-align: center;
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 0.3px;
  color: #5a5656;
}

.announcement-designer-description {
  width: 90%;
  margin: 15px auto;
  color: #5a5656;
  font-weight: 600;
  font-size: 13px;
  text-align: center;
  line-height: 18px;
}

.designer-signup-button {
  display: flex;
  justify-content: center;
}

.designer-signup-button button {
  background: linear-gradient(to right, #eb4819 0%, #f78f5f 49%, #ff8d1b 100%);
  border: none;
  text-align: center;
  margin: 10px auto;
  padding: 10px 15px;
  border-radius: 6px;
  cursor: pointer;
  font-weight: 500;
  color: #fff;
  font-size: 16px;
  letter-spacing: 0.9px;
}

.announcement-endline {
  text-align: center;
  font-size: 9px;
  color: #5a5656;
  font-weight: 600;
  letter-spacing: 0.3px;
}

.membership-section {
  width: 60%;
  margin: 50px auto 20px auto;
}

.mobile-membership-heading {
  display: none;
}

.membership-heading {
  width: 100%;
  position: relative;
}

.membership-type {
  padding: 7px 10px;
  border: 1.5px solid #f9912d;
  color: #f9912d;
  font-weight: 600;
  text-align: center;
  font-size: 20px;
  border-radius: 10px;
  width: 100%;
  z-index: -1;
  margin-bottom: 15px;
}

.membership-type-icon {
  width: 40px;
  height: 40px;
  float: right;
  right: -10px;
  top: -10px;
  color: #00a878;
  position: absolute;
}

.membership-details-container{
  display: flex;
  gap: 20px;
}

.membership-details-content {
  position: relative;
  width: 100%;

}

.membership-details {
  padding: 25px 15px;
  box-shadow: 0px 2px 4px rgb(177, 176, 176);
  border: 1px solid rgb(223, 220, 220);
  border-radius: 12px;
  height: 100%;
}

.membership-title {
  text-align: center;
  font-size: 28px;
  font-weight: 600;
  color: #5a5656;
}

.membership-subtitle {
  text-align: center;
  margin: 15px 0px;
  font-size: 12px;
  font-weight: 500;
  color: #5a5656;
}

.membership-price {
  text-align: center;
  font-size: 15px;
  color: #5a5656;
}

.membership-price span {
  font-size: 24px;
  font-weight: 500;
}

.membership-description {
  margin: 15px 20px;
  text-align: justify;
  font-size: 12px;
  font-weight: 500;
  color: #5a5656;
}

.membership-benefits {
  margin: 15px 20px;
  font-size: 12px;
  color: #5a5656;
}

.membership-benefits p {
  margin-bottom: 10px;
}

.membership-or {
  margin: 15px 0px;
  text-align: center;
}

.membership-referral {
  margin: 10px 20px 0px 20px;
  font-weight: 600;
  font-size: 12px;
  text-align: center;
  letter-spacing: 1px;
  line-height: 22px;
}

.membership-referral-info {
  font-size: 15px;
  margin-bottom: -3px;
  color: #bababa;
  cursor: pointer;
}

.membership-referral-info:hover {
  color: #f9912d;
}

.membership-quotation-days-section {
  display: flex;
  flex-direction: column-reverse;

  align-items: center;
}

.membership-quotation-details-parent {
  margin-top: 16px;
  width: 100px;
  position: relative;
  height: 100px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: -14px;
}

.membership-quotation-details-child {
  position: absolute;
  top: -1px;
  left: 21px;
  border-radius: 50%;
  background: linear-gradient(163.23deg, #dee2e7, #dbe0e7);
  box-shadow: 0 15px 30px rgba(142, 155, 174, 0.2),
    0 3px 8px 1px rgba(68, 17, 85, 0.1) inset;
  border: 1px solid rgba(255, 255, 255, 0.5);
  box-sizing: border-box;
  width: 66.1px;
  height: 66.1px;
}
.membership-quotation-details-item {
  top: 2.9px;
  left: 25px;
  background: linear-gradient(90deg, #eb4819, #f78f5f 48.71%, #ff8d1b);
  width: 58.9px;
  height: 58.3px;
}
.membership-quotation-details-div,
.membership-quotation-details-inner,
.membership-quotation-details-item {
  position: absolute;
  border-radius: 50%;
}
.membership-quotation-details-inner {
  top: 5.2px;
  left: 27px;
  background: linear-gradient(145.7deg, #f5f5f9, rgba(228, 232, 238, 0));
  box-shadow: 3px 3px 8px rgba(255, 255, 255, 0.15) inset,
    0 8px 15px rgba(142, 155, 174, 0.5), 0 31px 60px rgba(142, 155, 174, 0.2);
  border: 1px solid var(--color-white);
  box-sizing: border-box;
  width: 53.7px;
  height: 53.7px;
}
.membership-quotation-details-div {
  top: 8px;
  left: 30px;
  background: linear-gradient(
    90deg,
    rgba(235, 72, 25, 0.8),
    rgba(247, 143, 95, 0.8) 48.71%,
    rgba(255, 141, 27, 0.8)
  );
  width: 48px;
  height: 48px;
}

.membership-quotation-days-remaining,
.membership-quotation-days {
  position: absolute;
  display: flex;
  align-items: center;
}
.membership-quotation-days {
  top: 29.4px;
  right: calc(50% - 14.5px);
  letter-spacing: 0.29px;
  line-height: 32px;
  justify-content: center;
  width: 18.8px;
  height: 6.4px;
  color: #fff;
  font-size: 27px;
  font-family: "Montserrat", sans-serif;
}
.membership-quotation-days-remaining {
  font-weight: 500;
  text-align: center;
  font-size: 13.5px;
  color: #00a878;
  margin-left: 14px;
  letter-spacing: 0.5px;
}

.buy-now-button {
  width: 100%;
  margin-top: 40px;
  padding: 10px;
  font-size: 20px;
  font-weight: 600;
  color: #f9912d;
  border: 1.5px solid #f9912d;
  background-color: white;
  border-radius: 10px;
  letter-spacing: 0.5px;
  cursor: pointer;
}

.buy-now-button:hover {
  background: linear-gradient(to right, #eb4819 0%, #f78f5f 49%, #ff8d1b 100%);
  color: #fff;
  transform: scale(1.03);
  box-shadow: 0 2px 4px rgb(203, 201, 201);
}

@keyframes flip {
  from {
    transform: rotateY(90deg);
    opacity: 0;
  }
  to {
    transform: rotateY(0deg);
    opacity: 1;
  }
}

.referral-instructions-content {
  width: 100%;
  position: absolute;
  padding: 20px;
  animation: flip 0.3s ease-in-out;
  transform-origin: left;
  bottom: 10%;
}

.referral-instructions-details {
  background-color: rgba(255, 219, 195, 0.96);
  padding: 15px 25px;
  border-radius: 30px;
  position: relative;
}

.referral-close {
  position: absolute;
  right: 10px;
}

.referral-close-icon {
  float: right;
  background-color: #fff;
  border-radius: 50%;
  border: 1px solid #f9912d;
  color: #f9912d;
  width: 30px;
  font-size: 30px;
  padding: 1px;
  cursor: pointer;
}

.referral-close-icon:hover {
  color: #fff;
  background-color: #f9912d;
}

.referral-instructions {
  padding-top: 10px;
}

.referral-details {
  color: #5a5656;
  font-size: 13px;
  margin-bottom: 20px;
  letter-spacing: 0.4px;
  font-weight: 600;
  line-height: 23px;
}

.membership-read-more{
  color: #f9912d;
  font-weight: 500;
  text-align: center;
  font-size: 12px;
 margin: 5px auto;
 cursor: pointer;
 text-decoration: underline;
 text-underline-offset: 2px;
}

.membership-read-more:hover{
  font-weight: 600;
}

.referral-details span{
  color: #f9912d;
  cursor: pointer;
  border: 1px solid #f9912d;
  padding:3px 5px;
  background-color: #fff;
  border-radius: 5px;
}

.referral-details span:hover{
  background: linear-gradient(to right, #eb4819 0%, #f78f5f 49%, #ff8d1b 100%);
  color: #fff;
}

@media screen and (max-width: 2570px) {
  .announcement {
    margin-top: 40px;
  }

  .announcement-title {
    font-size: 50px;
  }

  .announcement-content p {
    font-size: 23px;
  }

  .announcement-info-title {
    font-size: 35px;
    width: 300px;
  }

  .announcement-info-subsection {
    font-size: 25px;
  }

  .announcement-info-benefit-list p {
    font-size: 25px;
  }

  .announcement-info-summary {
    font-size: 26px;
  }

  .announcement-designer-title {
    font-size: 30px;
  }

  .announcement-designer-subtitle {
    font-size: 22px;
    width: 300px;
    padding: 10px;
  }

  .announcement-designer-benefits {
    font-size: 18px;
  }

  .announcement-designer-description {
    font-size: 22px;
    line-height: 25px;
  }

  .designer-signup-button button {
    font-size: 20px;
  }

  .announcement-endline {
    font-size: 16px;
  }

  .signup-announcement {
    width: 90%;
    margin-top: 50px;
  }

  .signup-announcement-info-section {
    margin: 30px 0px;
    padding: 50px;
  }

  .signup-announcement-info-block {
    width: 100%;
  }

  .signup-announcement-info-title {
    width: 470px;
    padding: 8px;
  }

  .signup-announcement-info-subsection {
    font-size: 30px;
    margin: 40px 0px;
  }

  .signup-announcement-info-benefits {
    width: 75%;
  }

  .signup-announcement-info-benefit-list p {
    font-size: 24px;
    color: #5a5656;
    margin-bottom: 15px;
  }

  .signup-announcement-info-summary {
    width: 80%;
    font-size: 28px;
    text-align: center;
    line-height: 25px;
    margin: 0 auto;
  }

  .membership-section {
    margin: 50px auto 20px auto;
  }

  .membership-type {
    padding: 10px;
    font-size: 35px;
  }


  .membership-title {
    font-size: 45px;
  }

  .membership-subtitle {
    margin: 15px 0px;
    font-size: 22px;
  }

  .membership-price {
    font-size: 20px;
  }

  .membership-price span {
    font-size: 35px;
  }

  .membership-description {
    margin: 45px 20px;
    font-size: 23px;
    text-align: center;
    line-height: 25px;
    letter-spacing: 0.5px;
  }

  .membership-benefits {
    margin: 20px;
    font-size: 24px;
  }

  .membership-benefits p {
    margin-bottom: 18px;
  }

  .membership-or {
    font-size: 30px;
  }

  .membership-referral {
    font-size: 22px;
    line-height: 34px;
  }

  .membership-read-more{
    font-size: 20px;
  }

  .membership-referral-info {
    font-size: 30px;
    margin-left: 2px;
    margin-bottom: -4px;
  }



  .membership-quotation-details-parent {
    width: 130px;
    height: 130px;
    margin-bottom: 3px;
  }

  .membership-quotation-details-child {
    width: 96.1px;
    height: 96.1px;
  }
  .membership-quotation-details-item {
    width: 88.9px;
    height: 88.3px;
  }

  .membership-quotation-details-inner {
    width: 83.7px;
    height: 83.7px;
  }
  .membership-quotation-details-div {
    width: 78px;
    height: 78px;
  }

  .membership-quotation-days {
    top: 45.4px;
    font-size: 45px;
  }
  .membership-quotation-days-remaining {
    font-size: 21.5px;
  }

  .buy-now-button {
    font-size: 25px;
    padding: 15px;
    margin-top: 30px;
  }

  .referral-instructions-content {
    padding: 40px;
  }
  
  .referral-instructions-details {
    background-color: rgba(255, 219, 195, 0.96);
    padding: 25px 35px;
    border-radius: 30px;
  }
  
  
  .referral-close-icon {
    width: 45px;
    font-size: 45px;
  }
  
  .referral-instructions {
    padding-top: 20px;
  }
  
  .referral-details {
    font-size: 20px;
    margin-bottom: 20px;
    line-height: 35px;
  }
}

@media screen and (max-width: 1950px) {
  .announcement-title {
    font-size: 45px;
  }

  .announcement-content p {
    font-size: 17px;
  }

  .announcement-info-title {
    font-size: 30px;
    width: 280px;
  }

  .announcement-info-subsection {
    font-size: 18px;
  }

  .announcement-info-benefit-list p {
    font-size: 15.5px;
  }

  .announcement-info-summary {
    font-size: 16px;
  }

  .announcement-designer-title {
    font-size: 25px;
  }

  .announcement-designer-subtitle {
    font-size: 18px;
  }

  .announcement-designer-benefits {
    font-size: 14px;
  }

  .announcement-designer-description {
    font-size: 16px;
    line-height: 20px;
  }

  .designer-signup-button button {
    font-size: 18px;
  }

  .announcement-endline {
    font-size: 11px;
  }

  .signup-announcement {
    width: 90%;
  }

  .signup-announcement-info-section {
    margin: 30px 0px;
    padding: 30px 30px 15px 30px;
  }

  .signup-announcement-info-title {
    width: 400px;
    font-size: 25px;
  }

  .signup-announcement-info-subsection {
    font-size: 18px;
    margin: 20px 0px;
  }

  .signup-announcement-info-benefits {
    width: 80%;
  }

  .signup-announcement-info-benefit-list p {
    font-size: 15.5px;
    margin-bottom: 9px;
  }

  .signup-announcement-info-summary {
    width: 95%;
    font-size: 16.5px;
  }

  /* .membership-section {
    width: 450px;
  } */

  .membership-type {
    font-size: 25px;
  }

  .membership-title {
    font-size: 35px;
  }

  .membership-subtitle {
    font-size: 17px;
  }

  .membership-price {
    font-size: 18px;
  }

  .membership-price span {
    font-size: 35px;
  }

  .membership-description {
    margin: 35px 20px;
    font-size: 20px;
  }

  .membership-benefits {
    margin: 20px;
    font-size: 18px;
  }

  .membership-benefits p {
    margin-bottom: 14px;
  }

  .membership-or {
    font-size: 25px;
  }

  .membership-referral {
    font-size: 19px;
    line-height: 34px;
  }

  .membership-read-more{
    font-size: 13px;
  }

  .membership-referral-info {
    font-size: 25px;
  }

  .membership-quotation-details-parent {
    width: 110px;
    height: 110px;
    margin-bottom: -3px;
  }

  .membership-quotation-details-child {
    width: 76.1px;
    height: 76.1px;
  }
  .membership-quotation-details-item {
    width: 68.9px;
    height: 68.3px;
  }

  .membership-quotation-details-inner {
    width: 63.7px;
    height: 63.7px;
  }
  .membership-quotation-details-div {
    width: 58px;
    height: 58px;
  }

  .membership-quotation-days {
    top: 35.4px;
    font-size: 34px;
  }
  .membership-quotation-days-remaining {
    font-size: 19px;
  }

  .buy-now-button {
    font-size: 23px;
    padding: 10px;
  }

  .referral-instructions-content {
    padding: 20px;
  }
  
  .referral-instructions-details {
    background-color: rgba(255, 219, 195, 0.96);
    padding: 15px 20px;
    border-radius: 30px;
  }
  
  
  .referral-close-icon {
    width: 30px;
    font-size: 30px;
  }
  
  .referral-instructions {
    padding-top: 20px;
  }
  
  .referral-details {
    font-size: 17px;
    margin-bottom: 20px;
    line-height: 30px;
  }
}



@media screen and (max-width: 1400px) {
  .announcement {
    width: 65%;
  }

  .announcement-title {
    font-size: 37px;
  }

  .announcement-content p {
    font-size: 13px;
  }

  .announcement-info-title {
    font-size: 25px;
    width: 260px;
  }

  .announcement-info-subsection {
    font-size: 14.5px;
  }

  .announcement-info-benefit-list p {
    font-size: 12.5px;
  }

  .announcement-info-summary {
    font-size: 14px;
  }

  .announcement-designer-title {
    font-size: 22px;
  }

  .announcement-designer-subtitle {
    font-size: 15px;
    width: 240px;
  }

  .announcement-designer-benefits {
    font-size: 11px;
  }

  .announcement-designer-description {
    font-size: 13px;
    line-height: 18px;
  }

  .designer-signup-button button {
    font-size: 16px;
  }

  .announcement-endline {
    font-size: 9px;
  }

  .signup-announcement {
    width: 90%;
  }

  .signup-announcement-title {
    font-size: 32px;
  }

  .signup-announcement-info-section {
    margin: 30px 0px;
    padding: 20px 30px 15px 30px;
  }

  .signup-announcement-info-title {
    font-size: 20px;
    width: 360px;
  }

  .signup-announcement-info-subsection {
    font-size: 17px;
    margin: 15px 0px;
  }

  .signup-announcement-info-benefits {
    width: 90%;
  }

  .signup-announcement-info-benefit-list p {
    font-size: 14px;
  }

  .signup-announcement-info-summary {
    width: 70%;
    font-size: 15.5px;
  }


  .membership-type {
    font-size: 18px;
  }

  .membership-title {
    font-size: 24px;
  }

  .membership-subtitle {
    font-size: 12px;
  }

  .membership-price {
    font-size: 14px;
  }

  .membership-price span {
    font-size: 25px;
  }

  .membership-description {
    margin: 25px 20px;
    font-size: 14px;
  }

  .membership-benefits {
    margin: 20px;
    font-size: 13px;
  }

  .membership-benefits p {
    margin-bottom: 10px;
  }

  .membership-or {
    font-size: 18px;
  }

  .membership-referral {
    font-size: 13.5px;
    line-height: 20px;
  }

  .membership-read-more{
    font-size: 12px;
  }

  .membership-referral-info {
    font-size: 16px;
  }

  .membership-quotation-details-parent {
    width: 100px;
    height: 100px;
    margin-bottom: -10px;
  }

  .membership-quotation-details-child {
    width: 66.1px;
    height: 66.1px;
  }
  .membership-quotation-details-item {
    width: 58.9px;
    height: 58.3px;
  }

  .membership-quotation-details-inner {
    width: 53.7px;
    height: 53.7px;
  }
  .membership-quotation-details-div {
    width: 48px;
    height: 48px;
  }

  .membership-quotation-days {
    top: 29.4px;
    font-size: 27px;
  }
  .membership-quotation-days-remaining {
    font-size: 14px;
  }

  .buy-now-button {
    font-size: 17px;
  }

  .referral-instructions-content {
    padding: 20px;
  }
  
  .referral-instructions-details {
    background-color: rgba(255, 219, 195, 0.96);
    padding: 15px 20px;
    border-radius: 30px;
  }
  
  
  .referral-close-icon {
    width:18px;
    font-size: 18px;
  }
  
  .referral-instructions {
    padding-top: 10px;
  }
  
  .referral-details {
    font-size: 12px;
    margin-bottom: 15px;
    line-height: 25px;
  }
}

@media screen and (max-width: 1100px) {
  .announcement-title {
    font-size: 35px;
  }

  .announcement-content p {
    font-size: 11.5px;
  }

  .announcement-info-title {
    font-size: 22px;
    width: 240px;
  }

  .announcement-info-benefits {
    width: 90%;
  }

  .announcement-info-benefit-list p {
    font-size: 11.5px;
  }

  .announcement-info-summary {
    width: 90%;
    font-size: 12px;
  }

  .announcement-designer-title {
    font-size: 20px;
  }

  .announcement-designer-subtitle {
    font-size: 13px;
    width: 220px;
  }

  .announcement-designer-benefits {
    font-size: 10px;
  }

  .announcement-designer-description {
    font-size: 11.5px;
    line-height: 16px;
  }

  .designer-signup-button button {
    font-size: 13px;
  }

  .announcement-endline {
    font-size: 8px;
  }

  .signup-announcement {
    width: 90%;
  }

  .signup-announcement-title {
    font-size: 30px;
  }

  .signup-announcement-info-section {
    margin: 25px 0px;
    padding: 30px 30px 20px 30px;
  }

  .signup-announcement-info-title {
    font-size: 18px;
    width: 320px;
  }

  .signup-announcement-info-subsection {
    font-size: 17px;
    margin: 15px 0px;
  }

  .signup-announcement-info-benefits {
    width: 95%;
  }

  .signup-announcement-info-benefit-list p {
    font-size: 14px;
  }

  .signup-announcement-info-summary {
    width: 90%;
    font-size: 15.5px;
  }

  
  .referral-close-icon {
    width: 20px;
    font-size: 20px;
  }
  
  
  .referral-details {
    font-size: 12px;
  }

  .referral-instructions-content {
    padding: 20px;
  }
  
  .referral-instructions-details {
    background-color: rgba(255, 219, 195, 0.96);
    padding: 15px 20px;
    border-radius: 30px;
  }
  
  
  .referral-close-icon {
    width: 25px;
    font-size: 25px;
  }
  
  .referral-instructions {
    padding-top: 10px;
  }
  
  .referral-details {
    font-size: 13px;
    margin-bottom: 15px;
    line-height: 25px;
  }

  .membership-read-more{
    font-size: 11px;
  }
}

@media screen and (max-width: 950px) {
  .announcement {
    width: 95%;
  }

  .announcement-title {
    font-size: 40px;
  }

  .announcement-content p {
    font-size: 18px;
  }

  .announcement-info-block {
    width: 90%;
  }

  .announcement-info-title {
    font-size: 25px;
    width: 260px;
  }

  .announcement-info-benefits {
    width: 100%;
  }

  .announcement-info-subsection {
    font-size: 20px;
  }

  .announcement-info-benefit-list p {
    font-size: 18px;
  }

  .announcement-info-summary {
    width: 100%;
    font-size: 19px;
  }

  .announcement-designer-section {
    width: 90%;
  }

  .announcement-designer-title {
    font-size: 25px;
  }

  .announcement-designer-subtitle {
    font-size: 19px;
    width: 260px;
  }

  .announcement-designer-benefits {
    font-size: 15px;
  }

  .announcement-designer-description {
    width: 100%;
    font-size: 18px;
    line-height: 20px;
  }

  .designer-signup-button button {
    font-size: 20px;
  }

  .announcement-endline {
    font-size: 14px;
  }

  .signup-announcement {
    width: 97%;
  }

  .signup-announcement-title {
    font-size: 30px;
  }

  .signup-announcement-info-section {
    margin: 20px 0px;
    padding: 30px 15px 20px 15px;
  }

  .signup-announcement-info-block {
    width: 100%;
  }

  .signup-announcement-info-title {
    font-size: 20px;
    width: 280px;
  }

  .signup-announcement-info-subsection {
    font-size: 22px;
    margin: 15px 0px;
  }

  .signup-announcement-info-benefits {
    width: 100%;
  }

  .signup-announcement-info-benefit-list p {
    font-size: 15px;
  }

  .signup-announcement-info-summary {
    width: 95%;
    font-size: 17px;
  }

  .membership-type-icon {
    width: 30px;
    height: 30px;
    float: right;
    right: -5px;
    top: -5px;
  }

  .membership-section {
    width: 100%;
    margin: 30px auto 20px auto;
  }

  .membership-type {
    font-size: 22px;
  }

  .membership-details {
    padding: 25px 10px;
  }

  .membership-title {
    font-size: 32px;
  }

  .membership-subtitle {
    font-size: 16px;
  }

  .membership-price {
    font-size: 17px;
  }

  .membership-price span {
    font-size: 30px;
  }

  .membership-description {
    font-size: 17px;
  }

  .membership-benefits {
    font-size: 16px;
  }

  .membership-or {
    font-size: 20px;
  }

  .membership-referral {
    font-size: 15.5px;
    line-height: 20px;
  }

  .membership-read-more{
    font-size: 14.5px;
  }

  .membership-referral-info {
    font-size: 22px;
  }

  .membership-quotation-details-parent {
    width: 100px;
    height: 100px;
    margin-bottom: -10px;
  }

  .membership-quotation-details-child {
    width: 66.1px;
    height: 66.1px;
  }
  .membership-quotation-details-item {
    width: 58.9px;
    height: 58.3px;
  }

  .membership-quotation-details-inner {
    width: 53.7px;
    height: 53.7px;
  }
  .membership-quotation-details-div {
    width: 48px;
    height: 48px;
  }

  .membership-quotation-days {
    top: 29.4px;
    font-size: 27px;
  }
  .membership-quotation-days-remaining {
    font-size: 16px;
  }

  .buy-now-button {
    font-size: 19px;
  }

  .referral-instructions-content {
    width: 400px;
    padding: 20px;
    margin-top: -60%;
  }
  
  .referral-instructions-details {
    padding: 15px 20px;
    border-radius: 30px;
  }
  
  
  .referral-close-icon {
    width: 30px;
    font-size: 30px;
  }
  
  .referral-instructions {
    padding-top: 10px;
  }
  
  .referral-details {
    font-size: 14.5px;
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 850px) {
  .announcement-title {
    font-size: 40px;
  }

  .announcement-content p {
    font-size: 17px;
  }

  .announcement-info-block {
    width: 90%;
  }

  .announcement-info-title {
    font-size: 25px;
    width: 260px;
  }

  .announcement-info-benefits {
    width: 100%;
  }

  .announcement-info-benefit-list p {
    font-size: 16px;
  }

  .announcement-info-summary {
    width: 100%;
    font-size: 17.5px;
  }

  .announcement-designer-section {
    width: 90%;
  }

  .announcement-designer-title {
    font-size: 24px;
  }

  .announcement-designer-subtitle {
    font-size: 18px;
  }

  .announcement-designer-benefits {
    font-size: 14px;
  }

  .announcement-designer-description {
    width: 100%;
    font-size: 16px;
    line-height: 20px;
  }

  .designer-signup-button button {
    font-size: 18px;
  }

  .announcement-endline {
    font-size: 13px;
  }

  .signup-announcement {
    width: 98.5%;
  }

  .signup-announcement-title {
    font-size: 30px;
  }

  .signup-announcement-info-section {
    margin: 20px 0px;
    padding: 30px 10px 20px 10px;
  }

  .signup-announcement-info-block {
    width: 100%;
  }

  .signup-announcement-info-title {
    font-size: 20px;
    width: 280px;
  }

  .signup-announcement-info-subsection {
    font-size: 18px;
    margin: 15px 0px;
  }

  .signup-announcement-info-benefit-list p {
    font-size: 13px;
  }

  .signup-announcement-info-summary {
    width: 98%;
    font-size: 15px;
  }

  .referral-instructions-content {
    margin-top: -70%;
  }
  
  .referral-instructions-details {
    padding: 15px 20px;
    border-radius: 30px;
  }
  
  .referral-details {
    font-size: 15px;
    line-height: 28px;
  }
}

@media screen and (max-width: 600px) {
  .announcement {
    width: 100%;
  }

  .announcement-title {
    font-size: 35px;
  }

  .announcement-content p {
    font-size: 15px;
    padding: 0px 17px;
  }

  .announcement-info-section {
    border-top: 1px solid #b8b7b7;
    padding: 25px 15px;
  }

  .announcement-info-block {
    width: 100%;
  }

  .announcement-info-title {
    font-size: 22px;
    width: 220px;
  }

  .announcement-info-subsection {
    font-size: 16px;
  }

  .announcement-info-benefits {
    flex-direction: column;
  }

  .announcement-info-benefit-list p {
    font-size: 15.5px;
    margin-bottom: 13px;
  }

  .announcement-info-summary {
    width: 100%;
    font-size: 15px;
  }

  .announcement-designer-section {
    width: 100%;
    padding: 18px 15px;
  }

  .announcement-designer-title {
    font-size: 22px;
  }

  .announcement-designer-subtitle {
    font-size: 16px;
    width: 240px;
  }

  .announcement-designer-benefits {
    font-size: 12px;
  }

  .announcement-designer-description {
    width: 100%;
    font-size: 14px;
    line-height: 20px;
  }

  .designer-signup-button button {
    font-size: 16px;
  }

  .announcement-endline {
    font-size: 10px;
  }

  .signup-announcement {
    width: 90%;
    margin-top: 30px;
  }

  .signup-announcement-title {
    font-size: 25px;
  }

  .signup-announcement-info-section {
    margin: 20px 0px;
    padding: 30px 15px 20px 15px;
  }

  .signup-announcement-info-block {
    width: 100%;
  }

  .signup-announcement-info-title {
    font-size: 17px;
    width: 240px;
  }

  .signup-announcement-info-subsection {
    font-size: 19px;
    margin: 20px 0px;
  }

  .signup-announcement-info-benefits {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .signup-announcement-info-benefit-list {
    width: 90%;
  }

  .signup-announcement-info-benefit-list p {
    font-size: 15px;
  }

  .signup-announcement-info-summary {
    width: 100%;
    font-size: 16px;
  }

  .membership-details-container {
   flex-direction: column;
}

  .membership-details-content {
    padding-top: 0px;
  }

  .membership-section {
    width: 100%;
    margin-right: 0;
    margin-left: 0;
  }

  .membership-heading {
    display: none;
  }

  .mobile-membership-heading {
    display: block;
    width: 100%;
    position: relative;
  }

  .mobile-membership-type {
    padding: 7px 10px;
    border: 1.5px solid #f9912d;
    color: #f9912d;
    font-weight: 600;
    text-align: center;
    font-size: 20px;
    border-radius: 10px;
    width: 86%;
    margin-right: auto;
    margin-left: auto;
    position: absolute;
    z-index: -1;
  }

  .mobile-membership-type-icon {
    width: 40px;
    height: 40px;
    float: right;
    margin-right: -7px;
    margin-top: -11px;
    color: #00a878;
    z-index: 999;
  }

  .membership-title {
    font-size: 35px;
  }

  .membership-details {
    box-shadow: 0px 2px 5px gray;
  }

  .referral-instructions-content {
    width: 89%;
    padding: 20px;
    margin-top: -100%;
  }
  
  .referral-instructions-details {
    padding: 15px 20px;
    border-radius: 30px;
  }
  
  
  .referral-close-icon {
    width: 30px;
    font-size: 30px;
  }
  
  .referral-details {
    font-size: 15.5px;
    margin-bottom: 15px;
  }

  .membership-read-more{
    font-size: 13px;
  }
}

@media screen and (max-width: 450px) {
  .announcement-title {
    font-size: 30px;
  }

  .announcement-content p {
    font-size: 15px;
    padding: 0px 15px;
    margin-bottom: 13px;
  }

  .announcement-info-section {
    border-top: 1px solid #b8b7b7;
    padding: 25px 15px;
  }

  .announcement-info-block {
    width: 100%;
  }

  .announcement-info-title {
    font-size: 18px;
    width: 180px;
  }

  .announcement-info-subsection {
    margin: 22px 0px;
  }

  .announcement-info-subsection h6 {
    font-size: 14px;
  }

  .announcement-content {
    margin: 30px 0px;
  }

  .announcement-info-benefit-list p {
    font-size: 16px;
  }

  .announcement-info-summary {
    width: 100%;
    font-size: 14px;
  }

  .announcement-designer-title {
    font-size: 20px;
  }

  .announcement-designer-benefits {
    font-size: 13.5px;
  }

  .announcement-designer-description {
    width: 100%;
    font-size: 15px;
    line-height: 20px;
    margin: 20px 0;
  }

  .designer-signup-button button {
    font-size: 16.5px;
  }

  .announcement-endline {
    font-size: 12px;
  }

  .signup-announcement {
    width: 90%;
    margin-top: 30px;
  }

  .signup-announcement-title {
    font-size: 25px;
  }

  .signup-announcement-info-section {
    margin: 20px 0px;
    padding: 30px 15px 20px 15px;
  }

  .signup-announcement-info-block {
    width: 100%;
  }

  .signup-announcement-info-title {
    font-size: 17px;
    width: 240px;
  }

  .signup-announcement-info-subsection {
    font-size: 15px;
    margin: 20px 0px;
  }

  .signup-announcement-info-subsection h6 {
    font-size: 12px;
    margin-bottom: 8px;
  }

  .signup-announcement-info-benefits {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .signup-announcement-info-benefit-list {
    width: 90%;
  }

  .signup-announcement-info-benefit-list p {
    font-size: 15px;
  }

  .signup-announcement-info-summary {
    width: 100%;
    font-size: 15px;
  }

  .membership-type {
   font-size: 20px;
   padding:7px;
  }

  .membership-title {
    font-size: 26px;
  }

  .membership-subtitle {
    font-size: 16px;
  }

  .membership-price {
    font-size: 17px;
  }

  .membership-price span {
    font-size: 30px;
  }

  .membership-description {
    font-size: 17px;
    margin-right: 0;
    margin-left: 0;
  }

  .membership-benefits {
    font-size: 16px;
    margin-right: 0;
    margin-left: 0;
  }

  .membership-or {
    font-size: 20px;
  }

  .membership-referral {
    font-size: 15.5px;
    line-height: 20px;
    margin-right: 0;
    margin-left: 0;
  }

  .membership-referral-info {
    font-size: 22px;
  }

  .buy-now-button {
    font-size: 19px;
  }

  .referral-instructions-content {
    width: 90%;
    padding: 20px;
    margin-top: -130%;
  }
  
  
  .referral-details {
    font-size: 15.5px;
  }
}

@media screen and (max-width: 400px) {
  .announcement-title {
    font-size: 30px;
  }

  .announcement-content p {
    font-size: 13px;
  }

  .announcement-info-title {
    font-size: 16.5px;
    width: 160px;
  }

  .announcement-info-subsection h6 {
    font-size: 12.5px;
  }

  .announcement-info-benefit-list p {
    font-size: 14px;
  }

  .announcement-info-summary {
    width: 100%;
    font-size: 14px;
  }

  .announcement-designer-title {
    font-size: 19px;
  }

  .announcement-designer-benefits {
    font-size: 12px;
  }

  .announcement-designer-description {
    width: 100%;
    font-size: 14px;
  }

  .designer-signup-button button {
    font-size: 15px;
  }

  .announcement-endline {
    font-size: 12px;
  }

  .signup-announcement {
    width: 90%;
    margin-top: 30px;
  }

  .signup-announcement-title {
    font-size: 25px;
  }

  .signup-announcement-info-section {
    margin: 20px 0px;
    padding: 30px 0px 20px 0px;
  }

  .signup-announcement-info-block {
    width: 100%;
  }

  .signup-announcement-info-title {
    font-size: 17px;
    width: 240px;
  }

  .signup-announcement-info-subsection {
    font-size: 15px;
    margin: 20px 0px;
  }

  .signup-announcement-info-subsection h6 {
    font-size: 11px;
    margin-bottom: 8px;
  }

  .signup-announcement-info-benefit-list {
    width: 90%;
  }

  .signup-announcement-info-benefit-list p {
    font-size: 14px;
  }

  .signup-announcement-info-summary {
    width: 100%;
    font-size: 13.5px;
  }

  .membership-title {
    font-size: 20px;
  }

  .membership-subtitle {
    font-size: 13px;
  }

  .membership-price {
    font-size: 15px;
  }

  .membership-price span {
    font-size: 27px;
  }

  .membership-description {
    font-size: 15px;
  }

  .membership-benefits {
    font-size: 14px;
  }

  .membership-or {
    font-size: 17px;
  }

  .membership-referral {
    font-size: 14px;
  }

  .membership-referral-info {
    font-size: 22px;
  }

  .buy-now-button {
    font-size: 17px;
  }

  .referral-instructions-content {
    width: 89%;
    padding: 18px;
    margin-top: -140%;
  }
  
  
  .referral-details {
    font-size: 13px;
  }
}

@media screen and (max-width: 350px) {
  .announcement-title {
    font-size: 28px;
  }

  .announcement-content {
    margin: 25px 0px;
  }

  .announcement-content p {
    font-size: 13px;
  }

  .announcement-info-title {
    font-size: 15.5px;
    width: 160px;
  }

  .announcement-info-subsection {
    font-size: 16px;
  }

  .announcement-info-benefit-list p {
    font-size: 15px;
  }

  .announcement-info-summary {
    width: 100%;
    font-size: 14.5px;
  }

  .announcement-designer-title {
    font-size: 18px;
  }

  .announcement-designer-benefits {
    font-size: 10.5px;
  }

  .announcement-designer-subtitle {
    font-size: 14px;
    width: 220px;
  }

  .announcement-designer-description {
    width: 100%;
    font-size: 13px;
  }

  .designer-signup-button button {
    font-size: 14px;
  }

  .announcement-endline {
    font-size: 10px;
  }

  .signup-announcement {
    width: 90%;
    margin-top: 30px;
  }

  .signup-announcement-title {
    font-size: 25px;
  }

  .signup-announcement-info-section {
    margin: 20px 0px;
    padding: 30px 0px 20px 0px;
  }

  .signup-announcement-info-block {
    width: 100%;
  }

  .signup-announcement-info-title {
    font-size: 17px;
    width: 240px;
  }

  .signup-announcement-info-subsection {
    font-size: 15px;
    margin: 20px 0px;
  }

  .signup-announcement-info-subsection h6 {
    font-size: 11px;
    margin-bottom: 8px;
  }

  .signup-announcement-info-benefit-list {
    width: 90%;
  }

  .signup-announcement-info-benefit-list p {
    font-size: 14px;
  }

  .signup-announcement-info-summary {
    width: 100%;
    font-size: 13.5px;
  }

  .mobile-membership-type {
    width: 84%;
    font-size: 17px;
  }

  .membership-title {
    font-size: 28px;
  }

  .membership-subtitle {
    font-size: 13px;
  }

  .membership-price {
    font-size: 15px;
  }

  .membership-price span {
    font-size: 27px;
  }

  .membership-description {
    font-size: 15px;
  }

  .membership-benefits {
    font-size: 13px;
  }

  .referral-instructions-content {
    width: 89%;
    padding: 18px;
    margin-top: -160%;
  }
  
  .referral-details {
    font-size: 12px;
  }
}
