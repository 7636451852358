.message-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height */
  background-color: #f0f2f5; /* Very light grey background */
  padding: 20px;
  box-sizing: border-box;
  margin-bottom: -20px;
}

.message-greeting {
  font-size: 2rem; /* Larger greeting text */
  font-weight: 600; /* Bold weight */
  color: #2c3e50; /* Dark text color */
  margin-bottom: 10px; /* Space below the greeting */
  text-align: center; /* Center-align text */
}

.message-apology {
  font-size: 1.5rem; /* Medium apology text */
  font-weight: 500; /* Medium weight */
  color: #34495e; /* Slightly lighter dark text color */
  margin-bottom: 10px; /* Space below the apology */
  text-align: center; /* Center-align text */
}

.message-info {
  font-size: 1.2rem; /* Normal info text */
  font-weight: 400; /* Regular weight */
  color: #7f8c8d; /* Grey text color */
  text-align: center; /* Center-align text */
  background-color: #ecf0f1; /* Very light grey background for the text */
  padding: 20px;
  border-radius: 12px; /* More rounded corners */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1); /* Light shadow */
  border: 1px solid #bdc3c7; /* Light border */
  max-width: 600px; /* Max width for the info box */
  margin: 0 auto; /* Center horizontally */
}

@media screen and (max-width: 600px) {

  
  .message-greeting {
    font-size: 1.5rem; 

  }
  
  .message-apology {
    font-size: 1.1rem;
  }
  
  .message-info {
    font-size: 0.95rem;
  }
}

@media screen and (max-width: 400px) {

  .message-greeting {
    font-size: 1.3rem; 

  }
  
  .message-apology {
    font-size: 1rem;
  }
  
  .message-info {
    font-size: 0.8rem;
  }
}