header {
  z-index: 2;
  padding: 10px;
  width: 100vw;
  z-index: 999;
}

.header-container {
  display: flex;
  flex-direction: column;
}

.header-for-mobile {
  display: none;
}

.header-for-laptop {
  width: 90%;
  margin: 10px auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-logo {
  width: 204px;
  position: relative;
  height: 61px;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
  cursor: pointer;
}

.header-nav-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
}

.header-nav-list li {
  display: inline-block;
  padding: 0.5em 1em;
  cursor: pointer;
}

.header-nav-list li a {
  display: block;
  text-decoration: none;
}

.header-nav-pricing a {
  position: relative;
  text-transform: uppercase;
  color: goldenrod;
  font-size: 12px;
  background: linear-gradient(to right, #eb4819 0%, #f78f5f 49%, #ff8d1b 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
}


.header-nav-login a,.header-nav-service a {
  padding: 5px 15px;
  background-color: #fff;
  border: 1px solid #f9912d;
  border-radius: 6px;
  color: #f9912d;
  font-size: 12px;
}

.header-nav-partner a,.header-nav-service a{
  font-weight: 500;
 }
 


.header-nav-login a:hover,.header-nav-service a:hover {
  transform: scale(1.04);
  background: linear-gradient(to right, #eb4819 0%, #f78f5f 49%, #ff8d1b 100%);
  color: #fff;
  border: none;
}

.header-nav-signup a,.header-nav-partner a {
  padding: 5px 15px;
  background: linear-gradient(to right, #eb4819 0%, #f78f5f 49%, #ff8d1b 100%);
  border:none;
  border-radius: 6px;
  color: #fff;
  font-size: 12px;
}

.header-nav-signup a:hover {
  transform: scale(1.04);
}

.header-profile-image {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 0px;
}

.footer-nav-list-in-header {
  margin-bottom: -20px;
  margin-top: 10px;
}

.footer-nav-item-in-header {
  font-size: 13px;
  color: #fff;
  margin-right: 15px;
  margin-left: 15px;
  cursor: pointer;
  padding-bottom: 5px;
}

.footer-nav-item-in-header:hover {
  border-bottom: 2px solid #fff;
  padding-bottom: 3px;
}

.active-nav-item-in-header {
  color: #f5ac68;
  border-bottom: 2px solid #f5ac68;
  padding-bottom: 3px;
}

/* otp popup */

.otp-popup {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  z-index: 999;
  opacity: 0; /* Start with 0 opacity */
  animation: fadeIn 0.5s forwards; /* Play the fade-in animation */
}

@keyframes fadeIn {
  0% {
    opacity: 0; /* Start with 0 opacity */
  }
  100% {
    opacity: 1; /* Fade in to 1 opacity */
  }
}

.otp-container {
  border-radius: 15px;
  padding: 30px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  max-width: 90%;
  max-height: 90%;
  overflow: auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  background-color: rgb(117 115 115 / 50%);
}

.otp-content {
  background-image: linear-gradient(to left, #f8f5ea, #f8eee6);
  padding: 25px 120px;
  border-radius: 15px;
}

.otp-title {
  font-weight: 400;
  color: #636363;
  margin-bottom: 40px;
  font-size: 20px;
}

.otp-instruction {
  color: #636363;
  font-size: 13px;
  margin-bottom: 14px;
}

.otp-input-group {
  margin-bottom: 8px;
}

.otp-input {
  width: 30px;
  height: 35px;
  background-color: #e9e5e0;
  border: 1.5px solid #f9912d;
  margin: 0px 5px;
  border-radius: 6px;
  text-align: center;
  line-height: 35px;
  font-size: 16px;
  padding: 0;
  outline: #f9912d;
}

.otp-resend {
  font-size: 11px;
  color: #b8b8b8;
  margin-bottom: 20px;
}

.otp-resend span {
  color: #f9912d;
  font-weight: 600;
  letter-spacing: 0.5px;
  cursor: pointer;
}

.otp-verify-button {
  background: linear-gradient(to right, #eb4819 0%, #f78f5f 49%, #ff8d1b 100%);
  padding: 7px 30px;
  color: #fff;
  border: none;
  border-radius: 8px;
  font-size: 12px;
  cursor: pointer;
}

.signup-phone-input {
  width: 250px;
  outline: none;
  margin-right: auto;
  margin-left: auto;
  display: block;
}

.get-otp-button {
  padding: 7px 30px;
  background: linear-gradient(to right, #eb4819 0%, #f78f5f 49%, #ff8d1b 100%);
  color: #fff;
  border: none;
  border-radius: 8px;
  font-size: 12px;
  cursor: pointer;
  margin: 30px auto 0 auto;
}

.otp-close {
  align-self: flex-end;
  position: absolute;
  cursor: pointer;
  margin-bottom: 10px;
  z-index: 999;
  color: #f9912d;
  border: 1px solid #f9912d;
  font-size: 25px;
  width: 25px;
  background-image: linear-gradient(to left, #f8f5ea, #f8eee6);
  border-radius: 50%;
  padding: 1px;
  margin-top: -7px;
  margin-right: -4px;
}

.otp-close:hover {
  background-image: linear-gradient(to left, #f9912d 0%, #f9912d 100%);
  color: #fff;
}

.verified-icon-container {
  padding: 0px;
  border-radius: 50%;
  background-color: #ecfdf3;
  width: 50px;
  height: 50px;
  margin-right: auto;
  margin-left: auto;
  z-index: -1;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: -1;
}

.verified-icon {
  width: 40px;
  height: 40px;
  padding: 5px;
  background-color: #d1fadf;
  border-radius: 50%;
  color: #039855;
  margin-left: 1px;
}

.verified-success-heading {
  font-weight: 500;
  color: #636363;
  font-size: 17px;
  margin-top: 25px;
  margin-bottom: 20px;
}

.verification-notice {
  width: 500px;
  color: #9e9d9d;
  font-size: 13px;
  line-height: 20px;
  margin-bottom: 15px;
  font-style: italic;
  margin-right: auto;
  margin-left: auto;
}

.verification-processing-time {
  color: #9e9d9d;
  font-size: 13px;
  margin-bottom: 20px;
  font-style: italic;
}

@media screen and (max-width: 2570px) {
  header {
    width: 100vw;
    padding: 10px 2vw 20px 2vw;
  }

  .header-for-laptop {
    width: 95%;
    margin: 80px auto 0 auto;
  }

  .header-logo {
    width: 431px;
    position: relative;
    height: 123px;
  }

  .header-nav-list li a {
    font-size: 30px;
    border-radius: 13px;
  }

  .header-nav-login a,
  .header-nav-signup a,.header-nav-partner a  {
    padding: 12px 25px;
  }

  .header-profile-image{
    width: 100px;
    height: 100px;
  }

  .footer-nav-item-in-header {
    font-size: 20px;
    margin-right: 25px;
    margin-left: 25px;
  }

  /* otp pop-up */

  .signup-phone-input {
    width: 400px;
    margin-top: 15px;
  }

  .otp-content {
    background-image: linear-gradient(to left, #f8f5ea, #f8eee6);
    padding: 50px 150px;
    border-radius: 15px;
  }

  .otp-title {
    margin-bottom: 60px;
    font-size: 30px;
  }

  .otp-instruction {
    font-size: 24px;
  }

  .otp-input-group {
    margin-bottom: 12px;
  }

  .otp-input {
    width: 50px;
    height: 60px;
    font-size: 25px;
  }

  .otp-resend {
    font-size: 20px;
    margin-bottom: 30px;
  }

  .otp-resend span {
    letter-spacing: 1px;
  }

  .otp-verify-button,
  .get-otp-button {
    padding: 10px 30px;
    font-size: 20px;
  }

  .otp-close {
    font-size: 50px;
    width: 50px;
    margin-top: -18px;
    margin-right: -4px;
  }

  .verified-icon-container {
    width: 70px;
    height: 70px;
  }

  .verified-icon {
    width: 60px;
    height: 60px;
  }

  .verified-success-heading {
    font-size: 22px;
  }

  .verification-notice {
    font-size: 18px;
    width: 700px;
  }

  .verification-processing-time {
    font-size: 18px;
  }
}

@media screen and (max-width: 2000px) {
  .header-for-laptop {
    margin: 15px auto 0 auto;
  }

  .header-logo {
    width: 225px;
    height: 68px;
  }

  .header-nav-list li a {
    font-size: 13.5px;
    border-radius: 8px;
  }

  .header-nav-login a,
  .header-nav-signup a,.header-nav-partner a  {
    padding: 8px 15px;
  }

  .header-profile-image{
    width: 60px;
    height: 60px;
  }


  .footer-nav-item-in-header {
    font-size: 16px;
    margin-right: 20px;
    margin-left: 20px;
  }

  /* otp pop-up */

  .signup-phone-input {
    width: 250px;
    margin-top: 8px;
  }

  .otp-content {
    background-image: linear-gradient(to left, #f8f5ea, #f8eee6);
    padding: 30px 120px;
    border-radius: 15px;
  }

  .otp-title {
    margin-bottom: 40px;
    font-size: 22px;
  }

  .otp-instruction {
    font-size: 17px;
  }

  .otp-input-group {
    margin-bottom: 8px;
  }

  .otp-input {
    width: 35px;
    height: 45px;
    font-size: 20px;
  }

  .otp-resend {
    font-size: 16px;
    margin-bottom: 30px;
  }

  .otp-resend span {
    letter-spacing: 1px;
  }

  .otp-verify-button,
  .get-otp-button {
    padding: 10px 30px;
    font-size: 18px;
  }

  .otp-close {
    font-size: 30px;
    width: 30px;
    margin-top: -8px;
    margin-right: -4px;
  }

  .verified-icon-container {
    width: 60px;
    height: 60px;
  }

  .verified-icon {
    width: 50px;
    height: 50px;
  }

  .verified-success-heading {
    font-size: 20px;
  }

  .verification-notice {
    font-size: 15px;
    width: 600px;
  }

  .verification-processing-time {
    font-size: 15px;
  }
}

@media screen and (max-width: 1400px) {
  .header-logo {
    width: 220px;
    height: 65px;
  }

  .header-nav-list li a {
    font-size: 12px;
    border-radius: 7px;
  }

  .header-nav-login a,
  .header-nav-signup a,.header-nav-partner a  {
    padding: 5px 10px;
    font-size: 12px;
  }

  .header-nav-pricing a {
    font-size: 12px;
  }

  .footer-nav-item-in-header {
    font-size: 13px;
    margin-right: 15px;
    margin-left: 15px;
  }

  .header-profile-image{
    width: 40px;
    height: 40px;
  }


  /* otp pop-up */

  .signup-phone-input {
    width: 250px;
    margin-top: 0px;
  }

  .otp-content {
    padding: 25px 120px;
  }

  .otp-title {
    margin-bottom: 40px;
    font-size: 20px;
  }

  .otp-instruction {
    font-size: 13px;
    margin-bottom: 14px;
  }

  .otp-input-group {
    margin-bottom: 8px;
  }

  .otp-input {
    width: 30px;
    height: 35px;
    font-size: 16px;
  }

  .otp-resend {
    font-size: 11px;
    margin-bottom: 20px;
  }

  .otp-verify-button,
  .get-otp-button {
    padding: 7px 30px;
    font-size: 12px;
  }

  .otp-close {
    font-size: 25px;
    width: 25px;
    margin-top: -7px;
    margin-right: -4px;
  }

  .verified-icon-container {
    width: 50px;
    height: 50px;
  }

  .verified-icon {
    width: 40px;
    height: 40px;
  }

  .verified-success-heading {
    font-size: 18px;
  }

  .verification-notice {
    font-size: 13px;
    width: 500px;
  }

  .verification-processing-time {
    font-size: 13px;
  }
}

@media screen and (max-width: 1100px) {
  .header-logo {
    width: 180px;
    height: 50px;
  }

  .header-nav-list li a {
    font-size: 12px;
  }

  .header-nav-login a,
  .header-nav-signup a,.header-nav-partner a  {
    padding: 6px 10px;
    border-radius: 6px;
  }

  .footer-nav-item-in-header {
    font-size: 12px;
    margin-right: 12px;
    margin-left: 12px;
  }

  /* otp pop-up */

  .signup-phone-input {
    width: 200px;
    margin-top: 0px;
  }

  .otp-content {
    padding: 25px 110px;
  }

  .otp-title {
    margin-bottom: 35px;
    font-size: 18px;
  }

  .otp-instruction {
    font-size: 12px;
    margin-bottom: 12px;
  }

  .otp-input-group {
    margin-bottom: 8px;
  }

  .otp-input {
    width: 28px;
    height: 35px;
    font-size: 15px;
  }

  .otp-resend {
    font-size: 11px;
    margin-bottom: 20px;
  }

  .otp-verify-button {
    padding: 7px 25px;
    font-size: 14px;
  }

  .otp-close {
    font-size: 25px;
    width: 25px;
  }

  .verified-icon-container {
    width: 50px;
    height: 50px;
  }

  .verified-icon {
    width: 40px;
    height: 40px;
  }

  .verified-success-heading {
    font-size: 17px;
  }

  .verification-notice {
    font-size: 11px;
    width: 500px;
  }

  .verification-processing-time {
    font-size: 11px;
  }
}

@media screen and (max-width: 950px) {
  .header-logo {
    width: 285px;
    height: 85px;
  }

  .header-nav-list li a {
    font-size: 18px;
  }

  .header-nav-login a,
  .header-nav-signup a, .header-nav-partner a {
    padding: 6px 10px;
  }

  .header-nav-list li {
    padding: 12px;
  }

  .footer-nav-item-in-header {
    font-size: 16px;
    margin-top: 15px;
    margin-right: 13px;
    margin-left: 13px;
  }

  .header-profile-image{
    width: 55px;
    height: 55px;
  }
  /* otp pop-up */

  .signup-phone-input {
    width: 250px;
    margin-top: 0px;
  }

  .otp-content {
    padding: 35px 150px;
  }

  .otp-title {
    margin-bottom: 40px;
    font-size: 22px;
  }

  .otp-instruction {
    font-size: 18px;
    margin-bottom: 14px;
  }

  .otp-input-group {
    margin-bottom: 10px;
  }

  .otp-input {
    width: 35px;
    height: 45px;
    font-size: 20px;
  }

  .otp-resend {
    font-size: 16px;
    margin-bottom: 25px;
  }

  .otp-verify-button,
  .get-otp-button {
    padding: 10px 30px;
    font-size: 20px;
  }

  .otp-close {
    font-size: 35px;
    width: 35px;
  }

  .verified-icon-container {
    width: 60px;
    height: 60px;
  }

  .verified-icon {
    width: 50px;
    height: 50px;
  }

  .verified-success-heading {
    font-size: 20px;
  }

  .verification-notice {
    font-size: 16px;
    width: 400px;
    line-height: 25px;
    margin-bottom: 20px;
  }

  .verification-processing-time {
    font-size: 16px;
  }
}

@media screen and (max-width: 850px) {
  .header-nav-list li {
    padding: 10px;
  }

  .header-nav-list li a {
    font-size: 15px;
  }
  .footer-nav-item-in-header {
    font-size: 15px;
    margin-top: 15px;
    margin-right: 11px;
    margin-left: 11px;
  }

  /* otp pop-up */

  .otp-content {
    padding: 25px 110px;
  }

  .otp-title {
    margin-bottom: 40px;
    font-size: 20px;
  }

  .otp-instruction {
    font-size: 16px;
    margin-bottom: 12px;
  }

  .otp-input-group {
    margin-bottom: 10px;
  }

  .otp-input {
    width: 30px;
    height: 40px;
    font-size: 18px;
  }

  .otp-resend {
    font-size: 15px;
    margin-bottom: 25px;
  }

  .otp-verify-button,
  .get-otp-button {
    padding: 8px 30px;
    font-size: 18px;
  }

  .otp-close {
    font-size: 30px;
    width: 30px;
  }

  .verification-notice {
    font-size: 15px;
    width: 400px;
    line-height: 25px;
    margin-bottom: 20px;
  }

  .verification-processing-time {
    font-size: 15px;
  }
}

@media screen and (max-width: 600px) {
  header {
    width: 100vw;
    padding: 10px 2vw 10px 2vw;
  }

  .header-for-laptop {
    display: none;
  }
  .header-for-mobile {
    display: block;
  }
  .header-for-mobile div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    margin-top: 30px;
  }

  .header-menu-icon {
    font-size: 35px;
    color: #f9912d;
  }

  .header-logo {
    overflow: visible;
    width: 160px;
    height: 60px;
  }

  .header-mobile-profile-image {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 50%;
  }

  @keyframes rollDown {
    0% {
      opacity: 0; /* Start invisible */
      transform: translateY(-0px) scaleY(0.1); /* Compressed and shifted up */
    }
    100% {
      opacity: 1; /* Fully visible */
      transform: translateY(0) scaleY(1); /* Normal position and scale */
    }
  }

  .header-mobile-nav {
    position: absolute;
    width: 160px;
    background-image: linear-gradient(to left, #faf4e1, #ffe5cc);
    padding: 10px 15px;
    border-radius: 5px 5px 20px 20px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.7);
    visibility: hidden; /* Hidden by default */
    opacity: 0; /* Invisible by default */
    transition: opacity 0.3s, visibility 0.3s;
    z-index: 1000;
  }

  .header-mobile-nav.open {
    visibility: visible; /* Become visible */
    animation: rollDown 0.3s ease-out forwards; /* Play roll-down animation */
  }

  .header-mobile-nav::before {
    content: "";
    position: absolute;
    top: -9px;
    left: 25px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #faf4e1;
    transform: translateX(-50%);
  }

  .header-mobile-nav-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .header-mobile-nav-list li a {
    display: block;
    padding: 10px 5px;
    text-decoration: none;
    color: #636363;
    transition: background-color 0.3s, color 0.3s;
    font-size: 16px;
    font-weight: 500;
  }

  .header-mobile-nav-login {
    border: 2px solid #fff;
    border-left: none;
    border-right: none;
    margin: 5px 0px;
  }

  .footer-nav-item-in-header {
    display: none;
  }

  /* otp pop-up */
  .otp-container {
    padding: 20px;
  }

  .otp-content {
    padding: 25px 50px;
  }

  .otp-title {
    margin-bottom: 30px;
    font-size: 17px;
  }

  .otp-instruction {
    font-size: 14px;
    margin-bottom: 10px;
  }

  .otp-input-group {
    margin-bottom: 8px;
  }

  .otp-input {
    width: 30px;
    height: 40px;
    font-size: 18px;
  }

  .otp-resend {
    font-size: 13px;
    margin-bottom: 25px;
  }

  .otp-verify-button,
  .get-otp-button {
    padding: 8px 30px;
    font-size: 16px;
  }

  .otp-close {
    font-size: 30px;
    width: 30px;
  }

  .verified-icon-container {
    width: 50px;
    height: 50px;
  }

  .verified-icon {
    width: 40px;
    height: 40px;
  }

  .verified-success-heading {
    font-size: 18px;
  }

  .verification-notice {
    font-size: 12px;
    width: 300px;
    line-height: 20px;
    margin-bottom: 20px;
  }

  .verification-processing-time {
    font-size: 12px;
  }

  .user-access-drop-down {
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    z-index: 999;
  }
}

@media screen and (max-width: 450px) {
  /* otp pop-up */
  .otp-container {
    max-width: 100%;
    width: 100%;
    padding: 15px;
  }

  .otp-content {
    padding: 25px 10px;
  }

  .otp-title {
    margin-bottom: 30px;
    font-size: 17px;
  }

  .otp-instruction {
    font-size: 15px;
    margin-bottom: 10px;
  }

  .otp-input-group {
    margin-bottom: 8px;
  }

  .otp-input {
    width: 30px;
    height: 40px;
    font-size: 18px;
  }

  .otp-resend {
    font-size: 13.5px;
    margin-bottom: 25px;
  }

  .otp-verify-button,
  .get-otp-button {
    padding: 8px 30px;
    font-size: 17px;
  }

  .otp-close {
    font-size: 25px;
    width: 25px;
  }

  .verification-notice {
    font-size: 13.5px;
    width: 100%;
  }

  .verification-processing-time {
    font-size: 13.5px;
  }
}

@media screen and (max-width: 400px) {
  /* otp pop-up */

  .otp-content {
    padding: 25px 5px;
  }

  .verified-success-heading {
    font-size: 17px;
  }

  .verification-notice {
    font-size: 12.5px;
    width: 100%;
  }

  .verification-processing-time {
    font-size: 12.5px;
  }
}

@media screen and (max-width: 350px) {
  .otp-content {
    padding: 25px 5px;
  }

  .otp-title {
    margin-bottom: 30px;
    font-size: 15px;
  }

  .otp-instruction {
    font-size: 14px;
    margin-bottom: 10px;
  }

  .otp-input-group {
    margin-bottom: 8px;
  }

  .otp-input {
    width: 28px;
    height: 35px;
    font-size: 17px;
  }

  .otp-resend {
    font-size: 13px;
    margin-bottom: 25px;
  }

  .otp-verify-button,
  .get-otp-button {
    padding: 8px 25px;
    font-size: 16px;
  }

  .otp-close {
    font-size: 25px;
    width: 25px;
  }
}
