.custom-radio {
    display: flex;
    align-items: center;
    width: 22px;
  }
  
  .custom-radio input {
    display: none; /* Hide the default radio button */
  }
  
  .custom-radio-label {
    position: relative;
    padding-left: 25px; /* Space for custom radio button */
    cursor: pointer;
  }
  
  .custom-radio-label::before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 10px;
    height: 10px;
    border: 1.4px solid #F9912D;
    border-radius: 50%;
    background-color: transparent;
}
  
.custom-radio input:checked + .custom-radio-label::after {
    content: "";
    position: absolute;
    left: 2.65px;
    top: 50%;
    transform: translateY(-50%);
    width: 7.5px;
    height: 7.5px;
    background-color: #F9912D;
    border-radius: 50%;
}

@media screen and (max-width: 2570px) {
  .custom-radio-label::before {
    width: 15px;
    height: 15px;
}
  
.custom-radio input:checked + .custom-radio-label::after {
    left: 4.65px;
    width: 9px;
    height: 9px;
}
}

@media screen and (max-width: 2000px) {
  .custom-radio-label::before {
    width: 12px;
    height: 12px;
}
  
.custom-radio input:checked + .custom-radio-label::after {
    left: 3.65px;
    width: 7px;
    height: 7px;
}
}

@media screen and (max-width: 1400px) {
  .custom-radio-label::before {
    width: 9px;
    height: 8.7px;
}
  
.custom-radio input:checked + .custom-radio-label::after {
    left: 3px;
    width: 6px;
    height: 6px;
}
}

@media screen and (max-width: 1100px) {
  .custom-radio-label::before {
    width: 8.7px;
    height: 8.7px;
}
  
.custom-radio input:checked + .custom-radio-label::after {
    left: 2.8px;
    width: 6px;
    height: 6px;
}
}

@media screen and (max-width: 450px) {
  .custom-radio {
    width: 15px;
  }
}
  