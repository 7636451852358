.model-popup{
    align-items: center;
    animation: fadeIn .5s forwards;
    background: #0009;
    display: flex;
    height: 100vh;
    justify-content: center;
    left: 50%;
    opacity: 0;
    position: fixed;
    top: 0;
    transform: translateX(-50%);
    width: 100vw;
    z-index: 999;
}

.model-popup-container{
    width: 80%;
    margin-right: auto;
    margin-left: auto;
    padding: 15px;
    background-color: rgb(117 115 115 / 20%);
}

.model-popup-content{
    background-color: #ffffff;
    padding: 20px;
}