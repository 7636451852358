.finishes-section {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-top: 0;
  background-color: #fff;
  height: 300px;
  overflow-y: auto;
}

.finishes-section::-webkit-scrollbar {
  display: none;
}

.container-title {
  font-weight: 600;
  text-align: left;
  font-size: 12.5px;
  padding: 4px;
  border-radius: 6px 6px 0 0;
  color: #717171;
}

.to-select-Others {
  width: 100%;
  text-align-last: left;
  position: relative;
  margin-top: 5px;
  border-top: 1px solid #ccc;
  padding: 10px 0 10px 0;
  border-bottom: 1px solid #ccc;
}

.to-select-other-info {
  opacity: 0;
  z-index: 1;
  width: 100%;
  margin-left: 10px;
  color: #bebbbb;
}

.to-select-other-word{
margin-left: 10px;
font-weight: 500;
}

.to-select-other-word:hover{
color: #f16f40;
cursor: pointer;
}

.to-select-other-word:hover + .to-select-other-info {
  opacity: 1;
}

.finishes-list {
  width: 97.5%;
  margin: 10px auto;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  list-style-type: none;
  box-shadow: 0px 3px 6px gray;
  border-radius: 5px;
  padding: 10px;
  gap: 1%;
}

.finishes-item {
  margin: 10px 4px;
  width: 31.2%;
  position: relative;
}

.finishes-title {
  font-weight: 400;
    font-size: 12px;
    color: #717171;
    text-align: start;
    cursor: pointer;
    padding: 4px;
    position: relative;
}

.finish-types {
  margin-left: 20px;
  padding: 2px 0;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  position: absolute;
  width: 195px;
  box-shadow: 0 2px 3px #ccc;
 margin-top: -3px;
 z-index: 999;
}

.finish-type-item {
  list-style-type: none;
  margin-top: 5px;
}

.finish-type-title {
  font-weight: 400;
  font-size: 10px;
  text-align: start;
  margin-bottom: 6px;
  cursor: pointer;
  transition: color 0.3s, text-decoration 0.3s; 
  padding: 5px;
}

.finish-type-title:hover {
  color: #f16f40;
}

/* Adding some hover effects */
.finishes-title:hover {
  cursor: pointer;
  background-color: #FFEBD7;
}

.finish-type-title-description{
  position: absolute;
  margin-top: -8px;
  text-align: justify;
  background-color:#f9ebe5;
  padding: 5px;
  margin-top: 3px;
  margin-left: 10px;
  width: 210px;
  color: #636363;
  display: none;
  font-size: 8px;
  box-shadow: 0px 2px 4px #ccc;
  z-index: 998;
  margin-bottom: 30px;
}

.finish-type-title:hover +.finish-type-title-description {
  display: block;
}

.Helo{
  background-color: #f16f40;
}

.user-categories-title{
  text-align: center;
  margin:10px 10px 5px 10px;
  font-weight: 600;
  color: #636363;
  font-size: 13px;
  width: 100%;

}