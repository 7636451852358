.book-a-service-custom-dropdown {
    position: relative;
    width: 100%;
  }
  
  .book-a-service-dropdown-toggle {
    width: 100%;
    padding:5px 10px;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 11px;
  }
  
  .book-a-service-dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    max-height: 230px;
    overflow-y: auto;
  }

  .book-a-service-dropdown-menu::-webkit-scrollbar{
    display: none;
  }
  
  .book-a-service-dropdown-group {
    padding: 10px;
    border-bottom: 1px solid #eee;
  }
  
  .book-a-service-dropdown-group-label {
    font-weight: bold;
    font-size: 11px;
  }

  .book-a-service-selected-project-name{
    font-weight: 500;
  }
  
  .book-a-service-dropdown-item {
    padding: 4px 20px;
    cursor: pointer;
    transition: background-color 0.2s;
    font-size: 10px;
  }
  
  .book-a-service-dropdown-item:hover {
    background-color: #f0f0f0;
  }
  
  .book-a-service-dropdown-arrow {
    margin-left: 10px;
  }
  
  .book-a-service-no-prpjects{
    text-align: center;
    font-size: 11px;
    padding: 10px;
    font-weight: 500;
    color: #636363;
  }

  @media screen and (max-width: 2570px) {
    
    .book-a-service-dropdown-toggle {
      padding:8px 10px;
      font-size: 19px;
    }

    
    .book-a-service-dropdown-group-label {
      font-size: 18px;
    }
    
    .book-a-service-dropdown-item {
      padding: 8px 20px;
      font-size: 18px;
    }

    .book-a-service-no-prpjects{
      font-size: 19px;
    }
  }

  @media screen and (max-width: 2000px) {
    
    .book-a-service-dropdown-toggle {
      padding:5px 10px;
      font-size: 14px;
    }

    
    .book-a-service-dropdown-group-label {
      font-size: 13px;
    }
    
    .book-a-service-dropdown-item {
      padding: 4px 20px;
      font-size: 13px;
    }

    .book-a-service-no-prpjects{
      font-size: 14px;
    }
  }

  @media screen and (max-width: 1400px) {
    
    .book-a-service-dropdown-toggle {
      font-size: 12px;
    }

    
    .book-a-service-dropdown-group-label {
      font-size: 11px;
    }
    
    .book-a-service-dropdown-item {
      padding: 4px 20px;
      font-size: 11px;
    }

    .book-a-service-no-prpjects{
      font-size: 11px;
    }
  }

  @media screen and (max-width: 950px) {
    
    .book-a-service-dropdown-toggle {
      font-size: 15px;
    }

    
    .book-a-service-dropdown-group-label {
      font-size: 14px;
    }
    
    .book-a-service-dropdown-item {
      font-size: 14px;
    }

    .book-a-service-no-prpjects{
      font-size: 14px;
    }
  }

  @media screen and (max-width: 850px) {
    
    .book-a-service-dropdown-toggle {
      font-size: 14px;
    }

    
    .book-a-service-dropdown-group-label {
      font-size: 14px;
    }
    
    .book-a-service-dropdown-item {
      font-size: 14px;
    }

    .book-a-service-no-prpjects{
      font-size: 14px;
    }
  }

  @media screen and (max-width: 550px) {
    
    .book-a-service-dropdown-toggle {
      font-size: 12px;
    }

    
    .book-a-service-dropdown-group-label {
      font-size: 12px;
    }
    
    .book-a-service-dropdown-item {
      font-size: 12px;
    }

    .book-a-service-no-prpjects{
      font-size: 12px;
    }
  }
