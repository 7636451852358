footer {
  width: 100%;
  margin-top: 10px;
  position: sticky;
}

.footer-section {
  background-color: #361716;
  padding: 30px 0px;
}

.footer-locations {
  width: 85%;
  margin: 0px auto;
}

.footer-locations-list {
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  justify-content: center;
  align-items: center;
  color: #fff;
  margin: 0px auto;
}

.footer-locations-list li {
  padding: 0px 20px;
  font-size: 13px;
  margin-bottom: 20px;
}

.footer-navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  margin: 0px auto;
}

.footer-navigation-img {
  width: 160px;
  height: 65px;
}

.footer-nav-list,
.footer-social-media-list {
  display: flex;
  list-style-type: none;
  justify-content: center;
  align-items: center;
}

.footer-nav-item {
  color: goldenrod;
  font-weight: 600;
  font-size: 13px;
  background: linear-gradient(
    to bottom,
    #ff8800 30%,
    #ffbf00 30%,
    #ffffff 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0px 10px;
  cursor: pointer;
}

.footer-contact {
  display: flex;
  padding: 10px 30px;
  justify-content: space-between;
  align-items: center;
  background-color: #271414;
  color: #fff;
  font-size: 12px;
}

.footer-social-media-list li {
  cursor: pointer;
}

.footer-fb-icon {
  width: 25px;
  height: 25px;
  color: #fff;
  padding: 5px;
  background-color: #1877f2;
  border-radius: 50%;
  margin: 0px 3px;
}

.footer-pinterest-icon {
  width: 25px;
  height: 25px;
  padding: 1px;
  background-color: #fff;
  border-radius: 50%;
  margin: 0px 3px;
}

.footer-insta-icon {
  width: 30px;
  height: 30px;
  color: #fff;
  background-color: black;
  margin: 0px 3px;
  padding: 4px;
  border-radius: 50%;
  margin: 0px 3px;
}

.footer-linkedin-icon {
  width: 25px;
  height: 25px;
  margin: 0px 3px;
  border-radius: 50%;
}

.footer-youtube-icon {
  width: 25px;
  height: 25px;
  color: #ff0000;
  background-color: #fff;
  padding: 2px;
  border-radius: 50%;
  margin: 0px 3px;
}

.mobile-footer-Incubatee {
  display: none;
}

.sample-unity-button{
  width: 100%;
  text-align: center;
}

.sample-unity-button button{
  background: linear-gradient(to right, #eb4819 0%, #f78f5f 49%, #ff8d1b 100%);
  border: none;
  outline: none;
  color: #fff;
  border-radius: 6px;
  cursor: pointer;
  padding: 6px 15px;
}

@media screen and (max-width: 2570px) {
  .footer-locations-list {
    width: 85%;
    margin: 0px auto;
    display: flex;
    flex-wrap: wrap;
  }

  .footer-locations-list li {
    padding: 0px 20px;
    font-size: 20px;
    margin-bottom: 20px;
  }

  .footer-navigation-img {
    width: 300px;
    height: 100px;
  }

  .footer-nav-item {
    font-size: 20px;
  }

  .mobile-footer-nav-list {
    display: none;
  }

  .footer-contact {
    display: flex;
    padding: 10px 30px;
    justify-content: space-between;
    align-items: center;
    background-color: #271414;
    color: #fff;
    font-size: 12px;
  }

  .footer-social-media-list li {
    cursor: pointer;
  }

  .footer-fb-icon {
    width: 45px;
    height: 45px;
  }

  .footer-pinterest-icon {
    width: 45px;
    height: 45px;
  }

  .footer-insta-icon {
    width: 50px;
    height: 50px;
  }

  .footer-linkedin-icon {
    width: 45px;
    height: 45px;
  }

  .footer-youtube-icon {
    width: 45px;
    height: 45px;
  }

  .footer-contact {
    padding: 15px 30px;
  }

  .footer-copyright,
  .footer-ontact-info {
    font-size: 18px;
  }
}

@media screen and (max-width: 1600px) {
  .footer-locations-list li {
    font-size: 16px;
  }

  .footer-navigation-img {
    width: 180px;
    height: 70px;
  }

  .footer-nav-item {
    font-size: 16px;
  }

  .footer-contact {
    font-size: 12px;
  }

  .footer-fb-icon,
  .footer-pinterest-icon,
  .footer-linkedin-icon,
  .footer-youtube-icon {
    width: 30px;
    height: 30px;
  }

  .footer-insta-icon {
    width: 35px;
    height: 35px;
  }

  .footer-contact {
    padding: 15px 30px;
  }

  .footer-copyright,
  .footer-ontact-info {
    font-size: 15px;
  }
}

@media screen and (max-width: 1300px) {
  .footer-locations-list li {
    font-size: 12px;
  }

  .footer-navigation-img {
    width: 150px;
    height: 50px;
  }

  .footer-nav-item {
    font-size: 12px;
  }

  .footer-fb-icon,
  .footer-pinterest-icon,
  .footer-linkedin-icon,
  .footer-youtube-icon {
    width: 20px;
    height: 20px;
  }

  .footer-insta-icon {
    width: 25px;
    height: 25px;
  }

  .footer-contact {
    padding: 10px 30px;
  }

  .footer-copyright,
  .footer-ontact-info {
    font-size: 11px;
  }
}

@media screen and (max-width: 950px) {
  .footer-locations-list li {
    font-size: 15px;
  }

  .footer-navigation-img {
    width: 170px;
    height: 56px;
  }

  .footer-nav-item {
    display: none;
  }

  .mobile-footer-nav-list {
    display: flex;
    list-style-type: none;
    justify-content: center;
    align-items: center;
    color: goldenrod;
    margin-top: 10px;
  }

  .mobile-footer-nav-item {
    color: goldenrod;
    font-weight: 600;
    font-size: 15px;
    background: linear-gradient(
      to bottom,
      #ff8800 30%,
      #ffbf00 30%,
      #ffffff 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 0px 10px;
    cursor: pointer;
  }

  .footer-fb-icon,
  .footer-pinterest-icon,
  .footer-linkedin-icon,
  .footer-youtube-icon {
    width: 25px;
    height: 25px;
  }


  .footer-insta-icon {
    width: 30px;
    height: 30px;
  }

  .footer-copyright,
  .footer-ontact-info {
    font-size: 13px;
  }
}

@media screen and (max-width: 850px) {
  .footer-locations-list li {
    font-size: 14px;
  }

  .footer-navigation-img {
    width: 160px;
    height: 50px;
  }

  .mobile-footer-nav-item {
    font-size: 13px;
  }

  .footer-fb-icon,
  .footer-pinterest-icon,
  .footer-linkedin-icon,
  .footer-youtube-icon {
    width: 25px;
    height: 25px;
  }


  .footer-insta-icon {
    width: 30px;
    height: 30px;
  }

  .footer-copyright,
  .footer-ontact-info {
    font-size: 11px;
  }
}

@media screen and (max-width: 550px) {
  .footer-locations-list li {
    font-size: 12px;
    margin-bottom: 10px;
  }

  .footer-navigation-img {
    width: 110px;
    height: 40px;
  }

  .mobile-footer-nav-item {
    font-size: 10px;
    margin: 0px 4px;
  }

  .footer-fb-icon,
  .footer-pinterest-icon,
  .footer-linkedin-icon,
  .footer-youtube-icon {
    width: 25px;
    height: 25px;
  }


  .footer-insta-icon {
    width: 30px;
    height: 30px;
  }

  .footer-contact {
    padding: 10px;
  }

  .footer-copyright,
  .footer-ontact-info {
    font-size: 10px;
  }

  .footer-Incubatee {
    display: none;
  }

  .mobile-footer-Incubatee {
    display: block;
    color: #fff;
    margin-top: 10px;
    text-align: center;
    margin-right: 15px;
    font-size: 10px;
  }
}

@media screen and (max-width: 450px) {
  .footer-section {
    padding: 20px 0px 10px 0px;
  }

  .footer-locations-list li {
    font-size: 12px;
    padding: 0px 4px;
    margin-bottom: 10px;
  }

  .footer-navigation {
    width: 95%;
  }

  .footer-navigation-img {
    width: 100px;
    height: 35px;
  }

  .mobile-footer-nav-list {
    margin-right: auto;
    margin-left: auto;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }

  .mobile-footer-nav-item {
    font-size: 9px;
    margin: 0px 4px 15px 4px;
    text-align: center;
  }

  .footer-fb-icon,
  .footer-pinterest-icon,
  .footer-linkedin-icon,
  .footer-youtube-icon {
    width: 20px;
    height: 20px;
  }


  .footer-insta-icon {
    width: 25px;
    height: 25px;
  }

  .footer-contact {
    padding: 10px;
  }

  .mobile-footer-Incubatee{
    font-size: 9px;
  }

  .footer-copyright,
  .footer-ontact-info {
    font-size: 8px;
  }
}

@media screen and (max-width: 400px) {
  .footer-locations-list li {
    font-size: 10px;
    padding: 0px 4px;
    margin-bottom: 10px;
  }

  .footer-navigation-img {
    width: 100px;
    height: 35px;
  }

  .mobile-footer-nav-list {
    width: 95%;
    margin-right: auto;
    margin-left: auto;
  }

  .mobile-footer-nav-item {
    font-size: 9px;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .footer-fb-icon,
  .footer-pinterest-icon,
  .footer-linkedin-icon,
  .footer-youtube-icon {
    width: 20px;
    height: 20px;
  }

  .footer-insta-icon {
    width: 25px;
    height: 25px;
  }

  .footer-contact {
    padding: 7px 5px;
  }

  .footer-copyright,
  .footer-ontact-info {
    font-size: 7.5px;
  }
}
