.team-members-details-container {
  align-items: flex-start;
  justify-content: flex-start;
  overflow-y: auto;
  padding: 10px;
  gap: 10px;
  position: relative;
}

.team-members-details-container::-webkit-scrollbar {
  display: none;
}

.team-members-search-bar {
  position: relative;
}

.team-members-info {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  height: 100%;
}

.team-members {
  display: flex;
  gap: 2%;
  align-items: start;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
}

.team-member {
  width: 18%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 2%;
  gap: 5px;
}

.team-member-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: contain;
}
.active-team-member-image{
border: 2.5px solid #f9912d;
}

.team-member-name {
  text-align: center;
  font-size: 10px;
  border: 1px solid #f9912d;
  color: #f9912d;
  padding: 3px 5px;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
}

.team-member-name:hover,.active-team-member-name {
  background: linear-gradient(to right, #eb4819 0%, #f78f5f 49%, #ff8d1b 100%);
  color: #fff;
}

.team-member-detail-container {
  width: 70%;
  height: 100%;
  padding: 15px;
  border-radius: 10px;
  background-color: #ffeddd;
  position: sticky;
  right: 0;
  top: 0;
}

.member-detail {
  padding: 10px;
  background-color: #fff;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  position: relative;
}

.member-name {
  text-align: center;
  font-size: 13px;
  font-weight: 600;
  color: #636363;
}

.member-profile {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 10px 0;
  gap: 8px;
}

.member-image {
  width: 70px;
  height: 70px;
  object-fit: fill;
}

.member-info {
  width: 100%;
}

.member-info p {
  font-size: 11px;
  color: #636363;
  margin-bottom: 5px;
}

.remove-member-btn {
  background: linear-gradient(to right, #eb4819 0%, #f78f5f 49%, #ff8d1b 100%);
  color: #fff;
  padding: 4px 18px;
  font-size: 11px;
  font-weight: 500;
  border-radius: 6px;
  border: none;
  cursor: pointer;
}

.member-quotations {
  margin: 10px;
  margin-right: 0;
  margin-bottom: 0;
}

.member-quotation-title {
  text-align: center;
  font-size: 12px;
  color: #636363;
  font-weight: 600;
}

.member-quotation-list {
  font-size: 10px;
  color: #636363;
  margin-top: 10px;
}

.member-quotation-list li {
  margin-bottom: 4px;
}

.add-member {
  width: 50px;
  height: 50px;
  background-color: #f9912d;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.add-member-icon {
  border-radius: 50%;
  border: 2px solid #fff;
  color: #fff;
  padding: 3px;
  width: 100%;
  height: 100%;
}

.add-member-section {
  margin: 30px 0;
}

.add-member-label {
  font-size: 13px;
  color: #636363;
  font-weight: 500;
}

.add-member-input-group {
  width: 100%;
  margin-top: 10px;
}

.add-member-profile-id-input {
  padding: 5px 10px;
  border: 1px solid #bcbaba;
  outline: none;
  border-radius: 6px 0 0 6px;
  font-size: 11px;
  color: #636363;
  border-right: none;
  width: 75%;
}

.added-member-verify-btn {
  border: 1px solid #f9912d;
  color: #f9912d;
  border-radius: 0px 6px 6px 0px;
  padding: 5px;
  font-size: 11px;
  background-color: #fff;
  width: 25%;
  cursor: pointer;
}

.added-member-verify-btn:hover,
.added-member-btn:hover {
  background: linear-gradient(to right, #eb4819 0%, #f78f5f 49%, #ff8d1b 100%);
  color: #fff;
}

.added-member-verified-btn,
.added-member-verified-btn:hover {
  border: 1px solid #28a745;
  color: #fff;
  background: #28a745;
  font-weight: 600;
}

.added-member-btn {
  border: 1px solid #f9912d;
  background-color: #fff;
  color: #f9912d;
  padding: 5px;
  font-size: 11px;
  cursor: pointer;
  width: 100%;
  border-radius: 6px;
  margin-top: 10px;
  font-weight: 600;
}

.member-info-close-icon {
  display: none;
}

@media screen and (max-width: 2570px) {
  .team-members-info {
    gap: 15px;
  }

  .team-member {
    width: 32%;
}

  .team-member-image,
  .add-member {
    width: 70px;
    height: 70px;
  }

  .team-member {
    gap: 10px;
  }

  .team-member-name {
    font-size: 14px;
  }

  .member-name {
    font-size: 18px;
  }

  .member-image {
    width: 100px;
    height: 100px;
  }

  .member-info p {
    font-size: 18px;
  }

  .remove-member-btn {
    padding: 5px 18px;
    font-size: 20px;
  }

  .member-quotation-title {
    text-align: center;
    font-size: 17px;
  }

  .member-quotation-list {
    font-size: 17px;
    color: #636363;
    margin-top: 10px;
  }

  .member-quotation-list li {
    margin-bottom: 6px;
  }

  .add-member-label {
    font-size: 18px;
  }

  .add-member-profile-id-input {
    font-size: 18px;
  }

  .added-member-verify-btn {
    font-size: 18px;
  }

  .added-member-btn {
    font-size: 18px;
  }
}



@media screen and (max-width: 2000px) {
  .team-members-info {
    gap: 15px;
  }

  .team-member {
    width: 18%;
}

  .team-member-image,
  .add-member {
    width: 60px;
    height: 60px;
  }

  .team-member {
    gap: 10px;
  }

  .team-member-name {
    font-size: 11.5px;
  }

  .member-name {
    font-size: 14px;
  }

  .member-image {
    width: 80px;
    height: 80px;
  }

  .member-info p {
    font-size: 12px;
  }

  .remove-member-btn {
    padding: 5px 18px;
    font-size: 12px;
  }

  .member-quotation-title {
    text-align: center;
    font-size: 13px;
  }

  .member-quotation-list {
    font-size: 10px;
    color: #636363;
    margin-top: 10px;
  }

  .member-quotation-list li {
    margin-bottom: 4px;
  }

  .add-member-label {
    font-size: 13px;
  }

  .add-member-profile-id-input {
    font-size: 12px;
  }

  .added-member-verify-btn {
    font-size: 12px;
  }

  .added-member-btn {
    font-size: 12px;
  }
}

@media screen and (max-width: 1400px) {
  .team-members-info {
    gap: 10px;
  }

  .team-member-image,
  .add-member {
    width: 50px;
    height: 50px;
  }

  .team-member {
    gap: 10px;
  }

  .team-member-name {
    font-size: 10px;
  }

  .member-name {
    font-size: 12px;
  }

  .member-image {
    width: 70px;
    height: 70px;
  }

  .member-info p {
    font-size: 11px;
  }

  .remove-member-btn {
    padding: 4px 18px;
    font-size: 11px;
  }

  .add-member-label {
    font-size: 12px;
  }

  .add-member-profile-id-input {
    font-size: 11px;
  }

  .added-member-verify-btn {
    font-size: 11px;
  }

  .added-member-btn {
    font-size: 11px;
  }
}

@media screen and (max-width: 950px) {
  .team-member {
    width: 31%;
  }

  .team-member-image,
  .add-member {
    width: 60px;
    height: 60px;
  }

  .team-member-name {
    font-size: 12px;
    padding: 4px 5px;
  }

  .team-member-detail-container {
    width: 100%;
  }

  .member-name {
    font-size: 14px;
  }

  .member-image {
    width: 80px;
    height: 80px;
  }

  .member-info p {
    font-size: 12px;
    margin-bottom: 7px;
  }

  .remove-member-btn {
    font-size: 12px;
  }

  .add-member-label {
    font-size: 14px;
  }

  .add-member-profile-id-input {
    font-size: 12px;
  }

  .added-member-verify-btn {
    font-size: 12px;
  }

  .added-member-btn {
    font-size: 12px;
  }
}

@media screen and (max-width: 850px) {
  .team-member-image,
  .add-member {
    width: 50px;
    height: 50px;
  }

  .member-image {
    width: 70px;
    height: 70px;
  }
}

@media screen and (max-width: 550px) {
  .member-info-close-icon {
    display: block;
    position: absolute;
    cursor: pointer;
    z-index: 994;
    color: #f9912d;
    border: 1px solid #f9912d;
    font-size: 22px;
    width: 22px;
    background-image: linear-gradient(to left, #fff, #fff);
    border-radius: 50%;
    padding: 1px;
    right: -20px;
    top: -20px;
  }

  .team-member-detail-container {
    position: fixed;
    top: 40%;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
    width: 90%;
    z-index: 998;
  }

  .team-members-info {
    gap: 15px;
  }

  .team-member {
    width: 23%;
  }

  .team-member-image,
  .add-member {
    width: 50px;
    height: 50px;
  }

  .member-image {
    width: 70px;
    height: 70px;
  }

  .member-profile {
    justify-content: center;
    align-items: center;
    margin: 15px 0;
    gap: 15px;
  }

  .member-info {
    width: auto;
  }
}

@media screen and (max-width: 450px) {
    
  
    .team-member {
      width: 31%;
    }
  
 
  }
