.view-versions-section {
 margin-top: 10px;
  padding: 0px 10px;

}

.profile-version-list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
  width: 100%;
 padding: 10px 10px 0 10px;
}

.project-versions-info{
  margin:10px 0;
}

.view-version-item {
  color: #f9912d;
  cursor: pointer;
  font-size: 9px;
  border: 1px solid #f9912d;
  padding: 2px 10px;
  background-color: #ffffff;
  border-radius: 10px 10px 0 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.05s ease;
  font-weight: 500;
  min-width: 60px;
    text-align: center;
}

.view-version-item:hover,.view-version-request-btn button:hover,.active-view-version-item {
    background: linear-gradient(to right, #eb4819 0%, #f78f5f 49%, #ff8d1b 100%);
    color: #fff;
}

.version-pricing-container{
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 40px;
  margin: 20px auto;
}

.version-pricing-item{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 1.5px solid #f9912d;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0px 3px 5px #bcbbbb;
  width: 130px;
  margin: 0 auto;
}

.version-pricing-label{
  color: #636363;
  font-weight: 500;
  font-size: 11px;
  text-align: center;
}

.version-pricing-value{
  color: #f9912d;
    font-size: 16px;
    margin-top: 5px;
    font-weight: 600;
    text-align: center;
}

.view-version-request-btn{
  text-align: center;
  margin-top: 15px;
  
}

.view-version-request-btn{
  background: linear-gradient(to right, #eb4819 0%, #f78f5f 49%, #ff8d1b 100%);
    color: #fff;
  border: 1.5px solid #f9912d;
  outline: none;
  font-size: 12px;
  padding: 5px 10px;
  font-weight: 500;
  border-radius: 5px;
  cursor: pointer;
}

.request-Successfull-btn{
  color: rgb(7, 174, 7);
  border: 1px solid rgb(7, 174, 7);
  background: rgb(234, 251, 234);
}
 
@media screen and (max-width: 2570px) {
   .view-version-item {
     font-size: 14px;
   }

   .version-pricing-item {
    width: 200px;
}
   
   .version-pricing-label{
     font-size: 18px;
   }
   
   .version-pricing-value{
       font-size: 24px;
   }
   
   .view-version-request-btn button{
     font-size: 20px;
   }
}

@media screen and (max-width: 2000px) {
  .view-version-item {
    font-size: 11px;
  }

  .version-pricing-item {
   width: 150px;
}
  
  .version-pricing-label{
    font-size: 12px;
  }
  
  .version-pricing-value{
      font-size: 16px;
  }
  
  .view-version-request-btn button{
    font-size: 12px;
  }
}

@media screen and (max-width: 1400px) {
  .view-version-item {
    font-size: 11px;
  }

  .version-pricing-item {
   width: 150px;
}
  
  .version-pricing-label{
    font-size: 11px;
  }
  
  .version-pricing-value{
      font-size: 16px;
  }
  
  .view-version-request-btn button{
    font-size: 11px;
  }
}

@media screen and (max-width: 950px) {
  .view-version-item {
    font-size: 13px;
  }

  .version-pricing-item {
   width: 170px;
}
  
  .version-pricing-label{
    font-size: 13px;
  }
  
  .version-pricing-value{
      font-size: 18px;
  }
  
  .view-version-request-btn button{
    font-size: 13px;
  }
}

@media screen and (max-width: 850px) {
  .view-version-item {
    font-size: 12px;
  }

  .version-pricing-item {
   width: 160px;
}
  
  .version-pricing-label{
    font-size: 12px;
  }
  
  .version-pricing-value{
      font-size: 17px;
  }
  
  .view-version-request-btn button{
    font-size: 12px;
  }
}

@media screen and (max-width: 600px) {
  .view-versions-section {
    gap: 0px;
}

  .view-version-item {
    font-size: 12px;
  }

  .version-pricing-item {
   width: 160px;
   margin: 0;
}

.version-pricing-container {
  margin: 5px auto;
}
  
  .version-pricing-label{
    font-size: 12px;
  }
  
  .version-pricing-value{
      font-size: 17px;
  }
  
  .view-version-request-btn button{
    font-size: 12px;
  }
}

@media screen and (max-width: 450px) {

  .view-version-item {
    font-size: 12px;
  }

  
  .version-pricing-label{
    font-size: 12px;
  }
  
  .version-pricing-value{
      font-size: 16px;
  }
  
  .view-version-request-btn button{
    font-size: 12px;
  }
}

@media screen and (max-width: 400px) {

  .view-version-item {
    font-size: 11px;
  }

  
  .version-pricing-label{
    font-size: 11px;
  }
  
  .version-pricing-value{
      font-size: 15px;
  }
  
  .view-version-request-btn button{
    font-size: 11px;
  }

}