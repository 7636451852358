.contact-container {
  width: 75%;
  margin: 20px auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px 40px;
  border: 1.5px solid #f9912d;
  border-radius: 20px;
  gap: 30px;
  font-family: "Montserrat", sans-serif;
}

.contact-form {
  width: 55%;
}

.contact-title {
  font-size: 30px;
  font-weight: 700;
}

.contact-title span {
  color: #f16f40;
}

.contact-form-field {
  margin: 8px 0px;
  display: flex;
  flex-direction: column;
}

.contact-form-field label {
  color: #636363;
  font-size: 13px;
  margin-bottom: 6px;
  font-weight: 500;
}

.contact-form-field input,
.contact-form-field textarea {
  padding: 8px 20px;
  border: 1.5px solid #636363;
  border-radius: 6px;
  font-size: 13px;
  letter-spacing: 0.3px;
  outline: #636363;
}

.contact-form-field textarea {
  height: 120px;
}

.contact-form-field textarea::-webkit-scrollbar {
  display: none;
}

.contact-form-field input ::placeholder {
  font-size: 13px;
}

.contact-form-submit {
  padding: 7px 35px;
  color: #fff;
  background: linear-gradient(to right, #eb4819 0%, #f78f5f 49%, #ff8d1b 100%);
  border: none;
  border-radius: 7px;
  margin-top: 5px;
  cursor: pointer;
}

.contact-form-submit:hover {
  transform: scale(1.02);
}

.contact-info {
  margin-top: 15px;
}

.contact-info-item {
  display: flex;
  gap: 15px;
  align-items: flex-start;
  margin: 10px 0px;
}

.contact-icon {
  font-size: 20px;
}

.contact-info-item p {
  padding-top: -3px;
  font-size: 12.5px;
  font-family: "Montserrat", sans-serif;
}

@media screen and (max-width: 2570px) {
  .contact-container {
    width: 65%;
    margin: 11% auto;
    padding: 50px 80px;
    gap: 60px;
  }

  .contact-form {
    width: 50%;
  }

  .contact-title {
    font-size: 40px;
  }

  .contact-form-field {
    margin: 25px 0px;
  }

  .contact-form-field label {
    font-size: 20px;
    margin-bottom: 12px;
  }

  .contact-form-field input,
  .contact-form-field textarea {
    padding: 10px 20px;
    font-size: 20px;
  }

  .contact-form-field input ::placeholder {
    font-size: 20px;
  }

  .contact-form-submit {
    padding: 12px 50px;
    font-size: 25px;
  }

  .company-location-map {
    border: 0;
    width: 100%;
    height: 300px;
  }

  .contact-info {
    margin-top: 25px;
  }

  .contact-info-item {
    margin: 20px 0px;
  }

  .contact-icon {
    font-size: 35px;
  }

  .contact-info-item p {
    font-size: 20px;
  }
}

@media screen and (max-width: 2000px) {
  .contact-container {
    width: 75%;
    margin: 11% auto;
    padding: 30px 50px;
    gap: 50px;
  }

  .contact-form {
    width: 55%;
  }

  .contact-title {
    font-size: 28px;
  }

  .contact-form-field {
    margin: 15px 0px;
  }

  .contact-form-field label {
    font-size: 16px;
    margin-bottom: 8px;
  }

  .contact-form-field input,
  .contact-form-field textarea {
    padding: 8px 20px;
    font-size: 16px;
  }

  .contact-form-field input ::placeholder {
    font-size: 16px;
  }

  .contact-form-submit {
    padding: 10px 35px;
    font-size: 18px;
  }

  .company-location-map {
    border: 0;
    width: 100%;
    height: 250px;
  }

  .contact-info {
    margin-top: 20px;
  }

  .contact-info-item {
    margin: 15px 0px;
  }

  .contact-icon {
    font-size: 25px;
  }

  .contact-info-item p {
    font-size: 16px;
  }
}

@media screen and (max-width: 1400px) {
  .contact-container {
    width: 75%;
    margin: 5% auto;
    padding: 20px 30px;
    gap: 30px;
  }

  .contact-form {
    width: 55%;
  }

  .contact-title {
    font-size: 25px;
  }

  .contact-form-field {
    margin: 10px 0px;
  }

  .contact-form-field label {
    font-size: 12px;
    margin-bottom: 6px;
  }

  .contact-form-field input,
  .contact-form-field textarea {
    padding: 6px 20px;
    font-size: 12px;
  }

  .contact-form-field input ::placeholder {
    font-size: 12px;
  }

  .contact-form-submit {
    padding: 7px 25px;
    font-size: 14px;
  }

  .company-location-map {
    border: 0;
    width: 100%;
    height: 230px;
  }

  .contact-info {
    margin-top: 15px;
  }

  .contact-info-item {
    margin: 10px 0px;
  }

  .contact-icon {
    font-size: 15px;
  }

  .contact-info-item p {
    font-size: 12px;
  }
}

@media screen and (max-width: 1100px) {
  .contact-container {
    width: 85%;
    margin: 3% auto;
    padding: 20px 30px;
    gap: 30px;
  }

  .contact-form {
    width: 55%;
  }

  .contact-title {
    font-size: 22px;
  }

  .contact-form-field {
    margin: 8px 0px;
  }

  .contact-form-field label {
    font-size: 11px;
    margin-bottom: 5px;
  }

  .contact-form-field input,
  .contact-form-field textarea {
    padding: 6px 20px;
    font-size: 11px;
  }

  .contact-form-field input ::placeholder {
    font-size: 11px;
  }

  .contact-form-submit {
    padding: 7px 25px;
    font-size: 13px;
  }

  .company-location-map {
    border: 0;
    width: 100%;
    height: 220px;
  }

  .contact-info {
    margin-top: 12px;
  }

  .contact-info-item {
    margin: 10px 0px;
  }

  .contact-icon {
    font-size: 16px;
  }

  .contact-info-item p {
    font-size: 12px;
  }
}

@media screen and (max-width: 950px) {
  .contact-container {
    width: 60%;
    margin: 10% auto;
    padding: 20px 30px;
    gap: 30px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .contact-form {
    width: 80%;
  }

  .contact-title {
    font-size: 25px;
    margin-bottom: 25px;
  }

  .contact-form-field {
    margin: 12px 0px;
  }

  .contact-form-field label {
    font-size: 14px;
    margin-bottom: 8px;
  }

  .contact-form-field input,
  .contact-form-field textarea {
    padding: 6px 15px;
    font-size: 14px;
  }

  .contact-form-field input ::placeholder {
    font-size: 14px;
  }

  .contact-form-submit {
    padding: 7px 25px;
    font-size: 16px;
  }

  .contact-details {
    width: 80%;
    margin-right: auto;
    margin-left: auto;
  }

  .company-location-map {
    border: 0;
    width: 100%;
    height: 220px;
  }

  .contact-info {
    margin-top: 18px;
  }

  .contact-info-item {
    margin: 12px 0px;
  }

  .contact-icon {
    font-size: 20px;
  }

  .contact-info-item p {
    font-size: 14px;
  }
}

@media screen and (max-width: 850px) {
  .contact-container {
    width: 65%;
  }

  .contact-form {
    width: 90%;
  }

  .contact-form-field label {
    font-size: 15px;
    margin-bottom: 8px;
  }

  .contact-form-field input,
  .contact-form-field textarea {
    font-size: 16px;
  }

  .contact-form-field input ::placeholder {
    font-size: 15px;
  }

  .contact-form-submit {
    padding: 7px 25px;
    font-size: 17px;
  }

  .contact-details {
    width: 90%;
  }

  .company-location-map {
    border: 0;
    width: 100%;
    height: 230px;
  }

  .contact-icon {
    font-size: 22px;
  }

  .contact-info-item p {
    font-size: 15px;
  }
}

@media screen and (max-width: 600px) {
  .contact-container {
    width: 85%;
    margin: 6% auto;
    padding:20px 10px;
  }

  .contact-form {
    width: 85%;
  }

  .contact-title {
    font-size: 22px;
    margin-bottom: 25px;
  }

  .contact-form-field {
    margin: 12px 0px;
  }

  .contact-form-field label {
    font-size: 13px;
    margin-bottom: 8px;
  }

  .contact-form-field input,
  .contact-form-field textarea {
    font-size: 14px;
  }

  .contact-form-field input ::placeholder {
    font-size: 13px;
  }

  .contact-form-submit {
    padding: 7px 25px;
    font-size: 16px;
  }

  .contact-details {
    width: 85%;
  }

  .company-location-map {
    border: 0;
    width: 100%;
    height: 220px;
  }

  .contact-info {
    margin-top: 18px;
  }

  .contact-info-item {
    margin: 12px 0px;
  }

  .contact-icon {
    font-size: 20px;
  }

  .contact-info-item p {
    font-size: 13px;
  }
}

@media screen and (max-width: 450px) {
    .contact-container {
      width: 100%;
      margin: 6% auto;
      padding:5px;
      border: none;
    }
  
    .contact-form {
      width: 90%;
    }
  
    .contact-title {
      font-size: 22px;
      margin-bottom: 22px;
    }
  
    .contact-details {
      width: 90%;
    }
  
    
  }

  @media screen and (max-width: 400px) {

    .contact-form {
      width: 95%;
    }
  
    .contact-title {
      font-size: 20px;
      margin-bottom: 20px;
    }
  
    .contact-form-field label {
      font-size: 12px;
      margin-bottom: 8px;
    }
  
    .contact-form-field input,
    .contact-form-field textarea {
      font-size: 13px;
    }
  
    .contact-form-field input ::placeholder {
      font-size: 12px;
    }
  
    .contact-form-submit {
      padding: 7px 25px;
      font-size: 16px;
    }
  
    .contact-details {
      width: 95%;
    }

  
    .contact-icon {
      font-size: 20px;
    }
  
    .contact-info-item p {
      font-size: 12px;
    }
  }
