.user-description-container {
  width: 210mm;
  height: 297mm;
  margin: 50px auto;
  background-color: #fff;
  border: 1px solid #ddd;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}



/* Header Section */

.user-description-header{
  padding: 20px ;
  background: linear-gradient(to bottom, #f6a625, #f09304);
}

.user-description-header-content {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1;
}

.description-circle-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px; /* Space between circles */
}

.description-circle {
  width: 15px;
  height: 15px;
  background-color: #f6a82a; 
  border-radius: 50%;
  display: inline-block;
}


.user-description-header h1 {
  font-size: 30px;
  color: #636363;
  margin: 0;
  width: 60%;
  text-align: right;
  font-weight: 600;
}

.user-description-header .user-description-logo {
width: 40%;

}

.user-description-header .user-description-logo img {
  width: 70px;
  height: 70px;
  object-fit: contain;
  float: right;
}

.user-description-header .user-description-details {
  float: left;
  text-align: left;
  margin-bottom: 10px;
}

.user-description-header p {
  font-size: 14px;
  color: #333;
}



/* Footer Section */
.user-description-footer {
  margin-top: 20px;
  padding: 15px;
  background-color: #ffcc66;
  color: #333;
}

.user-description-footer p {
  margin: 0;
  font-size: 14px;
}

/* Contact Details */
.user-description-contact {
  margin-top: 10px;
  font-size: 14px;
}

.user-description-contact p {
  margin: 5px 0;
}

/* Total Summary */
.user-description-total {
  margin-top: 20px;
}

.user-description-total .total {
  font-weight: bold;
  font-size: 18px;
  text-align: right;
}

.user-description-total p {
  margin: 5px 0;
}

/* Clear Floats */
.clearfix::after {
  content: "";
  clear: both;
  display: table;
}
