.login-page {
  width: 96vw;
  margin: 0 -12vw;
  display: flex;
  justify-content: flex-end;
}

.login-page-content {
  width: 250px;
}

.login-input-section {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.login-input {
  width: 100%;
}

.otp {
  z-index: 999;
  color: rgb(86, 216, 5);
  position: absolute;
  right: 9%;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0.6px;
  cursor: pointer;
  margin-top: 7px;
}

.google-error {
  text-align: center;
}

@media screen and (max-width: 2570px) {
  .login-page {
    margin: 0 -9.8vw;
  }

  .login-page-content {
    width: 320px;
    padding: 15px;
  }

  .otp {
    font-size: 15px;
    margin-top: 10px;
  }

  .login-input {
    font-size: 15px;
  }
}

@media screen and (max-width: 1600px) {
  .login-page {
    margin: 0 -10.5vw;
  }

  .login-page-content {
    width: 260px;
    padding: 15px;
  }

  .otp {
    font-size: 11px;
    margin-top: 10px;
  }

  .login-input {
    font-size: 12px;
  }
}

@media screen and (max-width: 1300px) {
  .login-page {
    margin: 0 -10.5vw;
  }

  .login-page-content {
    width: 250px;
    padding: 10px;
  }

  .otp {
    font-size: 11px;
    margin-top: 8px;
  }

  .login-input{
    font-size: 11px;
  }
}

@media screen and (max-width: 1100px) {
  .login-page {
    margin: 0 -13vw;
  }

  .login-page-content {
    width: 250px;
    padding: 10px;
  }

  .otp {
    font-size: 10px;
    margin-top: 10px;
  }
}

@media screen and (max-width: 600px) {
  .login-page {
    border-radius: 15px;
    padding: 30px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    max-width: 90%;
    max-height: 90%;
    overflow: auto;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 999;
  }

  .login-page-content {
    width: 300px;
    padding: 20px 25px;
  }

  .otp {
    font-size: 11px;
    margin-right: 8px;
    margin-top: 7px;
  }
}

@media screen and (max-width: 450px) {
  .login-page-content {
    width: 280px;
    padding: 20px;
  }

  .otp {
    font-size: 11px;
    margin-right: 8px;
    margin-top: 8px;
  }
}
