.cover-img {
  position: fixed;
  width: 100%;
  top: 0;
  right: 0;
  left: 0;
  overflow: hidden;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.new-project {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.new-project-container {
  width: 70%;
  margin: 20px auto 20px auto;
  background-color: rgb(117 115 115 / 50%);
  padding: 20px;
  border-radius: 20px;
}

.new-project-content {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 20px;
}

.new-project-heading {
  text-align: center;
  font-weight: 400;
  color: #636363;
  font-size: 20px;
  margin-bottom: 30px;
}

.project-form {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
}

.project-form-section {
  width: 42%;
}

.project-form-group {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  margin-bottom: 12px;
}

.project-form-label {
  margin-bottom: 8px;
  color: #636363;
  font-size: 13px;
  font-weight: 500;
}

.project-form-input {
  padding: 6px 10px;
  font-size: 12px;
  width: 100%;
  border-radius: 5px;
  border: 2px solid #636363;
  outline: #636363;
  font-weight: 300;
  /* color: #F9912D; */
}

.project-location-input{
  width: 100%;
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
}

.project-form-input::placeholder {
  color: #9b9c9c;
  font-weight: 300;
}

.project-layout-options {
  margin-top: 3px;
  width: 100%;
}

.layout-options-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 7px 0px;
  width: 100%;
}

.layout-option {
  display: flex;
}

.layout-option input {
  margin-right: 5px;
  cursor: pointer;
  fill: #f9912d;
}

.layout-option p {
  font-size: 11px;
  color: #9b9c9c;
  font-weight: 500;
  width: 50px;
}

.create-project-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.create-project-btn button {
  background: linear-gradient(to right, #eb4819 0%, #f78f5f 49%, #ff8d1b 100%);
  color: #fff;
  border: none;
  margin-top: 20px;
  padding: 10px 30px;
  border-radius: 9px;
  font-size: 16px;
  letter-spacing: 1px;
  cursor: pointer;
}

.new-project-close-icon {
  display: flex;
  position: absolute;
  cursor: pointer;
  z-index: 998;
  color: #f9912d;
  border: 1px solid #f9912d;
  background-image: linear-gradient(to left, #f8f5ea, #f8eee6);
  border-radius: 50%;
  padding: 1px;
  right: 15%;
}

.new-project-close-icon:hover {
  color: #fff;
  background-image: linear-gradient(to left, #f9912d, #f9912d);
}

@media screen and (max-width: 2570px) {
  .new-project-container {
    width: 65%;
    margin: 10% auto 20px auto;
    padding: 40px;
  }

  .new-project-close-icon {
    font-size: 45px;
    width: 45px;
    margin-top: -50px;
    margin-right: -10px;
    right: 17.5%;
  }

  .new-project-content {
    padding: 30px;
  }

  .new-project-heading {
    font-size: 35px;
    margin-bottom: 50px;
  }

  .project-form-section {
    width: 42%;
  }

  .project-form-group {
    margin-bottom: 20px;
  }

  .project-form-label {
    font-size: 19px;
    margin-bottom: 15px;
  }

  .project-form-input {
    padding: 10px;
    font-size: 20px;
    border-radius: 10px;
  }

  .layout-option p {
    font-size: 18px;
    width: 80px;
  }

  .create-project-btn button {
    font-size: 20px;
    padding: 15px 30px;
  }
}

@media screen and (max-width: 2000px) {
  .new-project-container {
    width: 75%;
    margin: 40px auto 20px auto;
    padding: 25px;
  }

  .new-project-close-icon {
    font-size: 30px;
    width: 30px;
    margin-top: -30px;
    margin-right: -4px;
    right: 12.5%;
  }

  .new-project-content {
    padding: 20px 10px;
  }

  .new-project-heading {
    font-size: 26px;
    margin-bottom: 25px;
  }

  .project-form-section {
    width: 42%;
  }

  .project-form-group {
    margin-bottom: 16px;
  }

  .project-form-label {
    font-size: 16px;
    margin-bottom: 10px;
  }

  .project-form-input {
    padding: 8px 10px;
    font-size: 16px;
    border-radius: 10px;
  }

  .layout-option p {
    font-size: 15px;
    width: 80px;
  }

  .create-project-btn button {
    font-size: 7;
    padding: 12px 30px;
  }
}

@media screen and (max-width: 1400px) {
  .new-project-container {
    width: 70%;
    margin: 20px auto 20px auto;
    padding: 25px;
  }

  .new-project-close-icon {
    font-size: 25px;
    width: 25px;
    margin-top: -30px;
    margin-right: -4px;
    right: 15%;
  }

  .new-project-heading {
    font-size: 20px;
    margin-bottom: 30px;
  }

  .project-form-section {
    width: 42%;
  }

  .project-form-group {
    margin-bottom: 12px;
  }

  .project-form-label {
    margin-bottom: 8px;
    font-size: 12px;
  }

  .project-form-input {
    padding: 6px 10px;
    font-size: 12px;
    border-radius: 7px;
  }

  .layout-option p {
    font-size: 11px;
    width: 50px;
  }

  .create-project-btn button {
    padding: 10px 20px;
    font-size: 16px;
  }
}

@media screen and (max-width: 1100px) {
  .new-project-container {
    width: 85%;
  }

  .new-project-close-icon {
    right: 7.5%;
  }

  .new-project-heading {
    font-size: 19px;
    margin-bottom: 25px;
  }

  .project-form-section {
    width: 42%;
  }

  .project-form-group {
    margin-bottom: 10px;
  }

  .project-form-label {
    margin-bottom: 6px;
    font-size: 11px;
  }

  .project-form-input {
    font-size: 11px;
  }

  .layout-option p {
    font-size: 10px;
    width: 50px;
  }

  .create-project-btn button {
    padding: 10px 30px;
    font-size: 14px;
  }
}

@media screen and (max-width: 950px) {
  .new-project-container {
    width: 70%;
    margin: 10% auto;
    padding: 25px;
  }

  .new-project-close-icon{
z-index: 998;
  }
  
  .new-project-close-icon {
    font-size: 32px;
    width: 32px;
    right: 15%;
  }

  .new-project-heading {
    font-size: 22px;
    margin-bottom: 25px;
  }

  .project-form {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
  }

  .project-form-section {
    width: 90%;
  }

  .project-form-group {
    margin-bottom: 15px;
  }

  .project-form-label {
    margin-bottom: 10px;
    font-size: 15px;
  }

  .project-form-input {
    padding: 8px 10px;
    font-size: 15px;
    border-radius: 7px;
  }

  .layout-option p {
    font-size: 13.5px;
    width: 60px;
  }

  .create-project-btn button {
    padding: 10px 20px;
    font-size: 16px;
  }
}

@media screen and (max-width: 850px) {
  .new-project-container {
    width: 70%;
    margin: 10% auto;
  }

  .new-project-close-icon {
    font-size: 30px;
    width: 30px;
  }

  .new-project-heading {
    font-size: 22px;
  }

  .project-form-section {
    width: 90%;
  }

  .project-form-group {
    margin-bottom: 15px;
  }

  .project-form-label {
    margin-bottom: 8px;
    font-size: 14.5px;
  }

  .project-form-input {
    padding: 8px 10px;
    font-size: 14.5px;
    border-radius: 7px;
  }

  .layout-option p {
    font-size: 13.5px;
    width: 60px;
  }

  .create-project-btn button {
    padding: 10px 20px;
    font-size: 15px;
  }
}

@media screen and (max-width: 600px) {
  .new-project-container {
    width: 90%;
    margin: 0% auto;
    padding: 20px;
  }

  
  .new-project-close-icon {
    font-size: 25px;
    width: 25px;
    margin-top: -25px;
    margin-right: -4px;
    right: 5%;
  }

  .new-project-content {
    padding: 10px 10px;
  }

  .new-project-heading {
    font-size: 22px;
    margin-bottom: 16px;
  }

  .project-form-section {
    width: 90%;
  }

  .project-form-group {
    margin-bottom: 15px;
  }

  .project-form-label {
    margin-bottom: 7px;
    font-size: 14px;
  }

  .project-form-input {
    padding: 6px 10px;
    font-size: 14px;
  }

  .layout-option p {
    font-size: 13px;
    width: 60px;
  }

  .create-project-btn button {
    padding: 10px 15px;
    font-size: 14.5px;
    margin-top: 15px;
  }

  .project-location-input {
    flex-direction: column;
}
}

@media screen and (max-width: 450px) {
  .new-project-container {
    width: 100%;
    margin: 20px auto;
    padding: 20px;
    margin-bottom: 0;
  }

  .new-project-close-icon {
    font-size: 30px;
    width: 30px;
    right: 1.3%;
  }

  .new-project-content {
    padding: 20px 0px;
    border-radius: 20px;
  }

  .new-project-heading {
    font-size: 19px;
    margin-bottom: 16px;
  }

  .project-form-section {
    width: 95%;
  }

  .project-form-group {
    margin-bottom: 15px;
  }

  .project-form-label {
    margin-bottom: 7px;
    font-size: 14px;
  }

  .project-form-input {
    padding: 6px 10px;
    font-size: 14px;
  }

  .layout-option p {
    font-size: 13px;
    width: 60px;
  }

  .create-project-btn button {
    padding: 10px 15px;
    font-size: 14.5px;
    margin-top: 15px;
  }
}

@media screen and (max-width: 400px) {
  .new-project-container {
    width: 100%;
    padding: 15px;
    margin: 15px auto;
    margin-bottom: 0;
  }

  
  .new-project-close-icon {
    font-size: 25px;
    width: 25px;
    margin-top: -20px;
    margin-right: -4px;
    right: 1.3%;
  }


  .new-project-content {
    padding: 15px 0px;
  }

  .new-project-heading {
    font-size: 18px;
    margin-bottom: 14px;
  }

  .project-form-section {
    width: 95%;
  }

  .project-form-group {
    margin-bottom: 12px;
  }

  .project-form-label {
    margin-bottom: 5px;
    font-size: 12px;
  }

  .project-form-input {
    padding: 6px 10px;
    font-size: 12px;
  }

  .layout-option p {
    font-size: 10px;
    width: 60px;
  }

  .create-project-btn button {
    padding: 10px 12px;
    font-size: 13px;
    margin-top: 12px;
  }
}
@media screen and (max-width: 350px) {

  .new-project-container {
    margin: 40px auto;
    margin-bottom: 0;
}

  .new-project-content {
    padding: 12px 6px;
  }

  .new-project-heading {
    font-size: 16px;
    margin-bottom: 13px;
  }

  .project-form-section {
    width: 97%;
  }

  .project-form-label {
    font-size: 11px;
  }

  .project-form-input {
    font-size: 11px;
  }

  .layout-option p {
    font-size: 11px;
  }

  .create-project-btn button {
    font-size: 12px;
  }
}
