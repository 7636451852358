.dropdown-container {
  position: relative;
  width: 100%;
}

.new-project-dropdown-container {
  width: 40%;
}

.dropdown-list {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid #ccc;
  border-radius: 7px;
  background-color: #fff;
  z-index: 10;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.dropdown-item {
  padding: 5px 10px;
  cursor: pointer;
  font-size: 12px;
}

.dropdown-item:hover {
  background-color: #f1f1f1;
}

.parter-dropdown-list{
  margin-top: -5px;
  border-radius: 0 0 7px 7px;
}

@media screen and (max-width: 2570px) {
  .dropdown-item {
    padding: 10px;
    font-size: 20px;
  }
}

@media screen and (max-width: 2000px) {
  .dropdown-item {
    padding: 8px 10px;
    font-size: 16px;
  }
}

@media screen and (max-width: 1400px) {
  .dropdown-item {
    padding: 6px 10px;
    font-size: 12px;
  }
}

@media screen and (max-width: 1100px) {
  .dropdown-item {
    font-size: 11px;
  }
}

@media screen and (max-width: 950px) {
  .dropdown-item {
    padding: 8px 10px;
    font-size: 15px;
  }
}

@media screen and (max-width: 850px) {
  .dropdown-item {
    padding: 8px 10px;
    font-size: 14.5px;
  }
}

@media screen and (max-width: 600px) {
  .dropdown-item {
    padding: 6px 10px;
    font-size: 14px;
  }

  .new-project-dropdown-container {
    width: 100%;
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 400px) {
  .dropdown-item {
    font-size: 12px;
  }
}

@media screen and (max-width: 350px) {
  .dropdown-item {
    font-size: 11px;
  }
}