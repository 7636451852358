.finishes-types-popup {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  z-index: 999;
}

@keyframes slideDown {
  from {
    transform: translateY(-100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.finishes-types-popup-container {
  width: 100%;
  height: 450px;
  background-color: #fff;
  max-width: 75%;
  margin-right: auto;
  margin-left: auto;
  border-radius: 8px;
  padding: 15px;
  overflow: auto;
}

.finishes-types-popup-container::-webkit-scrollbar {
  display: none;
}

.finishes-types-popup-icon {
  position: fixed;
  float: right;
  color: #f9912d;
  border-radius: 50%;
  padding: 3px;
  border: 1px solid #f9912d;
  font-size: 20px;
  width: 20px;
  cursor: pointer;
  right: 13.5%;
}

.finishes-types-popup-icon:hover {
  color: #fff;
  background-color: #f9912d;
}

.finishes-types-popup-title {
  text-align: center;
  margin-top: 15px;
  color: #3c3c3c;
  font-size: 17px;
  font-weight: 600;
  margin-bottom: 15px;
}

.finishes-types-popup-title span {
  font-size: 10px;
  font-weight: 500;
}

/* General container styling */
.finishes-popup-container {
  padding: 0px 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/* Category section styling */
.finishes-popup-category-section {
  margin-bottom: 20px;
}

.finishes-popup-category-name {
  font-size: 16px;
  color: #fff;
  padding: 9px;
  text-align: center;
  background: linear-gradient(to right, #eb4819 0%, #f78f5f 49%, #ff8d1b 100%);
  margin-bottom: 10px;
  border-radius: 15px 15px 0 0;
  position: relative;
}

.finishes-popup-finishes-section {
  display: flex;
  flex-wrap: wrap;
  gap: 20px 15px;
}

.finishes-popup-finishes-section tbody {
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
}

.finishes-popup-types-row {
  border-bottom: 1px solid #ccc;
}

/* Table styling */
.finishes-popup-finishes-table {
  width: 32.2%;
  border-collapse: collapse;
  /* box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1); */
  display: table-caption;
}

.finishes-popup-finishes-row {
  background-color: #f7f7f7;
}

.finishes-popup-finishe-name,
.finishes-popup-rate {
  border: 1px solid #ccc;
  padding: 7px;
  font-size: 12px;
  text-align: left;
  font-weight: bold;
  color: #4f4f4f;
  background-color: #ffebd7;
  position: relative;
}

.finishes-popup-type-name,
.finishes-popup-rate-input {
  padding: 4px 6px;
  font-size: 11px;
  text-align: left;
  color: #4f4f4f;
}

.finishes-popup-type-name {
  border-right: 1px solid #ccc;
  position: relative;
}

.finishes-popup-type-width {
  width: 60%;
}

.finishes-popup-rate-input {
  display: flex;
  align-items: center;
}

.finishes-popup-rate-input span {
  margin-right: 5px;
  color: #777;
}

.finishes-popup-rate-input input {
  width: 100%;
  padding: 2px;
  border: none;
  box-sizing: border-box;
  outline: none;
  transition: border-color 0.3s;
  font-size: 11px;
  color: #4f4f4f;
}

.rate-save-button {
  margin-top: 15px;
  padding: 6px 15px;
  font-weight: 500;
  color: #f9912d;
  border: 1px solid #f9912d;
  border-radius: 5px;
  background-color: #fff;
  cursor: pointer;
}

.rate-saved-button {
  color: rgb(7, 174, 7);
  border: 1px solid rgb(7, 174, 7);
  background-color: rgb(234, 251, 234);
}

.rate-save-button:hover {
  color: #fff;
  background-color: #f9912d;
}

.rate-saved-button:hover {
  color: rgb(7, 174, 7);
  background-color: rgb(234, 251, 234);
  cursor: default;
}

.finish-popup-type-title {
  margin-bottom: 0;
  user-select: none;
}

.finish-type-edit-icons {
  margin-left: 15px;
  display: none;
  position: absolute;
  right: 8px;
}

.finish-type-edit-icon {
  font-size: 12px;
  width: 12px;
  margin: 0 1px;
  margin-bottom: -2.5px;
}

.finish-type-title:hover .finish-type-edit-icons,
.finishes-popup-finishe-name:hover .finish-type-edit-icons{
  display: initial;
  color: #636363;
}

.finishes-popup-category-name:hover .finish-type-edit-icons {
  display: initial;
  color: #fff;
}

.category-name-icon{
  margin-bottom: 0px;
  font-size: 13px;
  width: 13px;
}

.finish-type-edit-icon:hover {
  color: #eb4819;
  cursor: pointer;
}

.category-name-icon:hover{
  color: #636363;
}

.finish-type-title-edit-description {
  position: absolute;
  margin-top: -8px;
  text-align: justify;
  background-color: #f9ebe5;
  padding: 5px;
  margin-top: 3px;
  margin-left: 10px;
  width: 260px;
  color: #636363;
  display: block;
  font-size: 11px;
  box-shadow: 0px 2px 4px #ccc;
  z-index: 998;
  height: 100px;
  outline: none;
  resize: none;
}

.finish-type-title-edit-description::-webkit-scrollbar {
  display: none;
}

.finishes-popup-add-button {
  float: right;
  position: relative;
}

.finishes-add-popup {
  position: absolute; /* Absolute positioning to overlay above the button */
  bottom: 100%;
  background-color: #fdf7f0; /* Background color for popup */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2); /* Shadow for depth */
  padding: 10px; /* Spacing inside the popup */
  z-index: 999; /* Ensure it appears above other elements */
  animation: foldInFromBottomRight 0.5s forwards;
  margin-bottom: 7px;
  width: 300px;
  right: -4px;
  overflow-y: auto;
}

@keyframes foldInFromBottomRight {
  0% {
    transform: translate(50%, 50%) scale(0.1) rotate(30deg); /* Start folded and rotated */
    opacity: 0; /* Start as transparent */
  }
  100% {
    transform: translate(0, 0) scale(1) rotate(0deg); /* End at its normal position */
    opacity: 1; /* Fully visible */
  }
}

.finishes-add-popup-field {
  margin-bottom: 10px;
  position: relative;
}

.finishes-add-popup-label {
  display: block; /* Labels on their own line */
  margin-bottom: 5px; /* Space between label and input */
  font-weight: bold;
  font-size: 11px;
}

.finishes-add-popup-input,
.finishes-add-popup-select {
  width: 100%;
  padding: 6px 10px;
  border: 1.5px solid #ccc;
  border-radius: 5px;
  font-size: 11px;
  transition: border-color;
  resize: none;
}

.finishes-add-popup-input::-webkit-scrollbar {
  display: none;
}

.finishes-add-popup-input:focus,
.finishes-add-popup-select:focus {
  border-color: #f9912d; /* Change border color on focus */
  outline: none; /* Remove default outline */
}

.finishes-popup-add-button button {
  background: linear-gradient(to right, #eb4819 0%, #f78f5f 49%, #ff8d1b 100%);
  color: white;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  font-size: 11px;
  font-weight: bold;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  text-transform: uppercase;
  transition: all 0.3s ease;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.25);
}

.finishes-popup-add-button button:before {
  content: "";
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 0.2),
    rgba(255, 255, 255, 0) 70%
  );
  transition: all 0.5s ease;
}

.finishes-popup-add-button button:hover:before {
  transform: rotate(45deg) scale(1.2); /* Glow effect on hover */
}

.finishes-popup-add-button button:hover {
  background: linear-gradient(
    135deg,
    #2575fc,
    #6a11cb
  ); /* Swap gradient on hover */
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3), 0 0 15px rgba(39, 140, 230, 0.5);
}

.finishes-popup-add-button button:active {
  transform: scale(0.96); /* Slight 3D press effect */
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
}

.finishes-popup-container-icon {
  float: right;
  color: #f9912d;
  border-radius: 50%;
  padding: 1px;
  border: 1px solid #f9912d;
  font-size: 17px;
  width: 17px;
  cursor: pointer;
  margin-top: -9px;
  margin-right: -9px;
}

.finishes-popup-container-icon:hover {
  color: #fff;
  background-color: #f9912d;
}

.added-categories-title {
  text-align: center;
  margin: 10px;
  font-weight: 600;
  color: #636363;
  font-size: 20px;
}

.characters-limit-error{
  position: absolute;
  text-align: right;
  margin-right: 5px;
}