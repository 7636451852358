.signup-page {
  width: 96vw;
  margin: 0 -5vw;
  display: flex;
  justify-content: flex-end;
}

.signup-page-content {
  width: 260px;
  margin: 0px auto;
  background-image: linear-gradient(to left, #f8f5ea, #f8eee6);
  padding: 10px;
  border-radius: 20px;
  color: #636363;
  position: absolute;
  z-index: 999;
}

.signup-header {
  font-size: 14px;
  font-weight: 600;
}

.signup-form-group {
  display: flex;
  flex-direction: column;
  margin: 9px 0px;
}

.signup-form-group label {
  color: #636363;
  font-size: 11px;
  margin-bottom: 4px;
  font-weight: 500;
}

.form-input  {
  padding: 6px 10px;
  border-radius: 6px;
  font-size: 11px;
  border: 1.5px solid #636363;
  background-image: linear-gradient(to left, #f8f6ee, #f7efe9);
  outline:#636363 ;
}

@keyframes shake {
  0%, 100% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-5px);
  }
  50% {
    transform: translateX(5px);
  }
  75% {
    transform: translateX(-5px);
  }
}

.input-error {
  border: 1.5px solid rgb(201, 42, 42);
  background-image: linear-gradient(to left, #ffe6e6, #ffe6e6);
  animation: shake 0.5s ease-in-out; /* Apply the shake animation */
}

.error{
  color: red;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0.5px;
  margin-top: 4px;
  margin-bottom: 0px;
  padding-left: 5px;
  width: 100%;
}

.signup-button {
  width: 197px;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
}

.signup-button button {
  width: 100%;
  background: linear-gradient(to right, #eb4819 0%, #f78f5f 49%, #ff8d1b 100%);
  padding: 7px 10px;
  font-size: 13px;
  color: #fff;
  letter-spacing: 0.5px;
  margin-top: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.signup-separator {
  margin: 5px 0px;
  width: 100%;
  text-align: center;
  font-weight: 600;
  font-size: 12px;
}

.signup-google {
  width: 100%;
    flex-direction: column;
    display: flex;
    justify-content: center;
    margin: 5px 0px;
    align-items: center;
}

.signup-login-line {
  margin-top: 0px;
  font-size: 11.5px;
  width: 100%;
  text-align: center;
}

.signup-login-line a {
  color: #17ff17;
  font-weight: 600;
  cursor: pointer;
}

.signup-close-icon {
  display: none;
}


@media screen and (max-width: 2570px) {
  .signup-page-content {
    width: 340px;
    padding: 10px 15px;
  }

  .signup-header {
    font-size: 24px;
    margin-bottom: 15px;
  }

  .signup-form-group label {
    font-size: 17px;
    margin-bottom: 10px;
  }

  .form-input  {
    font-size: 20px;
    padding: 10px 20px;
    letter-spacing: 0.5px;
  }

  .signup-separator {
    font-size: 18px;
  }

  .signup-login-line {
    font-size: 17px;
  }

  .signup-button button {
    font-size: 17px;
  }
}

@media screen and (max-width: 1600px) {
  .signup-page {
    width: 96vw;
    margin: 0 -4vw;
    display: flex;
    justify-content: flex-end;
  }

  .signup-page-content {
    width: 300px;
    padding: 10px 15px;
  }

  .signup-header {
    font-size: 17px;
    margin-bottom: 10px;
  }

  .signup-form-group label {
    font-size: 14px;
    margin-bottom: 7px;
  }

  .form-input  {
    font-size: 14px;
    padding: 8px 12px;
    letter-spacing: 0.5px;
  }

  .signup-separator {
    font-size: 16px;
  }

  .signup-login-line {
    font-size: 13px;
  }

  .signup-button button {
    font-size: 15px;
  }
}

@media screen and (max-width: 1300px) {
  .signup-page {
    margin: 0 -4vw;
  }

  .signup-page-content {
    width: 260px;
  }

  .signup-header {
    font-size: 14px;
  }

  .signup-form-group {
    margin: 6px 0px;
  }

  .signup-form-group label {
    font-size: 11px;
    margin-bottom: 5px;
  }

  .form-input  {
    padding: 6px 10px;
    font-size: 11px;
  }

  .signup-separator {
    margin: 5px 0px;
    font-size: 12px;
  }

  .signup-google {
    margin: 5px 0px;
  }

  .signup-login-line {
    margin-top: 0px;
    font-size: 11.5px;
  }

  .signup-button button {
    font-size: 13px;
  }
}

@media screen and (max-width: 1100px) {
  .signup-page {
    margin: 0 -4vw;
  }

  .signup-button button {
    font-size: 14px;
  }
}

@media screen and (max-width: 950px) {
  .signup-page {
    width: 96vw;
    margin: 0px -3.5vw;
  }

  .signup-header {
    font-size: 15px;
  }

  .signup-form-group label {
    font-size: 14px;
  }

  .form-input  {
    padding: 8px 10px;
    font-size: 14px;
  }

  .signup-separator {
    margin: 5px 0px;
    font-size: 13px;
  }

  .signup-login-line {
    margin-top: 0px;
    font-size: 12.5px;
  }
}

@media screen and (max-width: 850px) {
  .form-input  {
    padding: 6px 10px;
    font-size: 14px;
  }
}

@media screen and (max-width: 600px) {
  .signup-page {
    border-radius: 15px;
    padding: 30px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    max-width: 90%;
    max-height: 90%;
    overflow: auto;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 999;
  }

  .signup-page-content {
    width: 300px;
    padding: 20px 25px;
  }

  .signup-close-icon {
    display: flex;
    position: absolute;
    cursor: pointer;
    z-index: 999;
    color: #f9912d;
    border: 1px solid #f9912d;
    font-size: 30px;
    width: 30px;
    background-image: linear-gradient(to left, #f8f5ea, #f8eee6);
    border-radius: 50%;
    padding: 1px;
    margin-top: -27px;
    margin-right: -4px;
    right: 0;
  }

  .signup-close-icon:hover {
    color: #fff;
    background-image: linear-gradient(to left, #f9912d, #f9912d);
  }

  .signup-header {
    font-size: 16px;
  }

  .signup-form-group {
    margin: 10px 0px;
  }

  .signup-form-group label {
    font-size: 13px;
    text-align: start;
    margin-bottom: 5px;
  }

  .form-input  {
    padding: 6px 10px;
    font-size: 13px;
  }

  .form-input ::placeholder {
    font-size: 13px;
  }

  .signup-separator {
    margin: 5px 0px;
    font-size: 13px;
  }

  .signup-login-line {
    margin-top: 0px;
    font-size: 12.5px;
  }
}

@media screen and (max-width: 450px) {
  .signup-close-icon {
    font-size: 25px;
    width: 25px;
  }
  .signup-page-content {
    width: 280px;
    padding: 20px;
  }

  .signup-header {
    font-size: 16px;
  }

  .signup-form-group label {
    font-size: 13.5px;
  }

  .form-input  {
    padding: 6px 10px;
    font-size: 14px;
  }

  .form-input ::placeholder {
    font-size: 13px;
  }

  .signup-separator {
    margin: 5px 0px;
    font-size: 14px;
  }

  .signup-login-line {
    margin-top: 0px;
    font-size: 13.5px;
  }
}
