.pdf-viewer {
  width: 90%;
  margin: 30px auto;
  display: flex;
  gap: 15px;
  margin-top: 60px;
}

.pdf-viewer-container {
  width: 400px;
  height: 600px;
  overflow: hidden;
  width: 50%;
}

.pdf-viewer-iframe {
  border: none;
  overflow: hidden;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
}

.pdf-viewer-container::-webkit-scrollbar {
  display: none;
}

.pdf-viewer-iframe::-webkit-scrollbar {
  display: none;
}

.pdf-viewer-images {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.pdf-viewer-images h2{
font-size: 22px;
text-align: center;
color: #636363;
}

.sliderImage {
    position: relative;
    width: 400px;
    height: 500px;
    border-radius: 5px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    margin: 0px auto;
  }

  @media screen and (max-width: 2570px) {
    
    .pdf-viewer-container {
      height: 1100px;
    }
    
    .pdf-viewer-images h2{
    font-size: 50px;
    }
    
    .sliderImage {
        width: 65%;
        height: 900px;
      }
    
  }

  @media screen and (max-width: 2000px) {
    
    .pdf-viewer-container {
      height: 700px;
    }
    
    .pdf-viewer-images h2{
    font-size: 25px;
    }
    
    .sliderImage {
        width: 70%;
        height: 600px;
      }
    
  }

  @media screen and (max-width: 2000px) {
    
    .pdf-viewer-container {
      height: 700px;
    }
    
    .pdf-viewer-images h2{
    font-size: 25px;
    }
    
    .sliderImage {
        width: 70%;
        height: 600px;
      }
    
  }

  @media screen and (max-width: 1400px) {
    
    .pdf-viewer-container {
      height: 600px;
    }
    
    .pdf-viewer-images h2{
    font-size: 22px;
    }
    
    .sliderImage {
        width: 70%;
        height: 500px;
      }
    
  }

  @media screen and (max-width: 1100px) {
    
    .pdf-viewer-container {
      height: 520px;
    }
    
    .pdf-viewer-images h2{
    font-size: 19px;
    }
    
    .sliderImage {
        width: 75%;
        height: 420px;
      }
    
  }
  
  @media screen and (max-width: 950px) {
    
    .pdf-viewer-container {
     display: none;
    }

    .pdf-viewer-images {
      width: 100%;
    }
    
    .pdf-viewer-images h2{
    font-size: 30px;
    }
    
    .sliderImage {
        width: 80%;
        height: 750px;
      }
    
  }

  @media screen and (max-width: 600px) {

    .pdf-viewer {
      margin-top: 20px;
    }
  
    .pdf-viewer-images h2{
    font-size: 20px;
    }
    
    .sliderImage {
        width: 80%;
        height: 500px;
      }
    
  }

  @media screen and (max-width: 450px) {

    .pdf-viewer {
     width: 100%;
    }
  
    .pdf-viewer-images h2{
    font-size: 18px;
    }
    
    .sliderImage {
        width: 80%;
        height: 400px;
      }
    
  }

  @media screen and (max-width: 400px) {
  
    .pdf-viewer-images h2{
    font-size: 15px;
    }
    
    .sliderImage {
        width: 80%;
        height: 380px;
      }
    
  }

  @media screen and (max-width: 350px) {
  
    .pdf-viewer-images h2{
    font-size: 14px;
    }
    
    .sliderImage {
        width: 85%;
        height: 360px;
      }
    
  }