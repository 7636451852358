/* Import custom fonts from Google Fonts */
@import url("https://fonts.googleapis.com/css?family=Aref+Ruqaa|Mirza|Roboto|Serif");

/* Style for the editor to start with a default font */
#editor {
  font-family: "Aref Ruqaa";
  font-size: 18px;
  height: 375px;
}

/* Set dropdown font-families */
#toolbar .ql-font span[data-label="Aref Ruqaa"]::before {
  font-family: "Aref Ruqaa";
}
#toolbar .ql-font span[data-label="Mirza"]::before {
  font-family: "Mirza";
}
#toolbar .ql-font span[data-label="Roboto"]::before {
  font-family: "Roboto";
}

#toolbar .ql-font span[data-label="Serif"]::before {
  font-family: "Serif";
}

/* Set content font-families */
.ql-font-mirza {
  font-family: "Mirza";
}
.ql-font-roboto {
  font-family: "Roboto";
}

.ql-font-serif {
  font-family: "Serif";
}

.ql-container.ql-snow {
  border: none;
  padding: 0;
}

.ql-editor {
  padding: 0;
  overflow: hidden;
}

.ql-editor ::-webkit-scrollbar {
  display: none;
}

.ql-customTextBox {
  margin: 0;
  display: inline-block;
  padding: 0;
  font-size: 20px;
}

.ql-snow.ql-toolbar button,
.ql-snow .ql-toolbar button {
  padding: 4.5px;
}

.ql-editor p:has(img) { 
  height: 100%; 
}

.quotation-template {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 10px;
}

.quotation-template-container {
  padding: 20px 10px;
  background-color: #fff;
  border-radius: 10px;
  width: 100%;
}

.quotation-template-container::-webkit-scrollbar {
  display: none;
}

.quotation-container {
  width: 90%;
}

.editor-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 20px 0px;
}

.pdf-text-editor {
  display: flex;
  gap: 10px;
}

.quotation-editor {
  display: flex;
  justify-content: space-between;
  align-items: start;
  gap: 5px;
}

.pdf-text-editor-space {
  width: 210mm;
  height: 297mm;
  border: 1.5px dashed #b4b4b4;
  box-sizing: border-box;
  margin-bottom: 15px;
  padding: 20px 30px;
}

.pdf-text-editor-space::-webkit-scrollbar {
  display: none;
}

.quotation-table-editor-space {
  font-size: 10px;
}

.pdf-text-editor-border {
  width: 100%;
  height: 100%;
  border-width: 2px;
  border-style: solid;
}

.pdf-text-enter-space {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
}

.add-page-container {
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 210mm;
}

.page-tool-icons {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.page-minus-icon {
  font-size: 18px;
  width: 18px;
  color: #f9912d;
  background-color: #fff;
  padding: 1px;
  border-radius: 15%;
  border: 1px solid #f9912d;
  cursor: pointer;
  z-index: 998;
}

.add-page-button {
  margin-left: 10px;
  border-radius: 5px;
  border: 1.5px solid #f9912d;
  color: #f9912d;
  padding: 5px 13px;
  font-weight: 600;
  background-color: #fff;
  cursor: pointer;
}

.add-page-button:hover,
.page-minus-icon:hover {
  color: #fff;
  background-color: #f9912d;
}

.quotation-table {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

/* side bar  */

.pdf-editor-side-bar-container {
  width: 250px;
  position: sticky;
  right: 20px;
  top: 10px;
}

.pdf-editor-side-bar {
  background-color: #fff;
  width: 100%;
  border-radius: 10px;
  height: 77vh;
  overflow-y: auto;
}

.pdf-editor-side-bar::-webkit-scrollbar {
  display: none;
}

.pdf-template-editor-section {
  border-radius: 10px 10px 0px 0px;

  padding: 10px;
  background-color: #fff7ef;
}

.pdf-editor-finishes-rates,
.pdf-editor-template-title,
.pdf-editor-customize-title,
.pdf-editor-text-title {
  font-weight: 600;
  border-radius: 7px;
  border: 1.5px solid #636363;
  padding: 5px;
  text-align: center;
  color: #f9912d;
  font-size: 12px;
  background-color: #fff;
  margin: 10px 0px;
  cursor: pointer;
}

.pdf-editor-finishes-rates {
  margin-top: 0px;
}

.pdf-editor-template-header {
  display: flex;
  align-items: center;
}

.pdf-editor-template-title {
  margin: 0;
  text-align: left;
  width: 100%;
  padding-left: 15px;
  cursor: auto;
}

.pdf-editor-refresh-icon {
  right: 10px;
  color: #636363;
  font-weight: 500;
  font-size: 20px;
  width: 20px;
  cursor: pointer;
  margin-left: -25px;
}

.pdf-editor-template-list {
  margin: 10px 0px 0px 0px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}

.pdf-editor-template-item {
  width: 22%;
  text-align: center;
  font-size: 8px;
  padding: 5px;
  border: 1.5px solid #f9912d;
  border-radius: 7px;
  font-weight: 500;
  background-color: #fff;
  cursor: pointer;
}

.pdf-editor-sidebar-section {
  margin-top: 0px;
  padding: 5px 10px;
}

.pdf-editor-customize-title {
  margin-bottom: 5px;
  border-radius: 8px 8px 0 0;
  border-bottom: 0;
}

.color-picker-section {
  margin-top: 7px;
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}

.table-color-picker-section {
  margin-top: 7px;
  display: flex;
  gap: 10px;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}

.color-picker-container {
  display: flex;
  align-items: center;
}

.color-picker-icon-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.color-picker-icon {
  font-size: 18px;
  width: 18px;
  margin-bottom: -3px;
  color: #f9912d;
}

.color-picker-container input {
  border: none;
  background-color: #fff;
  width: 20px;
  height: 13px;
  cursor: pointer;
  appearance: none;
  box-sizing: content-box;
}

.color-picker-text {
  color: #636363;
  font-weight: 600;
  font-size: 11px;
  font-family: "Roboto", sans-serif;
  margin-left: 2px;
}

.active-item-text {
  color: #f9912d;
}

.pdf-editor-text-title {
  margin-bottom: 5px;
  margin-top: 5px;
  border-radius: 8px 8px 0 0;
  border-bottom: 0;
}

.ql-toolbar.ql-snow {
  padding: 0px 4px 8px 4px;
  margin-top: -5px;
  border: 1.5px solid #636363;
  border-radius: 0 0 10px 10px;
}

.pdf-editor-side-bar-button {
  margin-top: 10px;
  background-color: #fff;
  width: 100%;
  height: auto;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px 5px;
}

.pdf-editor-side-bar-button button {
  padding: 5px 20px;
  color: #f9912d;
  border: 1px solid #f9912d;
  border-radius: 5px;
  background-color: #fff;
  cursor: pointer;
  font-weight: 600;
  font-size: 12px;
}

.pdf-editor-side-bar-button button:hover {
  color: #fff;
  background-color: #f9912d;
}

.pdf-editor-side-bar-button p {
  padding: 5px 20px;
  color: rgb(7, 174, 7);
  border: 1px solid rgb(7, 174, 7);
  border-radius: 5px;
  background-color: rgb(234, 251, 234);
  font-weight: 600;
  font-size: 12px;
}

.quotation-editor-tools {
  padding: 0px 4px 8px 4px;
  margin-top: -5px;
  border: 1.5px solid #636363;
  border-radius: 0 0 10px 10px;
}

.table-font-family-section {
  font-family: Arial, sans-serif;
  font-size: 16px;
  color: #333;
  width: 100%;
}

.table-font-family-section select {
  background-color: #fff;
  border: 1.5px solid #f16f40;
  border-radius: 4px;
  padding: 2px;
  font-size: 13px;
  color: #636363;
  transition: border-color 0.3s, box-shadow 0.3s;
  cursor: pointer;
  outline: none;
  font-size: 11px;
  width: 100%;
}

.table-font-family-section select option {
  background-color: #fff;
  color: #636363;
}

.pdf-text-box {
  position: absolute;
  z-index: 10;
  resize: both;
  overflow: auto;
  width: 15vw;
  height: 15vh;
  max-width: 210mm;
  max-height: 297mm;
  background-color: transparent; /* Make the background transparent */
  color: black; /* Ensure the text is visible */
  resize: both; /* Enables resizing from both horizontal and vertical directions */
  overflow: hidden;
  margin-top: -5px;
  border-radius: 1px;
}

.draggable-container {
  position: absolute;
  margin-left: -30px;
  margin-top: -16px;
}

.drag-handle {
  position: absolute;
  cursor: move;
  user-select: none;
  z-index: 11;
  margin-left: -5px;
  margin-top: -7px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.drag-icon {
  font-size: 13px;
  color: #f9912d;
  background-color: #fff;
  width: 20px;
  padding-top: 2px;
  text-align: center;
  border-radius: 50%;
  border: 1px solid #636363;
  margin-top: -5px;
}

.text-tool-hidden {
  display: none;
}

.pdf-text-box img {
  width: 100%;
  height: 100%;
}

.text-box-tools-container {
  max-width: 200px;
  margin-left: 200px;
  position: inherit;
  margin-top: 10px;
  z-index: 15;
}

.image-z-index-icon {
  margin: 5px 3px 3px 3px;
  font-size: 16px;
  cursor: pointer;
  color: #f9912d;
  padding: 3px;
  border: 1px solid #f9912d;
  border-radius: 50%;
}

.image-z-index-icon:hover {
  background-color: #f9912d;
  color: #fff;
}

.text-box-font-size {
  width: 73%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.text-box-font-size input {
  margin: 0 5px;
  font-size: 13px;
  background-color: transparent;
  width: 30px;
  text-align: center;
  padding: 3px;
  outline: none;
  border: none;
}

.font-size-icon {
  margin: 5px 3px 3px 3px;
  font-size: 14px;
  cursor: pointer;
  color: #636363;
  font-weight: 500;
  padding: 1px;
  border: 1.5px solid #636363;
  border-radius: 3px;
}

.sidebar-toggle-button {
  color: #f9912d;
  padding: 5px;
  cursor: pointer;
  display: none;
}

.sidebar-open {
  width: 21%;
}

.sidebar-closed {
  width: 0%;
}

.position-adjustment-container {
  margin-left: 10%;
}

.quotation-template.landscape {
  transform: rotate(-90deg);
  transform-origin: left top;
  width: 100vh; /* Adjust as needed */
  height: 100vw; /* Adjust as needed */
}

.textbox-delete-icon {
  margin-top: 3px;
  cursor: pointer;
  font-size: 16px;
}

.textbox-delete-icon:hover {
  color: #06c;
}

.image-textbox-delete-icon {
  color: #f9912d;
  padding: 0;
  border: none;
}

.block-input-changing {
  background: transparent;
  position: absolute;
  margin-top: 0;
  z-index: 999;
}

.pdf-column-selection-container {
  padding: 10px 4px;
}

.column-selection-list {
  list-style-type: none; /* Remove default list styling */
  padding: 0; /* Remove padding */
  margin: 0; /* Remove margin */
  display: flex; /* Use flexbox for alignment */
  align-items: center;
  border-radius: 5px;
  flex-wrap: wrap;
  gap: 3%;
}

.column-selection-item {
  cursor: pointer;
  padding: 3px 5px;
  font-size: 10px;
  border: 1.5px solid #636363;
  color: #636363;
  border-radius: 5px;
  width: 30%;
  margin-bottom: 3%;
  text-align: center;
}

/* Hover effect */
.column-selection-item:hover {
  background: linear-gradient(to right, #fff 0%, #fff 49%, #fff 100%);
  color: #f9912d;
  font-weight: 600;
}

/* Selected item styling */
.column-selection-item.selected {
  background: linear-gradient(to right, #fff 0%, #fff 49%, #fff 100%);
  color: #f9912d;
  font-weight: 600;
  border: 1.5px solid #f9912d;
}

/* Selected item styling */
.column-selection-item.applied {
  background: linear-gradient(to right, #eb4819 0%, #f78f5f 49%, #ff8d1b 100%);
  color: #fff;
}

.column-selection-button {
  background: linear-gradient(to right, #eb4819 0%, #f78f5f 49%, #ff8d1b 100%);
  color: #fff;
  padding: 4px 6px;
  border: 1px solid #f9912d;
  border-radius: 5px;
  font-size: 11px;
  text-align: center;
  margin: 5px auto 0 auto;
  font-weight: 600;
  cursor: pointer;
}

.close-popup {
  position: absolute;
  right: 6px;
  top: 5px;
  z-index: 999;
  color: #f9912d;
  border: 1px solid #f9912d;
  background-color: #fff;
  border-radius: 50%;
  padding: 1px;
  cursor: pointer;
  font-size: 16px;
  width: 16px;
}

.close-popup:hover {
  color: #fff;
  background-color: #f9912d;
}

@media screen and (max-width: 2000px) {
  .pdf-editor-side-bar-container {
    width: 20%;
  }

  .quotation-side-bar-container {
    width: 24%;
  }

  .pdf-editor-side-bar {
    height: 77vh;
  }

  .pdf-editor-side-bar::-webkit-scrollbar {
    display: none;
  }

  .pdf-editor-finishes-rates,
  .pdf-editor-template-title,
  .pdf-editor-customize-title,
  .pdf-editor-text-title {
    padding: 7px;
    font-size: 13px;
  }

  .pdf-editor-template-list {
    gap: 3px;
  }

  .pdf-editor-template-item {
    width: 23%;
    font-size: 10.5px;
  }

  .color-picker-text {
    font-size: 13px;
  }

  .pdf-editor-side-bar-button button {
    padding: 5px 20px;
    font-size: 13px;
  }

  .pdf-editor-side-bar-button p {
    font-size: 13px;
  }

  .table-font-family-section select {
    font-size: 12px;
  }

  .table-font-family-section select option {
    background-color: #fff;
    color: #636363;
  }
}

@media screen and (max-width: 1400px) {
  .pdf-editor-side-bar {
    height: 88vh;
  }

  .pdf-editor-finishes-rates,
  .pdf-editor-template-title,
  .pdf-editor-customize-title,
  .pdf-editor-text-title {
    padding: 3px 5px;
    font-size: 10px;
  }

  .pdf-editor-template-list {
    gap: 5px;
  }

  .pdf-editor-template-item {
    width: 22%;
    font-size: 8px;
  }

  .color-picker-text {
    font-size: 10px;
  }

  .pdf-editor-side-bar-button button {
    padding: 5px 20px;
    font-size: 12px;
  }

  .pdf-editor-side-bar-button p {
    font-size: 12px;
  }

  .table-font-family-section select {
    font-size: 10.5px;
  }
}

@media screen and (max-width: 1100px) {
  .position-adjustment-container {
    margin-left: 0%;
    width: 95%;
  }

  .sidebar-open {
    width: 21%;
  }

  .sidebar-closed {
    width: 11%;
    margin-left: -6%;
  }

  .sidebar-toggle-button {
    display: inline-block;
  }

  .pdf-editor-finishes-rates,
  .pdf-editor-template-title,
  .pdf-editor-customize-title,
  .pdf-editor-text-title {
    padding: 5px;
    font-size: 11px;
  }

  .pdf-editor-template-list {
    gap: 2%;
  }

  .pdf-editor-template-item {
    width: 49%;
    font-size: 9px;
    margin-bottom: 10px;
  }

  .pdf-editor-sidebar-section {
    padding: 5px;
  }

  .color-picker-text {
    font-size: 10px;
    margin-left: 0.5px;
  }

  .color-picker-icon {
    font-size: 18px;
    width: 18px;
  }

  .color-picker-container input {
    width: 18px;
    height: 12px;
  }

  .pdf-editor-side-bar-button button {
    padding: 5px 20px;
    font-size: 12px;
  }

  .pdf-editor-side-bar-button p {
    font-size: 12px;
  }

  .table-font-family-section select {
    font-size: 10px;
    width: 120px;
  }

  .quotation-rates {
    padding: 19px 1.5px;
    width: 95px;
  }
}

@media screen and (max-width: 950px) {
  .quotation-template.landscape {
    transform: none;
    width: auto;
    height: auto;
  }
}
