.re-assigning-project-container {
  max-width: 40%;
  min-width: 650px;
  margin: 20px auto 20px auto;
  background-color: rgb(117 115 115 / 50%);
  padding: 15px;
  border-radius: 20px;
  position: relative;
}

.re-assigning-project-section {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  gap: 30px;
}

.re-assigning-project-details,
.re-assigning-member-details {
  width: 50%;
}

.re-assigning-member-details {
  height: 100%;
  padding: 15px;
  border-radius: 10px;
  background-color: #ffeddd;
}

.re-assigning-member {
  min-height: 185px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 250px;
}

.re-assign-member-input-group {
  width: 100%;
  margin-top: 5px;
}

.verify-re-assigning-member-text {
  width: 100%;
  height: 100%;
  font-size: 10px;
  color: #636363;
  font-weight: 500;
}

.verify-re-assigning-member-text p {
  text-align: center;
}

@media screen and (max-width: 2570px) {
  .re-assigning-project-container {
    min-width: 40%;
  }

  .re-assigning-member {
    max-width: 100%;
  }

  .verify-re-assigning-member-text {
    font-size: 18px;
  }
}

@media screen and (max-width: 2000px) {
  .re-assigning-project-container {
    min-width: 650px;
  }

  .verify-re-assigning-member-text {
    font-size: 12px;
  }
}

@media screen and (max-width: 1400px) {
  .verify-re-assigning-member-text {
    font-size: 11px;
  }
}

@media screen and (max-width: 950px) {
  .verify-re-assigning-member-text {
    font-size: 13px;
  }

  .re-assigning-project-container {
    min-width: 700px;
    padding: 25px;
  }
}

@media screen and (max-width: 850px) {
  .verify-re-assigning-member-text {
    font-size: 13px;
  }

  .re-assigning-project-container {
    padding: 20px;
  }
}

@media screen and (max-width: 600px) {
  .re-assigning-project-container {
    min-width: 100%;
    padding: 15px;
  }

  .re-assigning-project-section {
    flex-direction: column;
  }

  .re-assigning-project-details,
  .re-assigning-member-details {
    width: 100%;
  }

  .verify-re-assigning-member-text {
    font-size: 11px;
  }
}

@media screen and (max-width: 450px) {
  .verify-re-assigning-member-text {
    font-size: 13px;
  }
}

@media screen and (max-width: 400px) {
  .re-assigning-project-container {
    margin-top: 25%;
  }

  .verify-re-assigning-member-text {
    font-size: 12px;
  }
}
