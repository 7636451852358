.quotation-editor-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px 0px;
  width: 210mm;
}

.quotation-add-page-container {
  margin-top: 0;
  width: 210mm;
  display: flex;
  justify-content: center;
  align-items: center;
}


.quotation-table-header {
  text-align: center;
  background: linear-gradient(to right, #eb4819 0%, #f78f5f 49%, #ff8d1b 100%);
  padding: 10px;
  border-radius: 7px 7px 0px 0px;
  color: #fff;
  font-weight: 600;
  margin-bottom: 14px;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
}

.quotation-table-container {
  width: 100%;
}

.quotation-table-content {
  list-style-type: none;
  width: 100%;
  padding: 0;
}

.quotation-table-container {
  width: 100%;
  border-collapse: collapse;
}

thead.quotation-header,
tbody.quotation-body,
tfoot.quotation-total-container {
  padding: 0px;
  display: table-header-group;
  width: 100%;
}

tbody.quotation-body {
  border-right: 1.5px solid #f16f40;
  border-left: 1.5px solid #f16f40;
  border-bottom: 1.5px solid #f16f40;
}

.quotation-table-row-header,
.quotation-item-subname-container,
.quotation-designer-fee {
  position: relative;
  /* z-index: 998; */
}

.quotation-table-editor th {
  border: 1px solid #ddd;
  padding: 0px;
  text-align: center;
}

.quotation-table-row-header {
  border: 1.5px solid #f16f40;
}

.quotation-table-row-header th,
.quotation-item-subname-container td {
  font-size: 10.5px;
  font-weight: 500;
}

.quotation-table-row-header th,
.quotation-table-row-header input::placeholder {
  padding: 7.5px 1px;
}

/* quotation_table.css */
.placeholder-color::placeholder {
  color: var(--placeholder-color);
}

.quotation-editor-space{
  padding: 20px 5px;
}

.quotation-editor-table-space{
  padding: 20px 10px;
}

.quotation-item-subname-container td {
  background-color: #fff;
  color: #636363;
  border-right: 1px solid #f16f40;
  border-left: 1px solid #f16f40;
  padding: 5px 1.5px;
  text-align: center;
}

.quotation-table-row-header input,
.quotation-item-subname-container input {
  outline: none;
  border: none;
  width: 100%;
  box-sizing: border-box;
  font-size: 11px;
}

.quotation-table-row-header input{
  font-size: 13px;
}

.quotation-table-row-header input::placeholder {
  font-size: 11px;
  font-weight: 500;
}

.quotation-input {
  width: 22%;
  padding-left: 10px;
  padding-right: 2px;
}

.quotation-add,
.quotation-minus {
  cursor: pointer;
  background-color: #fff;
  border: 1.5px solid #9c9c9c;
  border-radius: 50%;
  font-size: 13px;
  width: 13px;
  padding: 0.5px;
  color: #9c9c9c;
  padding: 0.5px;
  text-align: center;
  font-weight: 600;
}

.quotation-add-icon {
  margin-top: 6px;
  color: black;
  z-index: 999;
  opacity: 0;
  padding: 0;
  display: flex;
  margin-top: 5px;
    margin-right: 5px;
}

.quotation-minus-icon {
  opacity: 0;
  display: flex;
  margin-top: 5px;
    margin-left: 5px;
}


.quotation-add-icon:hover,
.quotation-minus-icon:hover {
  /* When hovering over its position */
  opacity: 1;
}

.visible-quotation-add-icon{
  opacity: 1;
}

.quotation-finish input,.quotation-type input {
 padding: 0;
}

.quotation-finish {
  width: 190px;
}

.quotation-type{
width: 225px;
}

.quotation-decription{
  padding: 5px;
  position: absolute;
  z-index: 999;
  text-align: justify;
  background-color: #f9ebe5;
  padding: 5px;
  margin-top: 10px;
  margin-left: 10px;
  width: 320px;
  color: #636363;
  font-size: 8px;
  box-shadow: 0px 2px 4px #747474;
  z-index: 998;
}

.quotation-amount {
  width: 90px;
}

.quotation-width,
.quotation-height {
  width: 25px;
}

.quotation-area{
  width: 50px;
}


.quotation-width input,
.quotation-height input,
.quotation-amount input {
  text-align: center;
}

.quotation-item-name td {
  background-color: #fff;
}

.quotation-item-name input {
  color: #636363;
  outline: none;
  border: none;
  width: 100%;
  box-sizing: border-box;
  padding:3.6px 6px;
}

.quotation-input-item {
  font-size: 13px;
  font-weight: 500;
  padding-bottom: 5px;
  position: relative;
  z-index: 998;
}

.quotation-input-description {
  font-size: 10px;
  font-style: italic;
  color: #636363;
  outline: none;
  border: none;
  width: 100%;
  box-sizing: border-box;
  padding: 6px;
}

.quotation-item-subname-container input {
  background-color: #fff;
  color: #636363;
  font-size: 10px;
  font-weight: 500;
}

.quotation-item-warning-container input {
  color: red;
}

.quotation-amount input{
  font-size: 12px;
}

.subItem-amount-text input{
  font-size: 10px;
}

.quotation-body {
  margin-bottom: 13px;
}

.quotation-subtotal {
  color: #fff;
}

.quotation-subtotal td {
  padding: 6px;
}

.quotation-subtotal td:first-child {
  margin: 0;
  padding: 0;
}

.subtotal-title{
  font-size: 12px;
  font-weight: 500;
  width: 80%;
}    

.quotation-taxable-amount td:first-child{
  font-size: 12px;
}

.quotation-subtotal td:last-child {
  padding: 0;
  text-align: center;
  font-size: 12px;
  /* width: 100px; */
}

.subtotal-add-icon{
  padding: 0;
}

.subtotal-amount{
  width: 20%;
  text-align: center;
  font-size: 13px;
}

.quotation-designer-fee td {
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: center;
  font-size: 10.5px;
  color: #636363;
}

.quotation-gst td{
  padding-top: 10px;
padding-bottom: 10px;
}

.quotation-designer-fee-text span {
  display: inline-block;
  width: 150px;
}

.quotation-designer-fee-text span:first-child {
  color: #cfcece;
}

.quotation-designer-fee-text input {
  width: 150px;
  text-align: center;
  border: none;
  outline: none;
  font-size: 11px;
}

.quotation-designer-percentage input {
  text-align: center;
  font-size: 11px;
  border: none;
  outline: none;
  width: 20px;
}

.divider-line {
  background-color: #fff;
  margin-left: 10px;
  display: table;
  width: 100%;
}

.divider-line-here {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #a5a4a4;
  width: 220px;
  margin: 4px auto;
  background-color: #fff;
}

.space-provider {
  visibility: hidden;
  margin-top: -5px;
}
.hide-subtotal-row{
font-size: 5px;
visibility: hidden;
}

.quotation-gst-btn{
 margin: 10px;
    font-size: 10px;
    color: #636363;
}

.subtotal-add-gst{
background-color: #fff;
padding: 5px 10px;
}

.quotation-gst-btn td{
cursor: pointer;
font-weight: 500;
}

.quotation-gst-btn span:hover{
  color: #f16f40;
}

.quotation-rates {
  padding: 20px 5px;
  height: 297mm;
  width: 192px;
  border: 1.5px dashed #b4b4b4;
  box-sizing: border-box;
  background-color: #fff7ef;
 margin-top: 10px;
}

.hide-quotation-table-header {
  visibility: hidden;
  margin-bottom: 14px;
}

.quotation-rate-header {
  width: 90px;
  padding: 4px 1px;
  border: 1.5px solid #f16f40;
}

.hide-row td {
  color: #636363;
  width: 100%;
  opacity: 0;
}

.hide-row  {
padding: 0px;
}

.quotation-rate-item-name input{
  color: #636363;
    outline: none;
    border: none;
    width: 100%;
    box-sizing: border-box;
    padding: 4.2px 6px;
    margin-top: -0.55px;
}

.quotation-rate {
  font-size: 11px;
  background-color: #f16f40;
  padding: 9.5px 2px;
  color: #fff;
  text-align: center;
  font-weight: 500;
}

.hide-quotation-rate-header {
  width: 90px;
  opacity: 0;
}

.quotation-table-logo {
  width: 18px;
  height: 18px;
  margin-right: auto;
  margin-left: 5px;
  border: 1.5px solid #c6c5c5;
  border-radius: 50%;
  background-color: rgba(207, 203, 203, 0.4); /* semi-transparent white */
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px); 
  object-fit: contain;
  padding: 1px;
  padding-left: 2px;
  cursor: pointer;
}

.active-quotation-logo{
  border: 1.5px solid #fc9835;
  background-color: rgba(248, 164, 80, 0.4);
}


.quotation-rate-input {
  margin-top: 0;
  position: relative;
}



.quotation-rate-input input {
  width: 100%;
  text-align: center;
  background-color: #fff;
  border: 1px solid #f16f40;
  font-size: 11px;
  outline: none;
  padding: 4.7px 1px;

}

.quotation-warning{
  position: absolute;
  z-index: 998;
  margin-bottom: 5px;
  top: 0;
  left: 100%;
}

.quotation-rate-input p{
  font-size: 10px;
  padding: 3px;
  background: #fff;
  color: red;
  margin-left: 2px;
  border: 1.5px solid red;
  border-radius: 5px;
  font-weight: 500;
  cursor: pointer;
  width: 250px;
  text-align: justify;
}

.quotation-rate-input span{
  color: rgb(55, 92, 0);
  font-weight: 600;
}


.to-get-interioverse-rate{
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 1100px) {
  .quotation-rates {
    padding: 19px 1.5px;
    width: 95px
  }
}


