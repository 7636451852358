.landing-page-finishes-section {
  width: 120%;
  margin-right: auto;
  margin-left: -10%;
  background-color: #fff;
  height: 300px;
  overflow-y: auto;
  position: absolute;
  z-index: 999;
  box-shadow: 0px 3px 6px gray;
  border-radius: 6px;
}

.landing-page-finishes-section::-webkit-scrollbar {
  display: none;
}

.landing-page-container-title {
  font-weight: 600;
  text-align: left;
  font-size: 12.5px;
  padding: 4px;
  border-radius: 6px 6px 0 0;
  color: #fff;
  background: linear-gradient(
    to right,
    rgb(241, 111, 64),
    rgb(255, 141, 94),
    rgb(244, 180, 120)
  );
}

.landing-page-finishes-list {
  width: 100%;
  margin-bottom: 0;
  display: flex;
  justify-content: flex-start;
  box-shadow: 0px 3px 6px gray;
  flex-wrap: wrap;
  list-style-type: none;
  border-radius: 5px;
  gap: 1%;
}

.landing-page-finishes-item {
  margin: 10px 4px;
  width: 31.2%;
  position: relative;
}

.landing-page-finishes-title {
  font-weight: 400;
  font-size: 12px;
  color: #717171;
  text-align: start;
  cursor: pointer;
  padding: 4px;
  position: relative;
}

.landing-page-finish-types {
  margin-left: 20px;
  padding: 2px 0;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  position: absolute;
  width: 195px;
  box-shadow: 0 2px 3px #ccc;
  margin-top: -3px;
  z-index: 999;
}

.landing-page-finish-type-item {
  list-style-type: none;
  margin-top: 5px;
}

.landing-page-finish-type-title {
  font-weight: 400;
  font-size: 10px;
  text-align: start;
  margin-bottom: 6px;
  cursor: pointer;
  transition: color 0.3s, text-decoration 0.3s;
  padding: 5px;
}

.landing-page-finish-type-title:hover {
  color: #f16f40;
}

/* Adding some hover effects */
.landing-page-finishes-title:hover {
  cursor: pointer;
  background-color: #ffebd7;
}

.landing-page-finish-type-title-description {
  position: absolute;
  margin-top: -8px;
  text-align: justify;
  background-color: #f9ebe5;
  padding: 5px;
  margin-top: 3px;
  margin-left: 10px;
  width: 210px;
  color: #636363;
  font-size: 8px;
  box-shadow: 0px 2px 4px #ccc;
  z-index: 998;
}

.landing-page-finish-type-title:hover + .finish-type-title-description {
  display: block;
}

.landing-page-finishes-search-bar {
  margin-left: 10px;
  border: none;
  outline: none;
  padding: 10px 5px;
  width: 100%;
  font-size: 12px;
}

@media screen and (max-width: 2570px) {
  .landing-page-finishes-section {
    width: 120%;
  }

  .landing-page-container-title {
    padding: 10px;
    font-size: 20px;
  }

  .landing-page-finishes-title {
    font-size: 19px;
    padding: 8px;
  }

  .landing-page-finish-types {
    width: 90%;
  }

  .landing-page-finish-type-title {
    font-size: 19px;
    padding: 8px;
  }

  .landing-page-finish-type-title-description {
    width: 90%;
    font-size: 16px;
  }

  .landing-page-finishes-search-bar {
    padding: 10px 5px;
    font-size: 20px;
  }
}

@media screen and (max-width: 2000px) {
  .landing-page-finishes-section {
    width: 120%;
    margin-top: 5px;
  }

  .landing-page-container-title {
    padding: 5px;
    font-size: 14px;
  }

  .landing-page-finishes-title {
    font-size: 14px;
    padding: 5px;
  }

  .landing-page-finish-types {
    width: 95%;
    margin-left: 5%;
  }

  .landing-page-finish-type-title {
    font-size: 13px;
    padding: 5px;
  }

  .landing-page-finish-type-title-description {
    width: 93%;
    font-size: 11px;
    margin-left: 5%;
  }

  .landing-page-finishes-search-bar {
    padding: 10px 5px;
    font-size: 14px;
  }
}

@media screen and (max-width: 1400px) {
  .landing-page-container-title {
    padding: 5px;
    font-size: 12px;
  }

  .landing-page-finishes-title {
    font-size: 11px;
    padding: 5px;
  }

  .landing-page-finish-type-title {
    font-size: 11px;
    padding: 5px;
  }

  .landing-page-finish-type-title-description {
    font-size: 9.5px;
  }

  .landing-page-finishes-search-bar {
    padding: 10px 5px;
    font-size: 12px;
  }
}

@media screen and (max-width: 1100px) {

  .landing-page-finish-type-title-description {
    font-size: 9px;
  }

}

@media screen and (max-width: 950px) {
  .landing-page-finishes-section {
    width: 110%;
    margin-left: -5%;
}

  .landing-page-container-title {
    padding: 5px;
    font-size: 13.5px;
  }

  .landing-page-finishes-title {
    font-size: 13px;
    padding: 5px;
  }

  .landing-page-finish-type-title {
    font-size: 13px;
    padding: 5px;
  }

  .landing-page-finish-type-title-description {
    font-size: 12px;
  }

  .landing-page-finishes-search-bar {
    padding: 10px 5px;
    font-size: 13px;
  }
}

@media screen and (max-width: 600px) {

.landing-page-finishes-item {
  width: 46%;
}

.landing-page-finishes-section {
  width: 120%;
  margin-left: -10%;
}

  .landing-page-container-title {
    padding: 5px;
    font-size: 12px;
  }

  .landing-page-finishes-title {
    font-size: 11.5px;
  }

  .landing-page-finish-type-title {
    font-size: 11.5px;
  }

  .landing-page-finish-type-title-description {
    font-size: 11px;
  }

  .landing-page-finishes-search-bar {
    font-size: 12px;
  }
}

@media screen and (max-width: 450px) {
    .landing-page-container-title {
      font-size: 11.5px;
    }
  
    .landing-page-finishes-title {
      font-size: 11px;
    }
  
    .landing-page-finish-type-title {
      font-size: 11px;
    }
  
    .landing-page-finish-type-title-description {
      font-size: 10.5px;
    }
  
    .landing-page-finishes-search-bar {
      font-size: 11px;
    }
  }

  @media screen and (max-width: 450px) {
    .landing-page-container-title {
      font-size: 11px;
    }
  
    .landing-page-finishes-title {
      font-size: 10.5px;
    }
  
    .landing-page-finish-type-title {
      font-size: 10.5px;
    }
  
    .landing-page-finish-type-title-description {
      font-size: 10px;
    }
  
    .landing-page-finishes-search-bar {
      font-size: 11px;
    }
  }

