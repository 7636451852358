.landing-page-container {
  overflow-y: hidden;
}

.landing-page-cover-img {
  position: absolute;
  width: 100%;
  top: 0;
  right: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.landing-page-cover-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 90%;
  margin: 10px auto;
}

.unauth-landing-page-cover-content{
  height: 70vh;
}

.auth-landing-page-cover-content {
  margin-top: 0;
  height: auto;
}

.landing-page-cover-heading h1 {
  width: 500px;
  font-size: 45px;
  letter-spacing: 0.5px;
  font-weight: 500;
  color: #fff;
}

.landing-page-cover-heading p {
  width: 500px;
  font-size: 14px;
  color: #fff;
  white-space: pre-wrap;
  font-weight: 400;
  margin-top: 6px;
  letter-spacing: 0.5px;
}

.auth-landing-page-cover-heading h1 {
  width: 100%;
  text-align: center;
  color: #fff;
}

.landing-page-rate-services {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 35px;
  flex-direction: column;
  align-items: center;
}

.landing-page-rate-finder-content,
.landing-page-services-section {
  background-color: rgb(183 164 170 / 40%);
  border-radius: 10px;
  padding: 15px 30px;
}

.landing-page-rate-finder {
  width: 62%;
}

.landing-page-rate-title {
  color: #fff;
  font-weight: 500;
  font-size: 18px;
}

.landing-page-city-selection {
  margin-top: 15px;
}

.landing-page-city-label,
.landing-page-finishes-label,
.landing-page-rates-label {
  color: #fff;
  font-size: 11.5px;
  margin-bottom: 8px;
}

.landing-page-city-name {
  background: linear-gradient(to right, #eb4819 0%, #f78f5f 49%, #ff8d1b 100%);
  padding: 6px 10px;
  font-size: 12px;
  font-weight: 500;
  width: 40%;
  border-radius: 5px;
  outline: none;
  color: #fff;
  cursor: pointer;
}

.landing-page-city-name option {
  color: #636363;
}

.landing-page-city-name::-webkit-scrollbar {
  display: none;
}

.landing-page-rate-container {
  position: relative;
}

.landing-page-rate-content {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.landing-page-finishes-selection {
  width: 80%;
}

.landing-page-finishes-inputs {
  width: 100%;
}

.landing-page-finishes-input {
  width: 50%;
  padding: 6px 10px;
  font-size: 11.5px;
  outline: none;
  border: none;
  cursor: pointer;
}

.landing-page-finishes-inputs input:first-child {
  border-radius: 6px 0 0 6px;
  border-right: 1px solid #f9912d;
  width: 40%;
}

.landing-page-finishes-inputs input:last-child {
  border-radius: 0px 6px 6px 0px;
  width: 60%;
}

.landing-page-rates-display {
  width: 18%;
}

.landing-page-rates-value {
  background: linear-gradient(to right, #eb4819 0%, #f78f5f 49%, #ff8d1b 100%);
  padding: 6px 10px;
  font-size: 11.5px;
  font-weight: 500;
  border-radius: 5px;
  color: #fff;
  text-align: center;
}

.landing-page-rate-description {
  font-size: 11px;
  margin-top: 5px;
  color: #fff;
  text-align: justify;
}

.landing-page-services-title {
  text-align: center;
  color: #fff;
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 7px;
}

.landing-page-services-section {
  padding: 20px;
  height: auto;
  margin-top: 20px;
}

.landing-page-services-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.landing-page-service-item {
  background-color: #fff;
  padding: 10px 30px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  width: 50%;
}

.landing-page-service-title {
  text-align: center;
  font-weight: 600;
  font-size: 12.5px;
  color: #f9912d;
}

.landing-page-service-subtitle {
  text-align: center;
  font-weight: 500;
  font-size: 9.5px;
  color: #f9912d;
}

.landing-page-service-img {
  width: 100%;
  margin: 10px auto;
  height: 115px;
  object-fit: contain;
}

.landing-page-service-description {
  font-size: 10px;
  text-align: justify;
  margin: 5px 0px;
  color: #636363;
  height: 75px;
}

.landing-page-service-button {
  cursor: pointer;
  padding: 4px 15px;
  border: 1.5px solid #f9912d;
  background-color: #fff;
  border-radius: 5px;
  color: #f9912d;
  font-size: 11.5px;
  font-weight: 600;
  margin-top: 5px;
}

.landing-page-service-button:hover {
  color: #fff;
  background: linear-gradient(to right, #eb4819 0%, #f78f5f 49%, #ff8d1b 100%);
}

.landing-page-cover-quotation {
  margin-top: 50px;
}

.landing-page-cover-quotation h2 {
  width: 250px;
  text-align: center;
  padding: 8px 15px;
  background-color: #fff;
  border: 1px solid #f9912d;
  border-radius: 5px;
  color: #f9912d;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}

.landing-page-cover-quotation h2:hover {
  background: linear-gradient(to right, #eb4819 0%, #f78f5f 49%, #ff8d1b 100%);
  color: #fff;
}

.landing-page-cover-quotation-details {
  margin-top: 20px;
  padding: 5px 0px;
  background-color: rgb(117 115 115 / 50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 95px;
  border-radius: 20px;
  float: inline-end;
}

.landing-page-cover-quotation-cancel-icon {
  color: #f9912d;
  margin-top: -90px;
  background-color: white;
  border-radius: 50%;
  border: 1px solid #f9912d;
  margin-left: 35px;
  width: 24px;
  font-size: 24px;
  cursor: pointer;
  padding: 2px;
}

.landing-page-cover-quotation-cancel-icon:hover {
  background-color: #f9912d;
  color: #fff;
}

.landing-page-cover-quotation-details-parent {
  margin-top: 16px;
  width: 80px;
  position: relative;
  height: 80px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: -50px;
}

.landing-page-cover-quotation-details-child {
  position: absolute;
  top: -1px;
  left: 21px;
  border-radius: 50%;
  background: linear-gradient(163.23deg, #dee2e7, #dbe0e7);
  box-shadow: 0 15px 30px rgba(142, 155, 174, 0.2),
    0 3px 8px 1px rgba(68, 17, 85, 0.1) inset;
  border: 1px solid rgba(255, 255, 255, 0.5);
  box-sizing: border-box;
  width: 46.1px;
  height: 46.1px;
}
.landing-page-cover-quotation-details-item {
  top: 2.9px;
  left: 25px;
  background: linear-gradient(90deg, #eb4819, #f78f5f 48.71%, #ff8d1b);
  width: 38.9px;
  height: 38.3px;
}
.landing-page-cover-quotation-details-div,
.landing-page-cover-quotation-details-inner,
.landing-page-cover-quotation-details-item {
  position: absolute;
  border-radius: 50%;
}
.landing-page-cover-quotation-details-inner {
  top: 5.2px;
  left: 27px;
  background: linear-gradient(145.7deg, #f5f5f9, rgba(228, 232, 238, 0));
  box-shadow: 3px 3px 8px rgba(255, 255, 255, 0.15) inset,
    0 8px 15px rgba(142, 155, 174, 0.5), 0 31px 60px rgba(142, 155, 174, 0.2);
  border: 1px solid var(--color-white);
  box-sizing: border-box;
  width: 33.7px;
  height: 33.7px;
}
.landing-page-cover-quotation-details-div {
  top: 8px;
  left: 30px;
  background: linear-gradient(
    90deg,
    rgba(235, 72, 25, 0.8),
    rgba(247, 143, 95, 0.8) 48.71%,
    rgba(255, 141, 27, 0.8)
  );
  width: 28px;
  height: 28px;
}

.landing-page-cover-quotation-days-remaining,
.landing-page-cover-quotation-days {
  position: absolute;
  display: flex;
  align-items: center;
}
.landing-page-cover-quotation-days {
  top: 18.4px;
  right: calc(50% - 14.5px);
  letter-spacing: 0.29px;
  line-height: 32px;
  justify-content: center;
  width: 18.8px;
  height: 6.4px;
  color: #fff;
  font-size: 17px;
  font-family: "Montserrat", sans-serif;
}
.landing-page-cover-quotation-days-remaining {
  margin-top: -29px;
  font-weight: 500;
  text-align: center;
  font-size: 12.5px;
  color: #fff;
}

/* ------------------------------------------------------------------------- */

.landing-page-content {
  top: 100%;
}
/* business number statistics */

.number-statistics-section {
  width: 100%;
  height: 180px;
  margin-top: -20px;
  /* margin-top: 50px; */
}

.number-statistics-section-img {
  width: 100%;
  height: 420px;
  position: absolute;
  z-index: -1;
}

.mobile-number-statistics-section-img {
  display: none;
}

.number-statistics {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  padding: 0px;
  margin-bottom: 20px;
}

.number-statistics-item {
  width: 33.2%;
  height: 93%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.number-statistics-divider {
  height: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.number-statistics-divider div {
  height: 62%;
  border: 1px solid #f9912d;
  border-top: none;
  border-bottom: none;
  margin: auto;
}

.number-statistics-count {
  color: #f9912d;
  font-family: "Poppins" sans-serif;
  font-size: 35px;
  font-weight: 400;
}

.number-statistics-description {
  color: #686666;
  font-family: "Montserrat" sans-serif;
  font-weight: 500;
  padding-top: 20px;
  font-size: 19px;
  width: 200px;
  text-align: center;
  letter-spacing: 0.5px;
}

.mobile-statistics-description {
  display: none;
}

.projects-statistics-count p {
  width: 250px;
}

/* how-it-works */

.how-it-works-container {
  width: 90%;
  margin-right: auto;
  margin-left: auto;
  padding: 20px;
  box-shadow: 0px 2px 4px #a8a8a8;
  background: linear-gradient(to top, #fff9f4, #fff);
}

.how-it-works {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 33px auto;
  gap: 20px;
}

.quotation-making-video-container {
  width: 55%;
  height: 400px;
}

.quotation-making-video-container h2,
.signup-video-container h2 {
  font-size: 14.5px;
  color: #636363;
  font-weight: 600;
  margin-bottom: 15px;
}

.quotation-making-video {
  width: 100%;
  height: 315px;
}

.signup-video-container {
  width: 45%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.signup-video {
  width: 80%;
  height: 220px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 25px;
}

.signup-video-container h2 {
  width: 80%;
}

.signup-video-text-container {
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: start;
  gap: 15px;
}

.signup-video-button {
  padding: 7px 15px;
  background: linear-gradient(to right, #eb4819 0%, #f78f5f 49%, #ff8d1b 100%);
  border: none;
  border-radius: 6px;
  color: #fff;
  font-size: 12px;
  width: 35%;
  cursor: pointer;
  font-weight: 500;
}

.signup-video-text {
  color: #636363;
  font-weight: 500;
  text-align: justify;
  width: 65%;
  font-size: 11.5px;
}

.no-button-video-text {
  width: 100%;
}

.how-it-works-content {
  width: 400px;
}

.how-it-works-content h2 {
  font-family: "Montserrat" sans-serif;
  font-weight: 600;
  letter-spacing: 0.5px;
  font-size: 29px;
  color: #3c3c3c;
}

.how-it-works-tag-line {
  font-family: "Montserrat" sans-serif;
  font-size: 16.5px;
  margin: 18px 0px;
  width: 280px;
  line-height: 23px;
  text-align: justify;
  font-weight: 500;
}

.how-it-works-step {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin: 10px 0;
}

.how-it-works-step h3 {
  font-size: 16px;
  font-weight: 600;
  width: 74px;
}

.how-it-works-step-details {
  width: 326px;
}

.how-it-works-step-details h4 {
  font-size: 16px;
  margin-bottom: 5px;
}

.how-it-works-step-details p {
  font-size: 14px;
  width: 220px;
  line-height: 20px;
}

/* about us */

.about-us-image {
  width: 100%;
  height: 500px;
  z-index: -1;
  position: absolute;
}

.about-us {
  width: 100%;
  height: 500px;
  padding: 20px 0px;
  border-radius: 20px;
}

.about-us-content {
  width: 420px;
  height: 450px;
  background-color: rgba(192, 76, 76, 0.6);
  margin-top: 10px;
  margin-left: 80px;
  padding: 50px 30px;
  color: #fff;
  border-radius: 30px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.about-us-title {
  font-weight: 500;
  font-size: 30px;
}

.about-us-subtitle {
  margin: 25px 0px;
  font-weight: 500;
  font-size: 16px;
}

.about-us-text {
  font-size: 13px;
  margin: 20px 0px;
  line-height: 25px;
  text-align: justify;
}

/* quotation list */
.quotation-section {
  width: 90%;
  margin: 0px auto 20px auto;
  border-bottom: 3px solid rgb(234, 231, 231);
}

.quotation-actions-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px 20px 15px 20px;
}

.quotation-search {
  display: flex;
  align-items: center;
}

.quotation-search-input {
  width: 330px;
  color: #f9912d;
  padding: 6px 10px 6px 40px;
  border-radius: 6px;
  border: 1px solid #f9912d;
  outline: #f9912d;
  font-family: "Montserrat" sans-serif;
}

.quotation-search-input::placeholder {
  color: #f9912d;
  font-family: "Montserrat" sans-serif;
}

.quotation-search-icon {
  color: #f9912d;
  position: absolute;
  margin: 0px 15px;
}

.quotation-view-all-button {
  padding: 6px 10px;
  color: #f9912d;
  border: 1px solid #f9912d;
  border-radius: 6px;
  cursor: pointer;
  background-color: #fff;
}

.quotation-view-all-button:hover {
  background-color: #f9912d;
  color: #fff;
}

.quotation-bottom-button {
  text-align: center;
  margin: 15px 15px 35px 15px;
}

.quotation-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
}

.quotation-item {
  width: 22.5%;
  height: 240px;
  margin: 14px;
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.quotation-item:hover {
  box-shadow: 0px 2px 4px rgb(106, 105, 105);
}

.new-quotation {
  border: 1.5px solid #f9912d;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
}

.add-new {
  width: 100%;
  height: 95%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.add-new-icon {
  width: 60px;
  font-size: 60px;
  border-radius: 50%;
  border: 2px solid #f9912d;
  color: #f9912d;
  padding: 3px;
}

.new-quotation p {
  padding: 6px;
  border-top: 1.5px solid #f9912d;
  font-weight: 700;
  font-size: 13.5px;
  letter-spacing: 0.3px;
  width: 100%;
  text-align: center;
  color: #5a5656;
}

.quotation-image {
  width: 20.4%;
  height: 240px;
  position: absolute;
  z-index: -1;
  border-radius: 20px;
}

.quotation-info {
  text-align: center;
  color: #fff;
  padding-top: 135px;
}

.quotation-info-line {
  font-weight: 600;
  margin-bottom: 20px;
  font-size: 15px;
}

.quotation-name {
  padding: 6px 8px;
  border: 1px solid #fff;
  width: 80%;
  margin-right: auto;
  margin-left: auto;
  border-radius: 6px;
  font-size: 15px;
}

.edit-delete-qoutation {
  width: 100%;
  z-index: 999;
}

.edit-qoutation-icon,
.delete-qoutation-icon {
  float: right;
  margin: 5px 5px 5px 0;
  font-size: 20px;
  color: #fff;
  cursor: pointer;
}

.edit-qoutation-icon:hover,
.delete-qoutation-icon:hover {
  color: #f9912d;
  transform: scale(1.01);
}

.membership-expiry-line {
  background: linear-gradient(to top, #f5a357, #f9c391, #ffffff);
  color: #5a5656;
  font-weight: 600;
  padding: 20px;
  text-align: center;
  border-radius: 10px;
  font-size: 14px;
}

.membership-expiry-line span {
  font-weight: 700;
  color: #f9912d;
  cursor: pointer;
}

.membership-expiry-line span:hover {
  text-decoration: underline;
}

.membership-close-icon:hover {
  color: #fff;
  background-image: linear-gradient(to bottom, #f9912d, #f9912d);
}

.mobile-user-access {
  display: none;
}

.membership-referral-instructions {
  width: 70%;
  margin: 10px auto -10px auto;
  box-shadow: 0px 3px 4px #b4b1b1;
  border-radius: 10px;
  background: linear-gradient(to top, #f9c391, #ffffff);
  font-size: 11px;
  margin-bottom: 4px;
}

.referral-details {
  font-size: 11px;
  margin-bottom: 4px;
}

.landing-page-referral-content {
  background-color: #f9f9f9; /* Light background for contrast */
  border: 1px solid #e1e1e1; /* Light border */
  border-radius: 8px; /* Rounded corners */
  padding: 20px; /* Padding for spacing */
  margin: 20px 0; /* Margin to separate from other content */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  font-family: 'Arial', sans-serif; /* Font styling */
}

.landing-page-referral-days {
  font-size: 1rem; /* Slightly smaller font for days message */
  color: #555; /* Slightly lighter text color */
  margin-top: 5px; /* Space above the days message */
  font-weight: 500; /* Medium weight for emphasis */
}

@media screen and (max-width: 2570px) {
  .landing-page-cover-content {
    justify-content: flex-start;
    padding: 77px 0px;
  }

  .auth-landing-page-cover-content {
    padding: 5% 0;
  }

  .landing-page-cover-heading h1 {
    width: 51%;
    font-size: 105px;
  }

  .auth-landing-page-cover-heading h1 {
    width: 100%;
    font-size: 50px;
  }

  .landing-page-cover-heading p {
    width: 50%;
    font-weight: 400;
    margin-top: 40px;
    font-size: 40px;
  }

  .landing-page-rate-finder {
    width: 62%;
    height: auto;
  }

  .landing-page-rate-title {
    font-size: 30px;
  }

  .landing-page-city-label,
  .landing-page-finishes-label,
  .landing-page-rates-label {
    color: #fff;
    font-size: 20px;
    margin-bottom: 8px;
  }

  .landing-page-city-name {
    font-size: 20px;
  }

  .landing-page-city-name option {
    color: #636363;
  }

  .landing-page-finishes-input {
    font-size: 20px;
    padding: 10px;
  }

  .landing-page-rates-value {
    padding: 10px;
    font-size: 21px;
  }

  .landing-page-rate-description {
    font-size: 18px;
  }

  .landing-page-services-title {
    font-size: 30px;
  }

  .landing-page-service-title {
    font-size: 20px;
  }

  .landing-page-service-subtitle {
    font-size: 17px;
  }

  .landing-page-service-img {
    height: 200px;
  }

  .landing-page-service-description {
    font-size: 16px;
  }

  .landing-page-service-button {
    padding: 10px 25px;
    font-size: 20px;
  }

  .landing-page-cover-quotation {
    margin-top: calc(90% - 82%);
  }

  .landing-page-cover-quotation h2 {
    width: 35%;
    text-align: center;
    padding: 15px;
    border: 3px solid #f9912d;
    font-size: 35px;
    font-weight: 500;
  }

  .landing-page-cover-quotation-details {
    width: 180px;
    padding: 22px 0;
  }

  .landing-page-cover-quotation-cancel-icon {
    margin-top: -184px;
    margin-left: 63px;
    width: 50px;
    font-size: 50px;
  }

  .landing-page-cover-quotation-details-parent {
    margin-top: 16px;
    width: 155px;
    height: 155px;
    margin-right: -98px;
  }

  .landing-page-cover-quotation-details-child {
    position: absolute;
    top: -1px;
    left: 21px;
    width: 121.1px;
    height: 121.1px;
  }

  .landing-page-cover-quotation-details-item {
    top: 2.9px;
    left: 25px;
    width: 113.9px;
    height: 113.3px;
  }

  .landing-page-cover-quotation-details-inner {
    top: 5.2px;
    left: 27px;
    width: 108.7px;
    height: 108.7px;
  }

  .landing-page-cover-quotation-details-div {
    top: 8px;
    left: 30px;
    width: 103px;
    height: 103px;
  }

  .landing-page-cover-quotation-days {
    top: 57.4px;
    right: calc(50% - 14.5px);
    font-size: 56px;
  }

  .landing-page-cover-quotation-days-remaining {
    margin-top: -29px;
    font-weight: 500;
    text-align: center;
    font-size: 23.5px;
    color: #fff;
  }

  /* business number statistics */

  .number-statistics-section {
    width: 100%;
    height: 450px;
  }

  .number-statistics-section-img {
    height: 450px;
  }

  .number-statistics-count {
    font-size: 58px;
    font-weight: 500;
  }

  .number-statistics-description,
  .projects-statistics-count p {
    font-size: 34px;
    width: 600px;
  }

  /* how-it-works */

  .quotation-making-video-container {
    width: 60%;
    height: 40%;
  }

  .how-it-works-content {
    width: 45%;
        margin-left: 30px;
  }

  .quotation-making-video-container h2,
  .signup-video-container h2 {
    font-size: 30px;
  }
  
  
  .signup-video-container {
    width: 45%;
  }
  
  .signup-video {
    width: 80%;
    height: 420px;
  }
  
  
  .signup-video-button {
    padding: 10px 15px;
    font-size: 20px;
    letter-spacing: 1px;
  }
  
  .signup-video-text {
    font-size: 20px;
  }
  
  .how-it-works-content h2 {
    font-weight: 600;
    font-size: 75px;
  }

  .how-it-works-tag-line {
    font-size: 34px;
    margin: 35px 0px;
    width: 100%;
    font-weight: 500;
  }

  .how-it-works-step {
    justify-content: flex-start;
    margin: 20px 0;
  }

  .how-it-works-step h3 {
    font-size: 35px;
    font-weight: 600;
    width: 200px;
  }

  .how-it-works-step-details {
    width: 326px;
  }

  .how-it-works-step-details h4 {
    font-size: 35px;
    margin-bottom: 10px;
  }

  .how-it-works-step-details p {
    line-height: 40px;
    font-size: 30px;
    width: 538px;
  }

  /* about us */

  .about-us-image {
    width: 100%;
    height: 700px;
    z-index: -1;
    position: absolute;
  }

  .about-us {
    width: 100%;
    height: 700px;
    padding: 20px 0px;
    border-radius: 20px;
  }

  .about-us-content {
    width: 34%;
    height: 96%;
  }

  .about-us-title {
    font-size: 52px;
  }

  .about-us-subtitle {
    letter-spacing: 0.5px;
    font-size: 25px;
  }

  .about-us-text {
    font-size: 26px;
    margin: 20px 0px;
    line-height: 31px;
  }

  /* quotation list */
  .quotation-section {
    margin: 0px auto 20px auto;
  }

  .quotation-actions-section {
    margin: 0px 25px 15px 25px;
  }

  .quotation-search-input {
    width: 450px;
    padding: 10px 10px 10px 50px;
    font-size: 25px;
  }

  .quotation-search-icon {
    font-size: 25px;
  }

  .quotation-view-all-button {
    padding: 10px;
    font-size: 25px;
  }

  .quotation-item {
    width: 23.6%;
    height: 330px;
    margin: 14px;
  }

  .add-new-icon {
    width: 80px;
    font-size: 80px;
  }

  .new-quotation p {
    padding: 10px;
    font-size: 20px;
  }

  .quotation-image {
    width: 21.4%;
    height: 330px;
  }

  .quotation-info {
    padding-top: 34%;
  }

  .quotation-info-line {
    font-size: 25px;
  }

  .quotation-name {
    padding: 10px;
    font-size: 25px;
  }

  .membership-expiry-line {
    padding: 20px;
    font-size: 20px;
  }

  .quotation-making-video {
    width: 100%;
    height: 700px;
  }
}

@media screen and (max-width: 2000px) {
  .landing-page-cover-content {
    padding: 40px 0px;
  }

  .auth-landing-page-cover-content {
    padding: 0;
  }

  .landing-page-cover-heading h1 {
    font-size: 55px;
  }

  .auth-landing-page-cover-heading h1 {
    width: 100%;
    font-size: 30px;
  }

  .landing-page-cover-heading p {
    margin-top: 20px;
    font-size: 20px;
  }

  .landing-page-rate-title,
  .landing-page-services-title {
    font-size: 20px;
  }

  .landing-page-city-label,
  .landing-page-finishes-label,
  .landing-page-rates-label {
    font-size: 14px;
  }

  .landing-page-city-name {
    font-size: 14px;
  }

  .landing-page-finishes-input {
    font-size: 14px;
    padding: 6px 10px;
  }

  .landing-page-rates-value {
    padding: 6px 10px;
    font-size: 14.5px;
  }

  .landing-page-rate-description {
    font-size: 12.5px;
  }

  .landing-page-service-title {
    font-size: 12px;
  }

  .landing-page-service-subtitle {
    font-size: 11.5px;
  }

  .landing-page-service-img {
    height: 150px;
  }

  .landing-page-service-description {
    font-size: 12px;
  }

  .landing-page-service-button {
    padding: 6px 15px;
    font-size: 14px;
  }

  .landing-page-cover-quotation {
    margin-top: calc(90% - 84%);
  }

  .landing-page-cover-quotation h2 {
    padding: 8px;
    font-size: 18px;
    width: 400px;
  }

  .landing-page-cover-quotation-details {
    width: 110px;
    padding: 5px 0;
  }

  .landing-page-cover-quotation-cancel-icon {
    margin-top: -114px;
    margin-left: 42px;
    width: 25px;
    font-size: 25px;
  }

  .landing-page-cover-quotation-details-parent {
    margin-top: 16px;
    width: 90px;
    height: 100px;
    margin-right: -53px;
    margin-left: 6px;
  }

  .landing-page-cover-quotation-details-child {
    position: absolute;
    top: -1px;
    left: 21px;
    width: 61.1px;
    height: 61.1px;
  }

  .landing-page-cover-quotation-details-item {
    top: 2.9px;
    left: 25px;
    width: 53.9px;
    height: 53.3px;
  }

  .landing-page-cover-quotation-details-inner {
    top: 5.2px;
    left: 27px;
    width: 48.7px;
    height: 48.7px;
  }

  .landing-page-cover-quotation-details-div {
    top: 8px;
    left: 30px;
    width: 43px;
    height: 43px;
  }

  .landing-page-cover-quotation-days {
    top: 26.4px;
    right: calc(50% - 16.5px);
    font-size: 29px;
  }

  .landing-page-cover-quotation-days-remaining {
    font-size: 12.5px;
    padding-left: 4px;
  }

  /* business number statistics */

  .number-statistics-section {
    width: 100%;
    height: 250px;
    margin-top: 20px;
  }

  .number-statistics-section-img {
    height: 250px;
  }

  .number-statistics-count {
    font-size: 40px;
    font-weight: 500;
  }

  .number-statistics-description,
  .projects-statistics-count p {
    font-size: 18px;
    width: 300px;
  }

  /* how-it-works */

  .quotation-making-video-container {
    width: 60%;
    height: 40%;
  }

  .quotation-making-video-container h2,
  .signup-video-container h2 {
    font-size: 18px;
  }

  .signup-video-container h2 {
    width: 95%;
  }
  

  .signup-video-container {
    width: 45%;
  }
  
  .signup-video {
    width: 95%;
    height: 280px;
  }

  .signup-video-text-container {
    width: 95%;
  }
  
  .signup-video-button {
    padding: 7px 15px;
    font-size: 14px;
    letter-spacing: 0.5px;
  }
  
  .signup-video-text {
    font-size: 13px;
  }
  
  .how-it-works-content {
   margin-left: 20px;
   width: 36%;
  }

  .how-it-works-content h2 {
    font-size: 35px;
  }

  .how-it-works-tag-line {
    font-size: 18px;
    line-height: 25px;
    margin: 20px 0px;
  }

  .how-it-works-step {
    justify-content: flex-start;
    margin: 20px 0;
  }

  .how-it-works-step h3 {
    font-size: 17px;
  }

  .how-it-works-step-details {
    width: 330px;
  }

  .how-it-works-step-details h4 {
    font-size: 17px;
    margin-bottom: 10px;
  }

  .how-it-works-step-details p {
    line-height: 26px;
    font-size: 16px;
    width: 330px;
  }

  /* about us */

  .about-us-image {
    height: 600px;
  }

  .about-us {
    height: 600px;
  }

  .about-us-content {
    width: 36%;
    height: 97%;
  }

  .about-us-title {
    font-size: 30px;
  }

  .about-us-subtitle {
    letter-spacing: 0.5px;
    font-size: 17px;
  }

  .about-us-text {
    font-size: 14px;
    margin: 20px 0px;
    line-height: 28px;
  }

  /* quotation list */
  .quotation-section {
    margin: 0px auto 20px auto;
  }

  .quotation-actions-section {
    margin: 0px 25px 15px 20px;
  }

  .quotation-search-input {
    width: 400px;
    padding: 10px 10px 10px 45px;
    font-size: 18px;
  }

  .quotation-search-icon {
    font-size: 18px;
  }

  .quotation-view-all-button {
    padding: 10px;
    font-size: 18px;
  }

  .quotation-item {
    width: 22.5%;
    height: 270px;
    margin: 14px;
  }

  .add-new-icon {
    width: 60px;
    font-size: 60px;
  }

  .new-quotation p {
    padding: 10px;
    font-size: 16px;
  }

  .quotation-image {
    width: 20.4%;
    height: 270px;
  }

  .quotation-info {
    padding-top: 150px;
  }

  .quotation-info-line {
    font-size: 17px;
  }

  .quotation-name {
    padding: 10px;
    font-size: 17px;
  }

  .membership-expiry-line {
    font-size: 14px;
  }

  .membership-referral-instructions {
    width: 80%;
  }

  .membership-referral-instructions p {
    font-size: 12px;
    margin-bottom: 4px;
  }

  .quotation-making-video {
    width: 100%;
    height: 380px;
  }
}

@media screen and (max-width: 1400px) {
  .landing-page-cover-content {
    padding: 30px 0px;
  }

  .landing-page-cover-heading h1 {
    width: 450px;
    font-size: 36px;
  }

  .auth-landing-page-cover-heading h1 {
    width: 100%;
    font-size: 22px;
  }

  .landing-page-cover-heading p {
    margin-top: 20px;
    font-size: 16px;
  }


  .landing-page-rate-title,
  .landing-page-services-title {
    font-size: 15px;
  }

  .landing-page-city-label,
  .landing-page-finishes-label,
  .landing-page-rates-label {
    font-size: 11px;
  }

  .landing-page-city-name {
    font-size: 11px;
  }

  .landing-page-finishes-input {
    font-size: 11px;
  }

  .landing-page-rates-value {
    font-size: 11px;
  }

  .landing-page-rate-description {
    font-size: 10px;
  }

  .landing-page-service-item {
    padding: 10px 20px;
  }

  .landing-page-service-title {
    font-size: 11px;
  }

  .landing-page-service-subtitle {
    font-size: 10px;
  }

  .landing-page-service-img {
    height: 115px;
  }

  .landing-page-service-description {
    font-size: 11px;
  }

  .landing-page-service-button {
    padding: 6px 15px;
    font-size: 11px;
  }

  .landing-page-cover-quotation {
    margin-top: calc(90% - 86%);
  }

  .signup-landing-page-cover-quotation {
    margin-top: calc(90% - 89%);
  }

  .landing-page-cover-quotation h2 {
    width: 300px;
    padding: 6px;
    font-size: 16px;
    border: 1px solid #f9912d;
  }

  .landing-page-cover-quotation-details {
    width: 89px;
    padding: 2px 0;
    margin-top: 10px;
  }

  .landing-page-cover-quotation-cancel-icon {
    margin-top: -94px;
    margin-left: 66px;
    width: 22px;
    font-size: 22px;
  }

  .landing-page-cover-quotation-details-parent {
    margin-top: 16px;
    width: 85px;
    height: 85px;
    margin-right: -137px;
    margin-left: -3px;
  }

  .landing-page-cover-quotation-details-child {
    position: absolute;
    top: -1px;
    left: 21px;
    width: 51.1px;
    height: 51.1px;
  }
  .landing-page-cover-quotation-details-item {
    top: 2.9px;
    left: 25px;
    width: 43.9px;
    height: 43.3px;
  }

  .landing-page-cover-quotation-details-inner {
    top: 5.2px;
    left: 27px;
    width: 38.7px;
    height: 38.7px;
  }
  .landing-page-cover-quotation-details-div {
    top: 8px;
    left: 30px;
    width: 33px;
    height: 33px;
  }

  .landing-page-cover-quotation-days {
    top: 21.4px;
    right: calc(50% - 13.5px);
    font-size: 22px;
  }

  .landing-page-cover-quotation-days-remaining {
    font-size: 10.5px;
    padding-left: 2px;
  }

  /* business number statistics */

  .number-statistics-section {
    width: 100%;
    height: 220px;
    margin-top: 20px;
  }

  .number-statistics-section-img {
    height: 220px;
  }

  .number-statistics-count {
    font-size: 35px;
    font-weight: 500;
  }

  .number-statistics-description {
    font-size: 16px;
    width: 180px;
  }

  .projects-statistics-count p {
    font-size: 16px;
    width: 200px;
  }

  /* how-it-works */
  .how-it-works {
    margin: 41px auto;
  }

  .quotation-making-video-container {
    width: 55%;
    height: 37%;
  }

  .quotation-making-video-container h2,
  .signup-video-container h2 {
    font-size: 14px;
  }
  
  .signup-video {
    height: 240px;
  }

  
  .signup-video-button {
    font-size: 12px;
    letter-spacing: 0.2px;
  }
  
  .signup-video-text {
    font-size: 11.5px;
  }

  .how-it-works-content {
    width: 35%;
  }

  .how-it-works-content h2 {
    font-size: 30px;
  }

  .how-it-works-tag-line {
    font-size: 15px;
    line-height: 18px;
    margin: 13px 0px;
  }

  .how-it-works-step {
    justify-content: flex-start;
    margin: 16px 0;
  }

  .how-it-works-step h3 {
    font-size: 15px;
    width: 80px;
  }

  .how-it-works-step-details {
    width: 280px;
  }

  .how-it-works-step-details h4 {
    font-size: 16px;
    margin-bottom: 5px;
  }

  .how-it-works-step-details p {
    line-height: 18px;
    font-size: 13px;
    width: 260px;
    text-align: justify;
  }

  /* about us */

  .about-us-image {
    height: 500px;
  }

  .about-us {
    height: 500px;
  }

  .about-us-content {
    width: 400px;
    height: 450px;
  }

  .about-us-title {
    font-size: 30px;
  }

  .about-us-subtitle {
    margin: 18px 0px;
    letter-spacing: 0.5px;
    font-size: 15px;
  }

  .about-us-text {
    font-size: 12px;
    margin: 15px 0px;
    line-height: 21px;
  }

  /* quotation list */
  .quotation-section {
    width: 90%;
    margin: -30px auto 20px auto;
  }

  .quotation-actions-section {
    margin: 0px 20px 15px 20px;
  }

  .quotation-search-input {
    width: 330px;
    padding: 6px 10px 6px 40px;
    font-size: 14px;
  }

  .quotation-search-icon {
    font-size: 14px;
  }

  .quotation-view-all-button {
    padding: 6px 10px;
    font-size: 14px;
  }

  .quotation-item {
    width: 22.5%;
    height: 240px;
  }

  .add-new-icon {
    width: 60px;
    font-size: 60px;
  }

  .new-quotation p {
    padding: 6px;
    font-size: 13.5px;
  }

  .quotation-image {
    width: 20.4%;
    height: 240px;
  }

  .quotation-info {
    padding-top: 135px;
  }

  .quotation-info-line {
    font-size: 14px;
  }

  .quotation-name {
    padding: 6px 8px;
    font-size: 14px;
  }

  .membership-expiry-line {
    font-size: 12px;
  }

  .membership-referral-instructions {
    width: 80%;
  }

  .membership-referral-instructions p {
    font-size: 11px;
    margin-bottom: 4px;
  }

  .quotation-making-video {
    height: 330px;
  }
}

@media screen and (max-width: 1100px) {
  .landing-page-cover-content {
    padding: 40px 0px;
  }

  .auth-landing-page-cover-content {
    padding: 0;
  }

  .landing-page-cover-heading h1 {
    width: 500px;
    font-size: 45px;
  }

  .auth-landing-page-cover-heading h1 {
    width: 100%;
    font-size: 22px;
  }

  .landing-page-cover-heading p {
    margin-top: 15px;
    font-size: 19px;
  }


  .landing-page-rate-title,
  .landing-page-services-title {
    font-size: 14px;
  }

  .landing-page-city-label,
  .landing-page-finishes-label,
  .landing-page-rates-label {
    font-size: 11px;
  }

  .landing-page-city-name {
    font-size: 11px;
  }

  .landing-page-finishes-input {
    font-size: 11px;
  }

  .landing-page-rates-value {
    font-size: 11px;
  }

  .landing-page-rate-description {
    font-size: 9px;
  }

  .landing-page-service-item {
    padding: 10px 8px;
}

  .landing-page-service-title {
    font-size: 10px;
  }

  .landing-page-service-subtitle {
    font-size: 8.5px;
  }

  .landing-page-service-img {
    height: 105px;
  }

  .landing-page-service-description {
    font-size: 9px;
  }

  .landing-page-service-button {
    padding: 5px 15px;
    font-size: 11px;
  }

  .landing-page-cover-quotation {
    margin-top: calc(90% - 85%);
  }

  .signup-landing-page-cover-quotation {
    margin-top: calc(90% - 90%);
  }

  .landing-page-cover-quotation h2 {
    padding: 6px;
    font-size: 15px;
    border: 1px solid #f9912d;
  }

  .landing-page-cover-quotation-details {
    width: 88px;
    padding: 2px 0;
    margin-right: 0px;
    margin-top: 20px;
  }

  .landing-page-cover-quotation-cancel-icon {
    margin-top: -96px;
  }

  /* business number statistics */
  .number-statistics-item {
    width: 32%;
  }

  .number-statistics-section {
    width: 100%;
    height: 200px;
    margin-top: 0px;
  }

  .number-statistics-section-img {
    height: 200px;
  }

  .number-statistics-count {
    font-size: 30px;
    font-weight: 500;
  }

  .number-statistics-description {
    font-size: 15px;
    width: 180px;
  }

  .projects-statistics-count p {
    font-size: 15px;
    width: 200px;
  }

  /* about us */

  .about-us-image {
    height: 470px;
  }

  .about-us {
    height: 470px;
    padding-bottom: 0px;
  }

  .about-us-content {
    width: 35%;
    height: 87%;
    padding: 30px 20px 0px 20px;
    margin-top: 35px;
  }

  .about-us-title {
    font-size: 30px;
  }

  .about-us-subtitle {
    margin: 18px 0px;
    letter-spacing: 0.5px;
    font-size: 15px;
  }

  .about-us-text {
    font-size: 12px;
    margin: 15px 0px;
    line-height: 21px;
  }

  /* quotation list */
  .quotation-section {
    width: 90%;
    margin: -20px auto 20px auto;
  }

  .quotation-actions-section {
    margin: 0px 20px 15px 20px;
  }

  .quotation-search-input {
    width: 330px;
    padding: 6px 10px 6px 40px;
    font-size: 14px;
  }

  .quotation-search-icon {
    font-size: 14px;
  }

  .quotation-view-all-button {
    padding: 6px 10px;
    font-size: 14px;
  }

  .quotation-item {
    width: 21.8%;
    height: 200px;
  }

  .add-new-icon {
    width: 50px;
    font-size: 50px;
  }

  .new-quotation p {
    padding: 6px;
    font-size: 13.5px;
  }

  .quotation-image {
    width: 20.5%;
    height: 200px;
  }

  .quotation-info {
    padding-top: 110px;
  }

  .quotation-info-line {
    font-size: 13px;
  }

  .quotation-name {
    padding: 6px 8px;
    font-size: 13px;
  }

  .membership-expiry-line {
    font-size: 12px;
  }

  .membership-referral-instructions {
    width: 80%;
  }

  .membership-referral-instructions p {
    font-size: 11px;
    margin-bottom: 4px;
  }

  /* how-it-works */
  .how-it-works {
    margin: 30px auto;
  }

  .quotation-making-video-container {
    width: 55%;
  }

  .quotation-making-video {
    height: 268px;
}

  .quotation-making-video-container h2,
  .signup-video-container h2 {
    font-size: 12px;
    margin-bottom: 10px;
  }
  
  .signup-video {
    height: 190px;
    margin-bottom: 14px;
  }

  
  .signup-video-button {
    font-size: 10.5px;
    letter-spacing: 0px;
    padding: 7px 10px;
  }
  
  .signup-video-text {
    font-size: 10px;
  }

  .how-it-works-content {
    width: 40%;
  }

  .how-it-works-content h2 {
    font-size: 24px;
  }

  .how-it-works-tag-line {
    font-size: 13px;
    line-height: 25px;
    margin: 13px 0px;
  }

  .how-it-works-step h3 {
    font-size: 13px;
    width: 80px;
  }

  .how-it-works-step-details h4 {
    font-size: 13px;
    margin-bottom: 5px;
  }

  .how-it-works-step-details p {
    font-size: 11.5px;
  }
}

@media screen and (max-width: 950px) {
  .landing-page-cover-content {
    padding: 165px 0px;
  }

  .auth-landing-page-cover-content {
    padding: 10% 0;
    padding-bottom: 4%;
  }

  .signup-landing-page-cover-img {
    height: 90vh;
  }

  .landing-page-cover-heading h1 {
    width: 92%;
    font-size: 55px;
  }

  .auth-landing-page-cover-heading h1 {
    width: 100%;
    font-size: 30px;
  }

  .landing-page-cover-heading p {
    width: 92%;
    margin-top: 35px;
    font-size: 25px;
  }

  .landing-page-rate-services {
    gap: 40px;
    margin-top: 10%;
    flex-direction: column;
    align-items: center;
  }

  .landing-page-rate-finder {
    height: auto;
    width: 105%;
  }

  .landing-page-rate-title,
  .landing-page-services-title {
    font-size: 22px;
  }

  .landing-page-city-label,
  .landing-page-finishes-label,
  .landing-page-rates-label {
    font-size: 18px;
  }

  .landing-page-city-name {
    font-size: 18px;
  }

  .landing-page-finishes-input {
    font-size: 18px;
    padding: 10px;
  }

  .landing-page-rates-value {
    font-size: 18px;
    padding: 10px;
  }

  .landing-page-rate-description {
    font-size: 16px;
  }

  .landing-page-services-list {
    flex-wrap: wrap;
}

  .landing-page-services-section {
    width: 100%;
  }

  .landing-page-service-item {
    padding: 10px 20px;
    width: 49%;
}

  .landing-page-service-title {
    font-size: 19px;
  }

  .landing-page-service-subtitle {
    font-size: 16px;
  }

  .landing-page-service-img {
    height: 180px;
  }

  .landing-page-service-description {
    font-size: 15px;
  }

  .landing-page-service-button {
    padding: 7px 15px;
    font-size: 16px;
  }

  .landing-page-cover-quotation {
    margin-top: calc(90% - 70%);
  }

  .signup-landing-page-cover-quotation {
margin-top: 0;
  }

  .landing-page-cover-quotation h2 {
    width: 45%;
    padding: 8px;
    font-size: 25px;
    letter-spacing: 0.7px;
  }

  .landing-page-cover-quotation-details {
    width: 115px;
    padding: 5px 0;
    margin-top: 80px;
    margin-right: 0px;
  }

  .landing-page-cover-quotation-cancel-icon {
    margin-top: -122px;
    margin-left: 82px;
    width: 30px;
    font-size: 30px;
  }

  .landing-page-cover-quotation-details-parent {
    width: 105px;
    height: 105px;
    margin-left: 1px;
  }

  .landing-page-cover-quotation-details-child {
    position: absolute;
    top: -1px;
    left: 21px;
    width: 71.1px;
    height: 71.1px;
  }
  .landing-page-cover-quotation-details-item {
    top: 2.9px;
    left: 25px;
    width: 63.9px;
    height: 63.3px;
  }

  .landing-page-cover-quotation-details-inner {
    top: 5.2px;
    left: 27px;
    width: 58.7px;
    height: 58.7px;
  }
  .landing-page-cover-quotation-details-div {
    top: 8px;
    left: 30px;
    width: 53px;
    height: 53px;
  }

  .landing-page-cover-quotation-days {
    top: 31.4px;
    right: calc(50% - 14.5px);
    font-size: 35px;
  }
  .landing-page-cover-quotation-days-remaining {
    font-size: 13px;
    color: #fff;
    padding-left: 4px;
  }

  /* business number statistics */
  .number-statistics-item {
    width: 33%;
  }

  .number-statistics-section {
    width: 100%;
    height: 200px;
    margin-top: 115px;
  }

  .number-statistics-section-img {
    height: 200px;
  }

  .number-statistics-count {
    font-size: 30px;
    font-weight: 500;
  }

  .number-statistics-description {
    font-size: 17px;
    width: 190px;
  }

  .projects-statistics-count p {
    font-size: 17px;
    width: 200px;
  }

  /* how-it-works */
  .how-it-works {
    margin: 25px auto;
    width: 100%;
  }

  .quotation-making-video-container {
    width: 55%;
    height: 350px;
  }

  .quotation-making-video {
    height: 280px;
}

  .quotation-making-video-container h2,
  .signup-video-container h2 {
    font-size: 16px;
  }
  
  .signup-video {
    height: 160px;
  }

  .signup-video-text-container {
    flex-direction: column-reverse;
    align-items: center;
}
  
  .signup-video-button {
    font-size: 14px;
    width: 50%;
  }
  
  .signup-video-text {
    font-size: 13px;
    width: 100%;
  }


  .how-it-works-content {
    width: 45%;
    margin-left: 0;
    margin-right: 20px;

  }

  .how-it-works-content h2 {
    font-size: 30px;
  }

  .how-it-works-tag-line {
    font-size: 18px;
  }


  .how-it-works-step h3 {
    font-size: 17px;
    width: 80px;
  }


  .how-it-works-step-details h4 {
    font-size: 18px;
  }

  .how-it-works-step-details p {
    line-height: 20px;
    font-size: 16px;
    width: 300px;
    text-align: justify;
  }

  /* about us */

  .about-us-content {
    width: 47%;
    height: 87%;
    padding: 30px 20px 0px 20px;
    margin-top: 35px;
  }

  .about-us-title {
    font-size: 30px;
  }

  .about-us-subtitle {
    margin: 18px 0px;
    letter-spacing: 0.5px;
    font-size: 18px;
  }

  .about-us-text {
    font-size: 17px;
    margin: 10px 0px;
    line-height: 17px;
  }

  /* quotation list */
  .quotation-section {
    width: 90%;
    margin: -150px auto 20px auto;
  }

  .quotation-actions-section {
    margin: 0px 20px 15px 20px;
  }

  .quotation-search-input {
    width: 330px;
    padding: 10px 10px 10px 40px;
    font-size: 15px;
  }

  .quotation-search-icon {
    font-size: 15px;
  }

  .quotation-view-all-button {
    padding: 10px;
    font-size: 15px;
  }

  .quotation-item {
    width: 29.5%;
    height: 240px;
  }

  .add-new-icon {
    width: 60px;
    font-size: 60px;
  }

  .new-quotation p {
    padding: 10px;
    font-size: 15px;
  }

  .quotation-image {
    width: 26.5%;
    height: 240px;
  }

  .quotation-info {
    padding-top: 140px;
  }

  .quotation-info-line {
    font-size: 15px;
  }

  .quotation-name {
    padding: 8px;
    font-size: 15px;
  }

  .membership-expiry-line {
    font-size: 16px;
  }

  .membership-referral-instructions {
    width: 80%;
    padding: 10px;
  }

  .membership-referral-instructions p {
    font-size: 14px;
    margin-bottom: 12px;
  }
}

@media screen and (max-width: 850px) {
  .landing-page-cover-content {
    padding: 65px 0px;
  }

  .auth-landing-page-cover-content {
    padding: 10% 0;
    padding-bottom: 5%;
  }

  .landing-page-cover-quotation h2 {
    width: 50%;
  }

  .landing-page-rate-services {
    gap: 20px;
    margin-top: 3%;
  }


  .landing-page-rate-title,
  .landing-page-services-title {
    font-size: 18px;
  }

  .landing-page-city-label,
  .landing-page-finishes-label,
  .landing-page-rates-label {
    font-size: 15px;
  }

  .landing-page-city-name {
    font-size: 15px;
    width: 50%;
  }

  .landing-page-finishes-input {
    font-size: 15px;
    padding: 8px 10px;
  }

  .landing-page-rates-value {
    font-size: 15px;
    padding: 8px 10px;
  }

  .landing-page-rate-description {
    font-size: 14px;
  }

  .landing-page-service-title {
    font-size: 16px;
  }

  .landing-page-service-subtitle {
    font-size: 14px;
  }

  .landing-page-service-img {
    height: 160px;
  }

  .landing-page-service-description {
    font-size: 14px;
  }

  .landing-page-service-button {
    padding: 7px 15px;
    font-size: 16px;
  }

  .landing-page-cover-quotation {
    margin-top: calc(90% - 76%);
    width: 100%;
  }

  .signup-landing-page-cover-quotation {
    margin-top: calc(90% - 88%);
  }

  /* business number statistics */

  .number-statistics-section {
    margin-top: 60px;
  }

  .number-statistics-item {
    width: 33%;
  }

  .projects-statistics-count p {
    font-size: 17px;
    width: 210px;
  }

  /* how-it-works */

  .quotation-making-video-container {
    width: 55%;
    height: 350px;
  }

  .quotation-making-video {
    height: 260px;
}

  .quotation-making-video-container h2,
  .signup-video-container h2 {
    font-size: 14px;
  }
  
  .signup-video {
    height: 147px;
  }

  
  .signup-video-button {
    font-size: 13px;
    width: 55%;
  }
  
  .signup-video-text {
    font-size: 12px;
  }

  .how-it-works-content {
    width: 47%;
    margin-right: 0;
  }

  .how-it-works-content h2 {
    font-size: 25px;
  }

  .how-it-works-tag-line {
    font-size: 15px;
  }


  .how-it-works-step h3 {
    font-size: 13px;
    width: 80px;
  }

  .how-it-works-step-details h4 {
    font-size: 13px;
  }

  .how-it-works-step-details p {
    line-height: 18px;
    font-size: 11.5px;
    width: 260px;
    text-align: justify;
  }

  /* about us */

  .about-us-content {
    width: 47%;
    height: 91%;
    margin-top: 20px;
    margin-left: 30px;
  }

  .about-us-title {
    font-size: 25px;
  }

  .about-us-subtitle {
    margin: 18px 0px;
    letter-spacing: 0.5px;
    font-size: 16px;
  }

  .about-us-text {
    font-size: 14.5px;
    margin: 10px 0px;
    line-height: 19px;
  }

  /* quotation list */
  .quotation-section {
    width: 90%;
    margin: -40px auto 20px auto;
  }

  .quotation-actions-section {
    margin: 0px 25px 15px 20px;
  }

  .quotation-search-input {
    width: 330px;
    padding: 10px 10px 10px 40px;
    font-size: 15px;
  }

  .quotation-search-icon {
    font-size: 15px;
  }

  .quotation-view-all-button {
    padding: 10px;
    font-size: 15px;
  }

  .quotation-item {
    width: 45%;
    height: 240px;
  }

  .add-new-icon {
    width: 60px;
    font-size: 60px;
  }

  .new-quotation p {
    padding: 10px;
    font-size: 15px;
  }

  .quotation-image {
    width: 41.5%;
    height: 240px;
  }

  .quotation-info {
    padding-top: 140px;
  }

  .quotation-info-line {
    font-size: 15px;
  }

  .quotation-name {
    padding: 8px;
    font-size: 15px;
  }

  .membership-expiry-line {
    font-size: 15px;
  }

  .membership-referral-instructions {
    width: 80%;
    padding: 8px;
  }

  .membership-referral-instructions p {
    font-size: 13px;
    margin-bottom: 8px;
  }
}

@media screen and (max-width: 600px) {
  .signup-landing-page-cover-img {
    height: 110vh;
  }

  .landing-page-cover-img {
    height: 100%;
  }

  .landing-page-cover-content {
    padding: 40px 0px;
    width: 90%;
  }

  .auth-landing-page-cover-content {
    padding-top: 1%;
    height:auto;
  }

  .landing-page-cover-heading h1 {
    width: 100%;
    font-size: 40px;
    text-align: center;
  }

  .auth-landing-page-cover-heading h1 {
    width: 100%;
    font-size: 22px;
  }

  .landing-page-cover-heading p {
    width: 100%;
    margin-top: 15px;
    font-size: 17px;
    text-align: center;
  }

  .landing-page-rate-services {
    gap: 20px;
    margin-top: 3%;
  }

  .landing-page-rate-finder {
    padding: 10px;
  }

  .landing-page-city-selection,
  .landing-page-rate-content {
    margin-top: 6px;
  }

  .landing-page-city-selection {
    z-index: 998;
    position: relative;
  }

  .landing-page-rate-title,
  .landing-page-services-title {
    font-size: 14px;
    text-align: center;
  }

  .landing-page-city-label,
  .landing-page-finishes-label,
  .landing-page-rates-label {
    font-size: 11px;
    margin-bottom: 5px;
  }

  .landing-page-city-name {
    font-size: 11px;
    width: 50%;
  }

  .landing-page-rate-content {
    gap: 10px;
    align-items: self-end;
    flex-direction: column-reverse;
    gap: 10px;
    margin-top: -46px;
  }

  .landing-page-finishes-selection {
    width: 100%;
  }

  .landing-page-rates-display {
    width: 25%;
    text-align: center;
  }

  .landing-page-finishes-input {
    font-size: 11px;
    padding: 6px 10px;
  }

  .landing-page-rates-value {
    font-size: 11px;
    padding: 6px 10px;
  }

  .landing-page-rate-description {
    font-size: 10px;
    text-align: center;
  }

  .landing-page-services-section {
    width: 100%;
    padding: 20px 10px;
  }

  .landing-page-service-item {
    padding: 10px ;
    width: 48.5%;
}

  .landing-page-service-title {
    font-size: 12px;
  }

  .landing-page-service-subtitle {
    font-size: 10.5px;
  }

  .landing-page-service-img {
    height: 120px;
  }

  .landing-page-service-description {
    font-size: 10.5px;
  }

  .landing-page-service-button {
    padding: 7px 15px;
    font-size: 12px;
  }

  .landing-page-cover-quotation h2 {
    width: 80%;
    font-size: 17px;
    margin-right: auto;
    margin-left: auto;
  }

  .landing-page-cover-quotation {
    margin-top: calc(90% - 76%);
    width: 100%;
  }

  .signup-landing-page-cover-quotation {
    margin-top: calc(90% - 100%);
  }

  .landing-page-cover-quotation-details {
    float: inline-start;
    width: 94px;
  }

  .landing-page-cover-quotation-cancel-icon {
    margin-top: -101px;
    margin-left: 71px;
    width: 24px;
    font-size: 24px;
  }

  .landing-page-cover-quotation-details-parent {
    width: 105px;
    height: 90px;
    margin-left: 1px;
  }

  .landing-page-cover-quotation-details-child {
    position: absolute;
    top: -1px;
    left: 21px;
    width: 51.1px;
    height: 51.1px;
  }
  .landing-page-cover-quotation-details-item {
    top: 2.9px;
    left: 25px;
    width: 43.9px;
    height: 43.3px;
  }

  .landing-page-cover-quotation-details-inner {
    top: 5.2px;
    left: 27px;
    width: 38.7px;
    height: 38.7px;
  }
  .landing-page-cover-quotation-details-div {
    top: 8px;
    left: 30px;
    width: 33px;
    height: 33px;
  }

  .landing-page-cover-quotation-days {
    top: 22.4px;
    right: calc(50% - 4.5px);
    font-size: 21px;
  }
  .landing-page-cover-quotation-days-remaining {
    font-size: 10.5px;
    padding-left: 6px;
    margin-top: -23px;
  }
  /* business number statistics */
  .number-statistics {
    margin-top: 0px;
    flex-direction: column;
    padding: 10px;
    margin-bottom: 20px;
  }

  .number-statistics-item {
    width: 100%;
  }

  .number-statistics-section {
    width: 100%;
    height: 200px;
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .number-statistics-section-img {
    display: none;
  }

  .mobile-number-statistics-section-img {
    display: block;
    width: 100%;
    height: 300px;
    position: absolute;
    z-index: -1;
  }

  .number-statistics-count {
    font-size: 24px;
    font-weight: 500;
  }

  .laptop-statistics-description {
    display: none;
  }

  .mobile-statistics-description {
    display: block;
  }

  .number-statistics-description {
    font-size: 15px;
    width: 100%;
    padding-top: 0;
  }

  .projects-statistics-count p {
    font-size: 15px;
    width: 100%;
  }

  .number-statistics-divider {
    display: none;
  }

  /* how-it-works */
  .how-it-works-container{
    box-shadow: 0 0 0 #fff;
    width: 100%;
    margin-top: 20px;
  }

  .how-it-works {
    margin: 0px auto;
    width: 100%;
    flex-direction: column;
    gap: 15px;
  }

  .quotation-making-video-container,.signup-video-container {
    width: 100%;
    height: auto;
  }

  .quotation-making-video {
    height: 260px;
}

  .quotation-making-video-container h2,
  .signup-video-container h2 {
    font-size: 12px;
  }

  .signup-video {
    height: 250px;
  }

  
  .signup-video-button {
    font-size: 13px;
    padding:8px 10px ;
    width: 50%;
  }
  
  .signup-video-text {
    font-size: 12px;
  }

  .how-it-works-content {
    width: 85%;
    margin: 20px auto 0 auto;
  }

  .how-it-works-content h2 {
    font-size: 25px;
    text-align: center;
  }

  .how-it-works-tag-line {
    font-size: 13px;
    line-height: 10px;
    margin: 15px 0px;
    text-align: center;
  }

  .how-it-works-step {
    justify-content: space-between;
    margin: 16px 0;
  }

  .how-it-works-step h3 {
    font-size: 16px;
    width: 80px;
  }

  .how-it-works-step-details {
    width: 280px;
  }

  .how-it-works-step-details h4 {
    font-size: 16px;
    margin-bottom: 5px;
  }

  .how-it-works-step-details p {
    line-height: 20px;
    font-size: 15px;
    width: 100%;
  }

  /* about us */

  .about-us-content {
    width: 90%;
    height: 87%;
    padding: 30px 20px 0px 20px;
    margin: 25px auto 0px auto;
  }

  .about-us-image {
    object-fit: cover;
  }

  .about-us-title {
    font-size: 26px;
  }

  .about-us-subtitle {
    margin: 18px 0px;
    letter-spacing: 0.5px;
    font-size: 15px;
  }

  .about-us-text {
    font-size: 13px;
    margin: 10px 0px;
    line-height: 19px;
  }

  /* quotation list */
  .quotation-section {
    width: 100%;
    margin: 40px auto 20px auto;
  }

  .quotation-actions-section {
    margin: 0px 20px 15px 20px;
  }

  .quotation-search-input {
    width: 280px;
    padding: 7px 10px 7px 40px;
    font-size: 13px;
  }

  .quotation-search-icon {
    font-size: 13px;
  }

  .quotation-view-all-button {
    padding: 7px 10px;
    font-size: 13px;
  }

  .quotation-item {
    width: 44%;
    height: 200px;
  }

  .add-new-icon {
    width: 40px;
    font-size: 40px;
  }

  .new-quotation p {
    padding: 7px 10px;
    font-size: 14px;
  }

  .quotation-image {
    width: 44%;
    height: 200px;
  }

  .quotation-info {
    padding-top: 110px;
  }

  .quotation-info-line {
    font-size: 14px;
    margin-bottom: 15px;
  }

  .quotation-name {
    padding: 6px 8px;
    font-size: 14px;
  }

  .mobile-user-access {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    margin-top: 15px;
    margin-bottom: -15px;
  }

  .mobile-user-access button {
    padding: 7px 12px;
    font-size: 14px;
    background-color: #fff;
    border: 1px solid #f9912d;
    border-radius: 6px;
    color: #f9912d;
    font-weight: 500;
  }

  .mobile-user-access button:last-child {
    background: linear-gradient(to right, #eb4819 0%, #f78f5f 49%, #ff8d1b 100%);
    color: #fff;
    margin-bottom: 10px;
  }

  .membership-expiry-line {
    font-size: 13px;
  }

  .membership-referral-instructions {
    width: 95%;
  }

  .membership-referral-instructions p {
    font-size: 11.5px;
    margin-bottom: 5px;
  }

  .quotation-making-video {
    height: 250px;
  }
}

@media screen and (max-width: 450px) {
  .header-logo {
    height: 51px;
  }

  .landing-page-cover-content {
    width: 100%;
    padding-top: 60px;
  }

  .auth-landing-page-cover-content {
    padding-top: 0px;
    margin-top: 15px;
    height: auto;
  }

  .signup-landing-page-cover-img {
    height: 84vh;
  }

  .landing-page-cover-heading h1 {
    font-size: 30px;
  }

  .auth-landing-page-cover-heading h1 {
    font-size: 18px;
    padding: 5px;
  }

  .landing-page-cover-heading p {
    font-size: 16px;
  }

  .landing-page-cover-quotation h2 {
    font-size: 15px;
    padding: 8px;
  }

  .landing-page-rate-services {
    gap: 0;
    margin-top: 35px;
  }

  .landing-page-rate-finder {
    width: 100%;
    padding: 10px;
  }

  .landing-page-city-selection,
  .landing-page-rate-content {
    margin-top: 6px;
  }

  .landing-page-rate-title,
  .landing-page-services-title {
    font-size: 16px;
  }

  .landing-page-city-label,
  .landing-page-finishes-label,
  .landing-page-rates-label {
    font-size: 12px;
    margin-bottom: 5px;
  }

  .landing-page-city-name {
    font-size: 11px;
    width: 50%;
  }

  .landing-page-rate-content {
    align-items: self-end;
    flex-direction: column-reverse;
    gap: 10px;
    margin-top: -46px;
  }

  .landing-page-finishes-selection {
    width: 100%;
  }

  .landing-page-rates-display {
    width: 25%;
    text-align: center;
  }

  .landing-page-finishes-input {
    font-size: 12px;
    padding: 6px 10px;
  }

  .landing-page-rates-value {
    font-size: 12px;
    padding: 6px 10px;
  }

  .landing-page-rate-description {
    font-size: 12px;
    text-align: center;
  }

  .landing-page-services-list {
    /* display: -webkit-box; */
    overflow-x: scroll;
    float: right;
    justify-content: center;
  }

  .landing-page-services-list::-webkit-scrollbar {
    display: none;
  }

  .landing-page-service-item {
    width: 90%;
    margin: 0 10px;
  }

  .landing-page-services-section {
    width: 100%;
  }

  .landing-page-service-title {
    font-size: 13px;
  }

  .landing-page-service-subtitle {
    font-size: 11px;
  }

  .landing-page-service-img {
    height: 160px;
  }

  .landing-page-service-description {
    font-size: 12px;
    height: 50px;
  }

  .landing-page-service-button {
    padding: 7px 15px;
    font-size: 12px;
  }

  .landing-page-cover-quotation-details {
    float: inline-start;
    width: 94px;
    margin-left: 15px;
  }

  .number-statistics {
    margin-top: 0px;
  }

  /* how-it-works */

  .how-it-works-container{
    padding:30px 10px 10px 10px;
    width: 100%;
    box-shadow: 0 0 0 #fff;
  }

  .quotation-making-video-container {
    object-fit: cover;
  }

  .quotation-making-video {
    height: 260px;
}

  .quotation-making-video-container h2,
  .signup-video-container h2 {
    font-size: 13px;
  }

  .signup-video {
    width: 100%;
  }

  .signup-video-text-container {
    width: 100%;
}

  
  .signup-video-button {
    font-size: 14px;
    padding:8px 10px ;
    width: 50%;
  }
  
  .signup-video-text {
    font-size: 14px;
  }

  .how-it-works-content {
    width: 95%;
  }

  .how-it-works-content h2 {
    font-size: 27px;
  }

  .how-it-works-tag-line {
    font-size: 15px;
    line-height: 18px;
    margin: 10px 0px;
  }

  .how-it-works-step {
    justify-content: space-between;
    margin: 15px 0;
  }

  .how-it-works-step h3 {
    font-size: 15px;
    width: 80px;
  }

  .how-it-works-step-details h4 {
    font-size: 15px;
    margin-bottom: 5px;
  }

  .how-it-works-step-details p {
    line-height: 18px;
    font-size: 14px;
  }

  /* about us */

  .about-us-title {
    font-size: 26px;
  }

  .about-us-subtitle {
    font-size: 14px;
  }

  .about-us-text {
    font-size: 13px;
    margin: 10px 0px;
    line-height: 19px;
  }

  /* quotation list */
  .quotation-section {
    width: 100%;
    margin: 20px auto 20px auto;
  }

  .quotation-actions-section {
    margin: 0px 15px 15px 15px;
  }

  .quotation-search-input {
    width: 250px;
    padding: 7px 10px 7px 40px;
    font-size: 14px;
  }

  .quotation-search-icon {
    font-size: 14px;
  }

  .quotation-view-all-button {
    padding: 7px 10px;
    font-size: 13px;
  }

  .quotation-item {
    width: 45%;
    height: 180px;
    margin: 10px;
  }

  .add-new-icon {
    width: 40px;
    font-size: 40px;
  }

  .new-quotation p {
    padding: 7px 10px;
    font-size: 12px;
  }

  .quotation-image {
    width: 45%;
    height: 180px;
  }

  .quotation-info {
    padding-top: 100px;
  }

  .quotation-info-line {
    font-size: 12px;
    margin-bottom: 12px;
  }

  .quotation-name {
    padding: 6px 8px;
    font-size: 12px;
  }

  .mobile-user-access button {
    padding: 7px 12px;
    font-size: 14px;
    background-color: #fff;
    border: 1px solid #f9912d;
    border-radius: 6px;
    color: #f9912d;
    font-weight: 500;
  }
}

@media screen and (max-width: 400px) {
  .landing-page-cover-content {
    width: 100%;
  }

  .auth-landing-page-cover-content {
    height: auto;
}

  .signup-landing-page-cover-img {
    height: 110vh;
  }

  .landing-page-cover-heading h1 {
    font-size: 27px;
  }

  .landing-page-cover-heading p {
    width: 100%;
    margin-top: 15px;
    font-size: 15px;
    text-align: center;
  }

  .landing-page-rate-services {
    gap: 15px;
    margin-top: 1%;
  }

  .landing-page-rate-title,
  .landing-page-services-title {
    font-size: 13px;
  }

  .landing-page-city-label,
  .landing-page-finishes-label,
  .landing-page-rates-label {
    font-size: 11px;
  }

  .landing-page-city-name {
    font-size: 11px;
  }

  .landing-page-finishes-input {
    font-size: 11px;
    padding: 6px 10px;
  }

  .landing-page-rates-value {
    font-size: 11px;
    padding: 6px 10px;
  }

  .landing-page-rate-description {
    font-size: 10px;
    text-align: center;
  }

  .landing-page-services-section {
    width: 100%;
  }

  .landing-page-service-title {
    font-size: 12px;
  }

  .landing-page-service-subtitle {
    font-size: 10.5px;
  }

  .landing-page-service-img {
    height: 130px;
  }

  .landing-page-service-description {
    font-size: 12px;
  }

  .landing-page-service-button {
    padding: 5px 15px;
    font-size: 12px;
  }

  .landing-page-cover-quotation h2 {
    font-size: 15px;
    padding: 8px;
  }

  .landing-page-cover-quotation-details {
    float: inline-start;
    width: 94px;
    margin-left: 15px;
  }

  .signup-landing-page-cover-quotation-details {
    margin-top: 60px;
  }

  .landing-page-cover-quotation-cancel-icon {
    margin-top: -101px;
    margin-left: 71px;
    width: 24px;
    font-size: 24px;
  }

  .landing-page-cover-quotation-details-parent {
    width: 105px;
    height: 90px;
    margin-left: 1px;
  }

  .landing-page-cover-quotation-details-child {
    position: absolute;
    top: -1px;
    left: 21px;
    width: 51.1px;
    height: 51.1px;
  }
  .landing-page-cover-quotation-details-item {
    top: 2.9px;
    left: 25px;
    width: 43.9px;
    height: 43.3px;
  }

  .landing-page-cover-quotation-details-inner {
    top: 5.2px;
    left: 27px;
    width: 38.7px;
    height: 38.7px;
  }
  .landing-page-cover-quotation-details-div {
    top: 8px;
    left: 30px;
    width: 33px;
    height: 33px;
  }

  .landing-page-cover-quotation-days {
    top: 22.4px;
    right: calc(50% - 4.5px);
    font-size: 21px;
  }

  /* how-it-works */

  .how-it-works-container{
    padding: 10px;
    width: 100%;
    box-shadow: 0 0 0 #fff;
  }

  .quotation-making-video-container {
    object-fit: cover;
  }

  .quotation-making-video {
    height: 260px;
}

  .quotation-making-video-container h2,
  .signup-video-container h2 {
    font-size: 13px;
  }

  .signup-video-button {
    font-size: 13px;
    padding:8px 10px ;
    width: 50%;
  }
  
  .signup-video-text {
    font-size: 12px;
  }


  .how-it-works-content h2 {
    font-size: 25px;
  }

  .how-it-works-tag-line {
    font-size: 13px;
    line-height: 18px;
    margin: 10px 0px;
  }


  .how-it-works-step h3 {
    font-size: 13px;
    width: 80px;
  }

  .how-it-works-step-details h4 {
    font-size: 13px;
    margin-bottom: 5px;
  }

  .how-it-works-step-details p {
    font-size: 11px;
  }

  /* about us */

  .about-us-title {
    font-size: 26px;
  }

  .about-us-subtitle {
    font-size: 14px;
  }

  .about-us-text {
    font-size: 13px;
    margin: 10px 0px;
    line-height: 19px;
  }

  /* quotation list */
  .quotation-section {
    width: 100%;
    margin: 20px auto 20px auto;
  }

  .quotation-actions-section {
    margin: 0px 15px 15px 15px;
  }

  .quotation-search-input {
    width: 220px;
    padding: 6px 10px 6px 40px;
    font-size: 13px;
  }

  .quotation-search-icon {
    font-size: 13px;
  }

  .quotation-view-all-button {
    padding: 6px 8px;
    font-size: 13px;
  }

  .quotation-item {
    width: 44.3%;
    height: 160px;
    margin: 10px;
  }

  .add-new-icon {
    width: 35px;
    font-size: 35px;
  }

  .new-quotation p {
    padding: 5px 10px;
    font-size: 10.5px;
  }

  .quotation-image {
    width: 44.3%;
    height: 160px;
  }

  .quotation-info {
    padding-top: 90px;
  }

  .quotation-info-line {
    font-size: 10.5px;
    margin-bottom: 11px;
  }

  .quotation-name {
    padding: 6px;
    font-size: 11px;
  }

  .quotation-making-video {
    height: 220px;
  }
}

@media screen and (max-width: 350px) {
  .header-logo {
    width: 101px;
    height: 45px;
  }


  .auth-landing-page-cover-content{
    height: auto;
  }

  .landing-page-cover-heading h1 {
    font-size: 27px;
  }

  .landing-page-cover-heading p {
    font-size: 14px;
  }

  .quotation-making-video-container {
    width: 100%;
  }

  /* quotation list */

  .quotation-item {
    width: 44%;
  }

  .quotation-image {
    width: 44%;
  }

  .quotation-info-line {
    font-size: 10px;
  }

  .quotation-name {
    padding: 6px;
    font-size: 10px;
  }
}
