.firstPageCoverImg {
  width: 100%;
  height: 100%;
  z-index: -1;
}

.first-page-quotation-content {
  position: absolute;
  z-index: 2;
  width: 70%;
}

.first-page-quotation-title {
  width: 60%;
  text-align: center;
  margin-top: 25px;
}

.first-page-quotation-text {
  font-size: 40px;
  font-weight: bolder;
  margin: 5px 0px;
  color: #524138;
  opacity: 0.8;
}

.first-page-quotation-logo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  width: 420px;
  height: 100px;
  padding: 10px;
  margin-top: 180px;
  border-left: none;
  gap: 10px;
}

.first-page-logo-image {
  max-width: 85px;
  max-height: 85px;
  object-fit: contain;
}

.first-page-brand-name {
  color: #795f50;
  font-size: 22px;
  width: 70%;
  text-align: center;
}

.first-page-company-info {
  margin-top: 38%;
  width: 35%;
  text-align: center;
  margin-left: 5px;

}

.first-page-quotation-company {
  font-weight: 800;
  color: #524138;
  opacity: 0.8;
  font-size: 22px;
  margin-bottom: 10px;
}

.first-page-quotation-tagline {
  font-weight: 600;
  color: #524138;
  opacity: 0.7;
  font-size: 13px;
  margin-top: 0px;
}

.page-with-header-footer {
  height: 253mm;
}

.page-header {
  height: 90px;
  border-bottom: 1.5px solid #f9912d;
}

.page-header-content {
  display: flex;
  justify-content: space-between;
  padding: 0px 10px 0 35px;
  position: absolute;
  width: 210mm;
  margin-top: 5px;
}

.page-header-images {
  display: flex;
  justify-content: space-between;
}

.page-header-top-right-image {
  width: 350px;
}
.page-header-top-left-image {
  width: 100px;
}

.page-header-user {
  display: flex;
  max-width: 60%;
  justify-content: start;
  gap: 10px;
  margin-top: 5px;
}

.page-header-interioverse {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
}

.page-header-interioverse-name {
  color: #333;
  font-weight: 600;
  font-size: 10px;
  margin: 4px 0 0 0;
}

.page-header-logo {
  width: 60px;
  object-fit: fill;
}

.page-header-user-logo {
  max-width: 70px;
  max-height: 70px;
  object-fit: contain;
}

.page-header-user-brand-name {
  color: #524138;
  opacity: 0.8;
  margin: 8px 0;
  font-size: 19px;
}

.page-header-user-tagline {
  margin: 10px 0px 0 0;
}

.page-header-user-phn {
  margin: 1px 0px 0 0;
}

.page-header-user-tagline,
.page-header-user-phn {
  font-size: 9.5px;
  font-weight: 600;
  /* font-style: italic; */
  color: #333;
}

.page-footer {
  height: 75px;
  border-top: 1.5px solid #f9912d;
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  color: #131313;
  font-size: 12px;
  position: absolute;
  height: 76px;
  width: 199mm;
}

.footer-user-contact {
  width: 100%;
  text-align: right;
  font-weight: 500;
  font-size: 10.5px;
  color: #333;
}

.footer-user-company {
  font-size: 10.5px;
  font-weight: 600;
  color: #333;
}

.page-footer-images {
  display: flex;
  justify-content: space-between;
  align-content: flex-end;
  align-items: flex-end;
  margin-top: -19px;
}

.page-footer-images img {
  height: 90px;
  margin-top: 2.5px;
}

.page-footer-content {
  height: 253mm;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.thank-you-message {
  margin-top: 90px;
  margin-bottom: 40px;
  font-weight: 700;
  color: #333;
}

.page-footer-description {
  width: 90%;
  text-align: center;
  margin: 0;
  margin-bottom: 30px;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #333;
}

.page-footer-brand-section {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 50px;
}

.page-footer-brand-logo {
  width: 100px;
  height: 100px;
  object-fit: contain;
}

.page-footer-brand-name {
  color: #333;
  font-size: 30px;
}

.page-footer-and-symbol {
  font-size: 25px;
  color: #333;
  font-weight: 600;
 margin: 10px;
}

.page-footer-execution-text {
  font-size: 17px;
  color: #333;
  font-weight: 500;
}

.page-footer-execution-partner-logo {
  width: 350px;
  height: auto;
  margin-top: 30px;
}
