.service-booking-container {
  width: 70%;
  margin-right: auto;
  margin-left: auto;
  padding: 15px;
  background-color: rgb(117 115 115 / 20%);
  border-radius: 10px;
}

.service-booking {
  border-radius: 10px;
  background-color: #fff;
  padding: 10px 20px;
}

.service-close-icon {
  display: flex;
  position: absolute;
  cursor: pointer;
  z-index: 998;
  color: #f9912d;
  border: 1px solid #f9912d;
  background-image: linear-gradient(to left, #f8f5ea, #f8eee6);
  border-radius: 50%;
  padding: 1px;
  font-size: 25px;
  width: 25px;
  margin-top: -25px;
  margin-right: -10px;
  right: 15.5%;
}

.service-city-list {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.service-city {
  font-size: 11px;
  margin: 0px 5px;
  color: #636363;
}

.service-booking-form {
  margin-top: 15px;
}

.service-booking-title {
  text-align: center;
  font-weight: 500;
  color: #636363;
  font-size: 21px;
  margin-top: -10px;
}

.service-form-container {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}

.service-form-section {
  padding: 10px 20px;
  width: 45%;
}

.service-form-group {
  display: flex;
  flex-direction: column;
}

.service-form-label {
  font-size: 12px;
  color: #636363;
  font-weight: 500;
  margin-bottom: 10px;
}

.service-form-select {
  padding: 7px;
  font-size: 11px;
  border-radius: 8px;
  border: 2px solid #636363;
  cursor: pointer;
}

.service-map-container {
  width: 95%;
  margin-right: auto;
}

.service-map-container img {
  width: 100%;
  height: 200px;
}

.service-project-details {
  width: 45%;
  padding: 20px;
  background: linear-gradient(to bottom, #ffeddd, #fff);
  border-radius: 12px;
  margin-bottom: 10px;
}

.service-project-location {
  color: #636363;
  font-size: 12px;
  font-weight: 500;
}

.service-location {
  color: #e96505;
}

.service-price-details {
  margin: 15px 0px;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
}

.service-price-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.service-price-label {
  font-size: 12px;
  font-weight: 500;
  color: #636363;
  margin-bottom: 10px;
}

.service-price-value {
  font-size: 11px;
  padding: 4px 30px;
  border: 1.5px solid #636363;
  border-radius: 6px;
  margin-top: 10px;
}

.service-price-incl-gst {
  width: 100%;
  text-align: right;
  font-size: 10px;
  margin-top: 6px;
  font-weight: 500;
  color: #636363;
}

.service-interioverse-price {
  border: 1.5px solid #f9912d;
  color: #f9912d;
}

.service-earnings-label {
  width: 100%;
  text-align: center;
  font-size: 10px;
  color: #636363;
  font-weight: 500;
  margin-bottom: 5px;
}

.service-earnings-value {
  text-align: center;
  font-weight: 500;
  color: #f9912d;
  font-size: 21px;
}

.service-provides-label {
  margin-top: 15px;
  margin-bottom: 10px;
  font-size: 12px;
  font-weight: 500;
  color: #636363;
}

.service-provides-list {
  margin-left: 40px;
}

.service-provides-item {
  padding: 1px;
  font-size: 10px;
}

.service-booking-buttons {
  width: 38%;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.service-booking-button {
  padding: 4px 20px;
  border: 1.5px solid #f9912d;
  border-radius: 7px;
  color: #f9912d;
  background-color: #fff;
  font-weight: 500;
  cursor: pointer;
}

.email-sent-for-service{
  color: rgb(7, 174, 7);
  border: 1px solid rgb(7, 174, 7);
}

.service-button-text {
  font-weight: 600;
}

.service-button-subtext {
  font-size: 10px;
  margin-top: 2px;
}

.service-booking-button:hover {
  background: linear-gradient(to right, #eb4819 0%, #f78f5f 49%, #ff8d1b 100%);
  color: #fff;
}

.email-sent-for-service:hover{
  color: rgb(7, 174, 7);
  background: linear-gradient(to right, #fff 0%, #fff 49%, #fff 100%);
  cursor:default;
}


@media screen and (max-width: 2570px) {
  .service-booking-container {
    width: 50%;
    padding: 25px;
    margin-top: 10%;
  }

  .service-booking {
    padding: 20px;
  }

  .service-close-icon {
    font-size: 30px;
    width: 30px;
    right: 25%;
  }

  .service-city-list {
    margin-bottom: 15px;
  }

  .service-city {
    font-size: 15px;
  }

  .service-booking-title {
    font-size: 30px;
  }

  .service-form-section {
    padding: 20px;
    width: 40%;
  }

  .service-form-label {
    font-size: 18px;
  }

  .service-form-select {
    font-size: 17px;
  }

  .service-map-container img {
    width: 100%;
    height: 300px;
  }

  .service-project-details {
    width: 45%;
    padding: 20px;
  }

  .service-project-location {
    font-size: 16px;
  }

  .service-price-label {
    font-size: 17px;
  }

  .service-price-value {
    font-size: 16px;
  }

  .service-price-incl-gst {
    font-size: 13px;
  }

  .service-earnings-label {
    font-size: 16px;
  }

  .service-earnings-value {
    font-size: 28px;
  }

  .service-provides-label {
    font-size: 18px;
  }

  .service-earnings {
    margin-bottom: 20px;
  }

  .service-provides-item {
    padding: 5px;
    font-size: 15px;
  }

  .service-button-text {
    font-size: 18px;
  }

  .service-button-subtext {
    font-size: 14px;
  }

}

@media screen and (max-width: 2000px) {
  .service-booking-container {
    width: 70%;
    padding: 15px 20px;
    margin-top: 0;
  }

  .service-booking {
    padding: 15px;
  }

  .service-close-icon {
    font-size: 25px;
    width: 25px;
    right: 15.5%;
  }

  .service-city-list {
    margin-bottom: 10px;
  }

  .service-city {
    font-size: 12px;
  }

  .service-booking-title {
    font-size: 21px;
  }

  .service-form-section {
    padding: 10px 20px;
    width: 45%;
  }

  .service-form-label {
    font-size: 14px;
  }

  .service-form-select {
    font-size: 13.5px;
  }

  .service-map-container img {
    height: 200px;
  }

  .service-project-details {
    padding: 10px 20px;
  }

  .service-project-location {
    font-size: 13px;
  }

  .service-price-label {
    font-size: 14px;
  }

  .service-price-value {
    font-size: 14px;
  }

  .service-price-incl-gst {
    font-size: 11px;
  }

  .service-earnings-label {
    font-size: 14px;
  }

  .service-earnings-value {
    font-size: 20px;
  }

  .service-provides-label {
    font-size: 13px;
  }

  .service-earnings {
    margin-bottom: 10px;
  }

  .service-provides-item {
    padding: 3px;
    font-size: 11.5px;
  }

  .service-button-text {
    font-size: 14px;
  }

  .service-button-subtext {
    font-size: 11px;
  }
}

@media screen and (max-width: 1400px) {
  .service-booking-container {
    width: 70%;
  }

  .service-close-icon {
    font-size: 20px;
    width: 20px;
    margin-top: -20px;
  }

  .service-city {
    font-size: 10px;
  }

  .service-booking-form {
    margin-top: 10px;
  }

  .service-booking-title {
    font-size: 20px;
  }

  .service-form-label {
    font-size: 11px;
    margin-bottom: 5px;
  }

  .service-form-select {
    font-size: 11px;
  }

  .service-map-container img {
    height: 200px;
  }

  .service-project-details {
    padding: 10px 20px;
  }

  .service-project-location {
    font-size: 11px;
  }

  .service-price-label {
    font-size: 12px;
  }

  .service-price-value {
    font-size: 11px;
  }

  .service-price-incl-gst {
    font-size: 10px;
  }

  .service-earnings-label {
    font-size: 10px;
  }

  .service-earnings-value {
    font-size: 21px;
  }

  .service-provides-label {
    font-size: 12px;
  }

  .service-provides-item {
    padding: 2px;
    font-size: 10px;
  }

  .service-button-text {
    font-size: 13px;
  }

  .service-button-subtext {
    font-size: 10px;
  }
}

@media screen and (max-width: 1100px) {
  .service-booking-container {
    width: 85%;
    padding: 15px;
  }

  .service-close-icon {
    right: 8%;
  }

  .service-city {
    font-size: 9px;
  }

  .service-booking-title {
    font-size: 18px;
  }

  .service-form-label {
    font-size: 10px;
  }

  .service-form-select {
    font-size: 10px;
  }

  .service-project-location {
    font-size: 10px;
  }

  .service-price-label {
    font-size: 11px;
  }

  .service-price-value {
    font-size: 10px;
  }

  .service-price-incl-gst {
    font-size: 8px;
  }

  .service-earnings-label {
    font-size: 9px;
  }

  .service-earnings-value {
    font-size: 19px;
  }

  .service-provides-label {
    font-size: 10px;
  }

  .service-provides-item {
    font-size: 9px;
  }

  .service-button-text {
    font-size: 11px;
  }

  .service-button-subtext {
    font-size: 8px;
  }
}

@media screen and (max-width: 950px) {
  .service-booking-container {
    width: 95%;
    margin-top: 25%;
  }

  .service-close-icon {
    font-size: 22px;
    width: 22px;
    right: 2.5%;
  }

  .service-city {
    font-size: 12px;
  }

  .service-booking-title {
    font-size: 22px;
  }

  .service-form-label {
    font-size: 13px;
  }

  .service-form-select {
    font-size: 12px;
  }

  .service-project-location {
    font-size: 12px;
  }


  .service-price-label {
    font-size: 13px;
  }

  .service-price-value {
    font-size: 14px;
  }

  .service-price-incl-gst {
    font-size: 11.5px;
  }

  .service-earnings-label {
    font-size: 14px;
  }

  .service-earnings-value {
    font-size: 23px;
  }

  .service-provides-label {
    font-size: 15px;
  }

  .service-provides-item {
    font-size: 12px;
  }

  .service-button-text {
    font-size: 15px;
  }

  .service-button-subtext {
    font-size: 11px;
  }
}

@media screen and (max-width: 850px) {
  .service-booking-container {
    width: 100%;
    margin-top: 10%;
  }

  .service-close-icon {
    font-size: 20px;
    width: 20px;
    right: 1.5%;
  }

  .service-form-section,
  .service-project-details {
    padding: 10px;
    width: 50%;
  }

  .service-booking-buttons {
    width: 45%;
  }
}

@media screen and (max-width: 550px) {
  .service-booking-container {
    width: 100%;
    margin-top: 2%;
  }

  .service-close-icon {
    font-size: 22px;
    width: 22px;
    right: 1.5%;
  }

  .service-form-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 15px;
  }

  .service-form-section,
  .service-project-details {
    padding: 20px;
    width: 100%;
  }

  .service-project-location {
    text-align: center;
  }

  .service-booking-buttons {
    width: 100%;
  }

  .service-provides-item {
    padding: 5px;
  }
}

@media screen and (max-width: 450px) {
  .service-booking-container {
    width: 100%;
    margin-top: 2%;
  }

  .service-close-icon {
    font-size: 22px;
    width: 22px;
    right: 1.5%;
  }


  .service-form-section,
  .service-project-details {
    padding: 20px;
    width: 100%;
  }


  .service-city {
    font-size: 10px;
  }

  .service-booking-title {
    font-size: 18px;
  }

  .service-form-label {
    font-size: 12px;
  }

  .service-form-select {
    font-size: 12px;
  }

  .service-form-select option{
    padding: 5px 0px;
  }

  .service-project-location {
    font-size: 12px;
  }


  .service-price-label {
    font-size: 12px;
  }

  .service-price-value {
    font-size: 12px;
  }

  .service-price-incl-gst {
    font-size: 10px;
  }

  .service-earnings-label {
    font-size: 12px;
  }

  .service-earnings-value {
    font-size: 20px;
  }

  .service-provides-label {
    font-size: 12px;
  }

  .service-provides-item {
    font-size: 12px;
  }


  .service-button-text {
    font-size: 13px;
  }

  .service-button-subtext {
    font-size: 10px;
  }

}

@media screen and (max-width: 400px) {
  

  .service-provides-item {
    font-size: 11px;
  }

  .service-price-incl-gst {
    font-size: 9px;
}


}

@media screen and (max-width: 400px) {
  .service-provides-item {
    font-size: 11px;
  }

  .service-price-incl-gst {
    font-size: 9px;
}


}

@media screen and (max-width: 350px) {

  .service-form-section, .service-project-details {
    padding: 10px;
}

  .service-provides-item {
    font-size: 11px;
  }

  .service-price-incl-gst {
    font-size: 8px;
}

  .service-booking-buttons {
    width: 100%;
    gap: 8px;
  }

}
