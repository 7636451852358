.media-popup {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  z-index: 999;
  animation: fadeIn 0.5s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideDown {
  from {
    transform: translateY(-100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.media-popup-container {
  width: 100%;
  height: 450px;
  background-color: #fff;
  max-width: 75%;
  margin: auto;
  border-radius: 8px;
  padding: 20px;
  overflow: auto;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  animation: slideDown 0.6s ease-out;
}

.media-popup-container::-webkit-scrollbar{
  display: none;
}

.media-popup-icon {
  position: fixed;
  float: right;
  color: #f9912d;
  border-radius: 50%;
  padding: 3px;
  border: 1px solid #f9912d;
  font-size: 20px;
  width: 20px;
  cursor: pointer;
  right: 13.5%;
}

.media-popup-icon:hover {
  background-color: #f9912d;
  color: #fff;
}

.finishes-types-popup-title {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
  text-align: center;
  margin-top: 0;
  color: #636363;
}

.media-content {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 10px;
  justify-items: center; 
}

.media-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #f8f9fa;
  border: 2px dashed #ccc;
  border-radius: 6px;
  padding: 10px;
  transition: background 0.3s ease, transform 0.3s ease;
  cursor: pointer;
  width: 100%;
  height: 100%;
  position: relative;
}

.deleting-media-item{
  background: #fed5ad;
}

.media-item:hover {
  background: #e2e6ea;
  /* transform: translateY(-3px); */
}


.media-image {
  max-width: 100%;
  max-height: 150px;
  object-fit: cover;
  border-radius: 6px;
}

.uploaded-file-btns {
  display: flex;
  gap: 10px;
  margin-top: 10px;
  align-items: center;
}

.uploaded-file-btns button {
  padding: 6px 12px;
  border: none;
  border-radius: 4px;
  background: linear-gradient(to right, #eb4819 0%, #f78f5f 49%, #ff8d1b 100%);
  color: #fff;
  cursor: pointer;
  transition: background 0.3s ease;
}

.uploaded-file-delete-icon {
  font-size: 18px;
  color: #dc3545;
  cursor: pointer;
  transition: color 0.3s ease;
}

.uploaded-file-delete-icon:hover {
  color: #c82333;
}

.add-new-media {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f9912d;
  color: #fff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  transition: background-color 0.3s ease;
}



.add-new-media-icon {
  font-size: 30px;
}

.upload-media-text {
  margin-top: 10px;
  font-size: 13px;
  text-align: center;
  color: #6c757d;
  transition: color 0.3s ease;
}

.file-delete-icon{
  position: absolute;
  top: 2px;
  right: 2px;
  cursor: pointer;
  color: #f9912d;
  font-size: 13px;
}
