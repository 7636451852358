.final-quotation-template-container::-webkit-scrollbar{
display: none;
}
.final-quotation-table-container {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 16px;
  border: 1.5px solid #f16f40;
}

.final-quotation-template {
  display: flex;
  justify-content: center;
  align-items: center;
}

.final-quotation-template-container {
  width: auto;
  overflow-y: auto;
}

.quotation-items {
  border-top: 1.5px solid #f16f40;
  background-color: rgb(255, 255, 255);
}

.quotation-items p {
  color: #636363;
  outline: none;
  border: none;
  width: 100%;
  box-sizing: border-box;
  padding: 3.6px 6px;
}

.quotation-type {
  font-size: 12px;
  font-weight: 500;
  padding-bottom: 5px;
  position: relative;
  /* z-index: 998; */
}

.quotation-description {
  font-size: 10px;
  font-style: italic;
  color: #636363;
  outline: none;
  border: none;
  width: 100%;
  box-sizing: border-box;
  padding: 6px;
}

.quotation-subItem-container {
  position: relative;
}

.quotation-subItem-container td {
  background-color: #fff;
  color: #636363;
  border-right: 1px solid #f16f40;
  border-top: 1px solid #f16f40;
  padding: 5px 4px;
  text-align: center;
  font-size: 11px;
  font-weight: 500;
}

.quotation-subItem-font-container td{
  font-size: 10px;
}

.quotation-subItem {
  width: 26%;
  padding-left: 12px;
  padding-right: 4px;
}

.subtotal-fees {
  width: 150px;
  text-align: center;
  font-size: 11px;
}

.quotation-designer-percentage p {
  text-align: center;
  font-size: 11px;
  width: 50px;
}

.final-quotation-amount {
  border-top: 1px solid;
  border-right: none;
  width: 80px;
}

.final-quotation-designer-fee td {
  padding-top: 8px;
  padding-bottom: 8px;
  text-align: center;
  font-size: 10px;
  color: #636363;
}

.pdf-description-label{
  color: #fff;
  font-size: 11px;
}

.pdf-description-options{
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 10px 0;
  color: #fff;
  font-size: 11px;
}

.pdf-description-option{
display: flex;
justify-content: center;
align-items: center;
gap: 5px;
}

.pdf-description-input {
  cursor: pointer;
}

.generate-pdf-btn {
  margin: 0px 0px 0px 10px;
  top: 20px;
  position: fixed;
}

.generate-pdf-btn button {
  padding: 7px 15px;
  border-radius: 5px;
  cursor: pointer;
  background: linear-gradient(to right, #eb4819 0%, #f78f5f 49%, #ff8d1b 100%);
  font-weight: 500;
  font-size: 13px;
  color: #fff;
  border: none;

}

.generate-pdf-btn button:hover{
    transform: scale(1.02);
      box-shadow: 0px 2px 4px #2e2e2e;
}

/* Table Styles */
.user-description-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}


.user-description-table th,
.user-description-table td {
  padding:6px;
  text-align: left;
  /* border: 1px solid #ddd; */
}

.user-description-table th{
  padding:10px;
}

.user-description-table th {
  /* background-color: #f6a625;
  color: #fff; */
  font-size: 11px;
}

.user-description-table td {
  color: #636363;
  background-color: #fff;
  font-size: 10px;
}


.description-type-row{
  width: 225px;
}

.description-category-row{
  width: 130px;
}

.description-finish-row{
  width: 140px;
}
