.new-quotation-side-bar {
  width: 100%;
  border-radius: 10px;
  height: 95vh;
  overflow-y: auto;
  animation: slideInFromLeft 0.5s ease-out;
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.new-quotation-side-bar::-webkit-scrollbar {
  display: none;
}

.new-quotation-side-bar-ad1 {
  height: 80px;
  border-radius: 7px;
  background-color: #fff7ef;
}

.new-quotation-side-bar-ad2 {
  height: 160px;
  border-radius: 7px;
  background-color: #fff7ef;
  object-fit: cover;
}

.new-quotation-side-bar-summary {
  max-height: 65vh;
  border-radius: 7px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin: 12px 0px;
  overflow-y: auto;
}

.new-quotation-side-bar-summary::-webkit-scrollbar {
  display: none;
}

.summary-table {
  width: 100%;
  border: 1.5px solid #f16f40;
  border-radius: 15px 15px 0 0;
  border-collapse: collapse;
  border-top: none;
}

.summary-header {
  color: #f16f40;
  padding: 8px 12px;
  top: 0;
  background: #fff;
  z-index: 1;
  border-radius: 15px 15px 0 0;
  border: 1.5px solid #f16f40;
  text-align: center;
  font-weight: 600;
  border-bottom: none;
}

.summary-body {
  display: block;
}

.summary-body::-webkit-scrollbar {
  display: none;
}

.summary-row {
  display: flex;
  justify-content: space-between;
}

.summary-category,
.summary-amount,
.summary-total-label,
.summary-total-amount {
  flex: 1;
  border-top: 1px solid #f16f40;
  font-size: 11px;
  padding: 5px 12px;
  color: #636363;
}

.summary-amount,
.summary-total-amount {
  text-align: center;
}

.summary-category,
.summary-total-label {
  border-right: 1px solid #f16f40;
}

.summary-category {
  font-weight: 500;
}

.summary-total-label {
  font-weight: 600;
  font-style: italic;
}

.summary-total-amount {
  font-weight: 600;
}

.summary-buttons {
  margin-top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: auto;
  margin-left: auto;
  gap: 10px;
}

.summary-buttons p {
  padding: 5px 20px;
  color: rgb(7, 174, 7);
  border: 1px solid rgb(7, 174, 7);
  border-radius: 5px;
  background-color: rgb(234, 251, 234);
  font-weight: 600;
  font-size: 12px;
}

.summary-button {
  width: 80px;
  padding: 5px 0px;
  border-radius: 6px;
  border: 1.5px solid #f16f40;
  font-weight: 600;
  color: #fff;
  text-align: center;
  background: linear-gradient(to right, #eb4819 0%, #f78f5f 49%, #ff8d1b 100%);
  cursor: pointer;
}

.summary-button:hover {
  background: linear-gradient(to right, #eb4819 0%, #f78f5f 49%, #ff8d1b 100%);
  color: #fff;
}

/*step 2 */

.new-quotation-quote {
  width: 100%;
  max-height: 95vh;
  overflow-y: auto;
  animation: slideInFromLeft 0.5s ease-out;
}

.new-quotation-quote::-webkit-scrollbar {
  display: none;
}

.new-quotation-content {
  width: 100%;
  text-align: center;
  background-color: #fff;
  padding: 0px;
  border-radius: 9px;
}

.new-quotation-message {
  background-color: #fff7ef;
  padding: 20px 10px;
  box-shadow: 0px 3px 4px #d4d3d3;
  border-radius: 9px 9px 0 0;
}

.new-quotation-greeting {
  margin-top: 10px;
}

.new-quotation-greeting,
.new-quotation-notice {
  font-size: 12px;
  color: #636363;
  font-weight: 500;
  text-align: left;
}

.new-quotation-notice {
  font-size: 11.5px;
  margin-top: 12px;
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.new-quotation-quote-value {
  color: #fc710c;
  font-weight: 500;
  font-size: 13px;
}

.new-quotation-project-location {
  margin: 14px 0px;
  color: black;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
}

.new-quotation-discounted-value {
  margin-bottom: 8px;
  font-size: 18px;
  font-weight: 600;
  color: #fc710c;
}

.new-quotation-total-value {
  font-size: 10px;
  font-weight: 300;
  color: #636363;
  font-style: italic;
}

.new-quotation-total-value span {
  color: #fc710c;
}

.new-quotation-commission {
  color: #636363;
  font-size: 11px;
  margin: 12px 0px;
  line-height: 17px;
  font-weight: 500;
}

.new-quotation-commission span {
  color: #fc710c;
}

.new-quotation-client-title {
  font-weight: 600;
  margin: 15px 0px;
  font-size: 12px;
}

.new-quotation-client-benefits {
  width: 85%;
  margin-right: auto;
  margin-left: auto;
  text-align-last: left;
}

.new-quotation-benefits-list li {
  color: #636363;
  font-size: 10.5px;
  margin-bottom: 7px;
}

.new-quotation-switch-title {
  font-weight: 500;
  color: #636363;
  font-size: 12px;
}

.new-quotation-switch-button {
  background: linear-gradient(to right, #eb4819 0%, #f78f5f 49%, #ff8d1b 100%);
  padding: 7px 10px;
  border-radius: 7px;
  font-weight: 600;
  color: #fff;
  margin-top: 10px;
  border: none;
  cursor: pointer;
  font-size: 12px;
}

.new-quotation-request-Successfull-btn{
  color: rgb(7, 174, 7);
  border: 1px solid rgb(7, 174, 7);
  background: rgb(234, 251, 234);
}

.new-quotation-contact-info {
  padding: 20px 0px;
  font-size: 10px;
  color: #5a5656;
  width: 70%;
  margin-right: auto;
  margin-left: auto;
  line-height: 14px;
}

.new-quotation-quotation-share {
  background-color: #fff;
  border-radius: 9px;
  width: 100%;
  margin-top: 12px;
  padding: 15px 10px;
}

.back-quotation-section {
  margin-top: 0;
  margin-bottom: 12px;
}

.new-quotation-share-options {
  display: flex;
  justify-content: start;
  align-items: center;
}

.new-quotation-share-title {
  font-size: 11px;
  font-weight: 500;
  color: #454545;
  margin-right: 14px;
}

.new-quotation-mail-logo {
  width: 25px;
  height: 20px;
  object-fit: fill;
  margin-right: 5px;
}

.new-quotation-whatsapp-logo {
  width: 35px;
  height: 25px;
  object-fit: fill;
}

.new-quotation-action-buttons {
  width: 100%;
  flex-direction: column;
  gap: 10px;
  margin-top: 0;
  margin-bottom: 0;
}

.new-quotation-button {
  width: 100%;
  padding: 5px 12px;
}

.new-quotation-back-button {
  width: 100%;
  padding: 5px 12px;
  border: 1.5px solid #f16f40;
  color: #f16f40;
  background: #fff;
  margin-top: 8px;
}

.base-execution-price {
  font-weight: 500;
  color: #5a5656;
  font-size: 10px;
  text-align: center;
  line-height: 15px;
}

.base-execution-price-value {
  font-size: 11.5px;
  font-weight: 600;
}

.executing-by-gst-exclusion {
  font-size: 9px;
  font-weight: 400;
}

.interioverse-quotation-value {
  font-size: 12px;
  color: #5a5656;
  font-weight: 500;
  text-align: start;
  margin: 10px 0;
}

.interioverse-quotation-gst-value {
  margin: 5px;
  text-align: center;
}

.interioverse-quotation-value span:first-child {
  font-size: 14px;
  color: #f18506;
  font-weight: 600;
}

.my-discount-section {
  margin: 15px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.my-discount-info {
  font-size: 9px;
  color: #000000;
  width: 100%;
}

.range-bar-section {
  width: 100%;
}

.range-bar {
  position: relative;
  height: 10px;
  width: 100%;
}

.custom-range {
  position: relative;
  width: 100%;
  height: 100%;
  appearance: none;
  background-color: transparent;
  outline: none;
}

.range-bar-fill {
  position: absolute;
  top: 100%;
  left: 0;
  transform: translateY(-50%);
  height: 4px;
  width: 0;
  background-color: #ff8d1b;
}

.range-bar-value {
  text-align: center;
  font-size: 11px;
  font-weight: 500;
  margin-top: 10px;
}

.max-range p {
  font-size: 12px;
  color: black;
}

.commission-section{
  width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: baseline;
    gap: 10px;
}

.commission-input,.discount-input,.gst-input{
  display: flex;
  gap: 10px;
  align-items: center;
  width: 100%;
}

.commission-label,.discount-label,.gst-label{
font-size: 11px;
font-weight: 500;
color: #5a5656;
width: 60%;
text-align: left;
}

.commission-field,.discount-field{
  width: 35%;
  background-color: inherit;
  border: none;
  outline: none;
  font-size: 12px;
}

.gst-field{
cursor: pointer;
}

/* Parent container for the custom checkbox */
.custom-gst-input-checkbox {
  position: relative;
  display: inline-flex;
  align-items: center;
  width: 12px; /* Updated to 12px */
  height: 12px; /* Updated to 12px */
  cursor: pointer;
}

/* Hide the default checkbox */
.custom-gst-input-checkbox input[type="checkbox"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Style the custom checkmark */
.gst-input-checkmark {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 12px; /* Updated to 12px */
  width: 12px; /* Updated to 12px */
  background-color: #fff;
  border: 1px solid #ff8d1b;
  border-radius: 2px;
  box-sizing: border-box;
  transition: all 0.2s ease-in-out;
}

/* Add styles for the default state */
.gst-input-checkmark {
  background-color: #fff;
  border-color: #f18506;
}

/* Style the React Icon */
.check-icon {
  color: #fff;
  font-size: 12px; /* Slightly adjusted for 12px size */
  background-color: #f18506;
  width: 12px;
}

.my-commission-details {
  font-size: 10px;
  color: #000000;
  margin-top: 5px;
}


.interioverse-commit {
  font-weight: 500;
  color: #454545;
  font-size: 11px;
  line-height: 18px;
  margin: 15px 0px;
}

.download-sentence {
  margin: 15px 0;
}

.switch-to-older {
  font-size: 11px;
  color: black;
  font-weight: 500;
}

.revert-back {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.revert-back-button {
  width: auto;
  padding: 5px 12px;
}

.quotation-side-bar-container {
  width: 25%;
}
