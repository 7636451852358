.profile-card-section {
  width: 96vw;
  margin: 0 -4vw;
  display: flex;
  justify-content: flex-end;
}

.profile-card-article {
  width: 240px;
  margin: 0px auto;
  background-image: linear-gradient(to left, #f8f5ea, #f8eee6);
  padding: 10px;
  border-radius: 20px;
  color: #636363;
  position: absolute;
  z-index: 999;
}

.profile-card-image {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin: 10px 0px 5px 0px;
}

.profile-card-image img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  object-fit: cover;
  z-index: -1;
  user-select: none;
}

.role-switch-icon {
  width: 28.5px;
  height: 26.5px;
  background: #fff;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #f9912d;
  font-size: 14px;
  font-weight: 500;
  box-sizing: border-box;
  padding: 3px;
  z-index: 1;
  margin-left: -22px;
  margin-bottom: 5px;
  cursor: pointer;
  border: 2px solid #f9912d;
}

.active-role-switch-icon{
  background: linear-gradient(to right, #eb4819 0%, #f78f5f 49%, #ff8d1b 100%);
  color: #fff;
}

.profile-card-name {
  text-align: center;
  font-weight: 500;
  font-size: 18px;
  color: #636363;
  margin-bottom: 2px;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
  position: relative;
}



.profile-card-email {
  text-align: center;
  font-size: 11px;
  color: #636363;
  margin-bottom: 10px;
}

.profile-card-menu {
  text-align: center;
  width: 100%;
  margin: auto;
}

.profile-card-menu-item h2 {
  font-size: 12px;
  font-weight: 600;
  margin: 8px;
  padding: 6px 10px;
  border: 1.5px solid #f9912d;
  background-color: #fbecdd;
  cursor: pointer;
}

/* Container styling */
.role-switcher-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.role-switcher-wrapper {
  position: relative;
  display: flex;
  width: 90%;
  height: 35px;
  /* background-color: #e0e0e0; */
  background-color: #fae4d0;
  border-radius: 50px;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.2);
}

/* Slider */
.role-switcher-slider {
  position: absolute;
  top: 5px;
  left: 5px;
  width: 49%;
  height: 25px;
  background: linear-gradient(to right, #eb4819 0%, #f78f5f 49%, #ff8d1b 100%);
  border-radius: 50px;
  transition: transform 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.role-switcher-slider.role-switcher-slide-right {
  transform: translateX(90px);
}

/* Button styles */
.role-switcher-btn {
  flex: 1;
  border: none;
  background: transparent;
  font-size: 11px;
  font-weight: bold;
  color: #888;
  cursor: pointer;
  z-index: 1;
  transition: color 0.3s ease;
}

.organisation-switch-btn {
  margin-left: -15px;
}

.role-switcher-btn.role-switcher-active {
  color: #fff;
  cursor: default;
}

@media screen and (max-width: 2570px) {
  .profile-card-section {
    margin: 0 -3.5vw;
  }

  .profile-card-article {
    width: 320px;
  }

  .profile-card-image img {
    width: 120px;
    height: 120px;
  }

  .role-switch-icon {
    width: 45px;
    height: 42px;
    margin-left: -35px;
    margin-bottom: 5px;
  }

  .profile-card-name {
    font-size: 24px;
  }

  .profile-card-email {
    font-size: 17px;
  }

  .profile-card-menu-item h2 {
    font-size: 18px;
    margin: 10px;
    padding: 8px 10px;
  }
}

@media screen and (max-width: 2000px) {
  .profile-card-section {
    margin: 0 -3.5vw;
  }

  .profile-card-article {
    width: 300px;
  }

  .profile-card-image img {
    width: 90px;
    height: 90px;
  }

  .role-switch-icon {
    width: 35px;
    height: 33px;
    margin-left: -30px;
    margin-bottom: 5px;
  }

  .profile-card-name {
    font-size: 20px;
  }

  .profile-card-email {
    font-size: 15px;
  }

  .profile-card-menu-item h2 {
    font-size: 15px;
    margin: 10px;
    padding: 6px 10px;
  }
}

@media screen and (max-width: 1400px) {
  .profile-card-article {
    width: 240px;
  }

  .profile-card-image img {
    width: 70px;
    height: 70px;
  }

  .role-switch-icon {
    width: 28.5px;
    height: 26.5px;
    margin-left: -22px;
    margin-bottom: 5px;
  }

  .profile-card-name {
    font-weight: 500;
    font-size: 18px;
  }

  .profile-card-email {
    font-size: 11px;
    margin-bottom: 10px;
  }

  .profile-card-menu-item h2 {
    font-size: 12px;
  }
}

@media screen and (max-width: 1100px) {
  .profile-card-article {
    width: 240px;
  }

  .profile-card-image img {
    width: 60px;
    height: 60px;
  }

  .role-switch-icon {
    width: 26.5px;
    height: 24.5px;
    margin-left: -20px;
    margin-bottom: 5px;
  }

  .profile-card-name {
    font-weight: 500;
    font-size: 17px;
  }

  .profile-card-email {
    font-size: 10px;
    margin-bottom: 10px;
  }

  .profile-card-menu-item h2 {
    font-size: 11px;
  }
}

@media screen and (max-width: 950px) {
  .profile-card-article {
    width: 250px;
  }

  .profile-card-image img {
    width: 80px;
    height: 80px;
  }

  .role-switch-icon {
    width: 26.5px;
    height: 24.5px;
    margin-left: -20px;
    margin-bottom: 5px;
  }

  .profile-card-name {
    font-size: 18.5px;
  }

  .profile-card-email {
    font-size: 13px;
  }

  .profile-card-menu-item h2 {
    font-size: 14px;
  }
}

@media screen and (max-width: 850px) {
  .profile-card-image img {
    width: 70px;
    height: 70px;
  }

  .role-switch-icon {
    width: 26.5px;
    height: 24.5px;
    margin-left: -20px;
    margin-bottom: 5px;
  }

  .profile-card-name {
    font-size: 17px;
  }

  .profile-card-email {
    font-size: 12px;
  }

  .profile-card-menu-item h2 {
    font-size: 13.5px;
  }
}

@media screen and (max-width: 600px) {
  .profile-card-section {
    padding: 30px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    max-width: 90%;
    max-height: 90%;
    overflow: auto;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 999;
  }

  .profile-card-close-icon {
    margin-top: -27px;
  }

  .profile-card-article {
    width: 300px;
    padding: 20px;
  }

  .profile-card-image img {
    width: 80px;
    height: 80px;
  }

  .role-switch-icon {
    width: 26.5px;
    height: 24.5px;
    margin-left: -20px;
    margin-bottom: 5px;
  }

  .profile-card-name {
    font-size: 18.5px;
  }

  .profile-card-email {
    font-size: 13px;
  }

  .profile-card-menu-item h2 {
    font-size: 14px;
  }
}

@media screen and (max-width: 450px) {
  .profile-card-close-icon {
    margin-top: -27px;
  }

  .profile-card-article {
    width: 280px;
    padding: 20px;
  }

  .role-switch-icon {
    width: 26.5px;
    height: 24.5px;
    margin-left: -25px;
    margin-bottom: 5px;
  }

  .profile-card-name {
    font-size: 18.5px;
  }

  .profile-card-email {
    font-size: 12px;
  }

  .profile-card-menu-item h2 {
    font-size: 13px;
  }
}
