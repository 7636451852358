.user-slot-heading {
  color: #5a5656;
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 15px;
}

.user-slot-item {
  margin-bottom: 12px;
  display: flex;
  justify-content: start;
  align-items: start;
  gap: 40px;
  font-size: 11px;
  color: #5a5656;
  font-weight: 500;
}

.user-slot-details {
  width: 80px;
  display: flex;
  gap: 10px;
}

.custom-input-checkbox {
  position: relative;
  display: inline-flex;
  align-items: center;
  width: 12px; /* Updated to 12px */
  height: 12px; /* Updated to 12px */
  cursor: pointer;
}

/* Hide the default checkbox */
.custom-input-checkbox input[type="checkbox"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Style the custom checkmark */
.input-checkmark {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 12px; /* Updated to 12px */
  width: 12px; /* Updated to 12px */
  background-color: #fff;
  border: 1px solid #ff8d1b;
  border-radius: 2px;
  box-sizing: border-box;
  transition: all 0.2s ease-in-out;
}

/* Add styles for the default state */
.input-checkmark {
  background-color: #fff;
  border-color: #f18506;
}

/* Style the React Icon */
.check-icon {
  color: #fff;
  font-size: 12px; /* Slightly adjusted for 12px size */
  background-color: #f18506;
  width: 12px;
}

.organization-payment-summary-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px;
  margin-bottom: 5px;
}

.organization-proceed-button {
  margin-top: 40px;
}

@media screen and (max-width: 2570px) {
  .user-slot-heading {
    font-size: 20px;
  }

  .user-slot-item {
    font-size: 18px;
  }

  .custom-input-checkbox {
    width: 18px;
    height: 18px;
  }

  /* Style the custom checkmark */
  .input-checkmark {
    height: 18px;
    width: 18px;
  }

  /* Style the React Icon */
  .check-icon {
    font-size: 18px;
    width: 18px;
  }

  .user-slot-details {
    width: 100px;
  }

  .organization-payment-background-img {
    height: 410px;
  }
}

@media screen and (max-width: 2000px) {
  .user-slot-heading {
    font-size: 16px;
  }

  .user-slot-item {
    font-size: 13px;
  }

  .custom-input-checkbox {
    width: 15px;
    height: 15px;
  }

  /* Style the custom checkmark */
  .input-checkmark {
    height: 15px;
    width: 15px;
  }

  /* Style the React Icon */
  .check-icon {
    font-size: 15px;
    width: 15px;
  }

  .user-slot-details {
    width: 80px;
  }

  .organization-payment-background-img {
    height: 350px;
  }
}

@media screen and (max-width: 1400px) {
  .user-slot-heading {
    font-size: 14px;
  }

  .user-slot-item {
    font-size: 11px;
  }

  .custom-input-checkbox {
    width: 12px;
    height: 12px;
  }

  /* Style the custom checkmark */
  .input-checkmark {
    height: 12px;
    width: 12px;
  }

  /* Style the React Icon */
  .check-icon {
    font-size: 12px;
    width: 12px;
  }


  .organization-payment-background-img {
    height: 290px;
  }
}

@media screen and (max-width: 950px) {
  .user-slot-heading {
    font-size: 16px;
  }

  .user-slot-item {
    font-size: 13px;
  }

  .custom-input-checkbox {
    width: 14px;
    height: 14px;
  }

  /* Style the custom checkmark */
  .input-checkmark {
    height: 14px;
    width: 14px;
  }

  /* Style the React Icon */
  .check-icon {
    font-size: 14px;
    width: 14px;
  }


  .organization-payment-background-img {
    height: 315px;
  }
}

@media screen and (max-width: 600px) {
  .user-slot-heading {
    font-size: 14px;
    margin-top: 15px;
  }

  .user-slot-item {
    font-size: 11px;
  }

  .custom-input-checkbox,.input-checkmark,.check-icon {
    width: 12px;
    height: 12px;
  }



  .organization-payment-background-img {
    height: 315px;
  }
}
