.loading-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 90vh;
    background-color: #fbfaf9; /* Light background for contrast */
    margin-bottom: -20px;
}
  
.loading-spinner {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    margin-bottom: 10px;
    background-color: #ffffff;
    border-radius: 50%;
    animation: bounce 1s infinite;
    box-shadow: 0px 3px 6px #ccc;
}
  
.loading-logo {
    width: 35px; /* Adjust size as needed */
    height: auto;
   
}

@keyframes bounce {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-20px);
    }
}


  

  
.loading-circle {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 3px solid transparent;
    border-top: 3px solid #facba0;
    border-right: 3px solid #facba0;
    border-bottom: 3px solid #facba0;
    border-left: 3px solid #f9912d;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

  
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
  
.loading-text {
    font-size: 17px;
    color: #333; 
    font-weight: 600;
}
