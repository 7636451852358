.team-project-details-container {
  flex-direction: column;
}

.profile-project-details-section {
  display: flex;
  gap: 2%;
  align-items: start;
  justify-content: start;
  flex-wrap: wrap;
  width: 100%;
}

.member-project-details {
    width: 32%;
    margin-bottom: 2%;
    border: 1px solid #e4e3e3;
    box-shadow: 0 3px 6px #ccc;
    padding: 5px;
    background: linear-gradient(to bottom, #ffeddd, #fff);
  }


.profile-project-assigned-section {
  display: flex;
  gap: 5px;
  justify-content: space-between;
  margin: 5px 0;
}

.project-created-date {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 8px;
  color: #6e665e;
  flex-direction: column;
}

.profile-project-assign-button {
  border: 1px solid #f9912d;
  background-color: #fff;
  font-size: 10px;
  padding: 2px 6px;
  color: #f9912d;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 5px;
}

.profile-project-details{
    display: flex;
    justify-content: space-between;
}

.profile-project-info{
    margin: 10px 0;
}

.profile-project-location-id{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 5px;
}

.profile-project-id,.profile-project-location {
    font-size: 9px;
    color: #6e665e;
  }


.project-assigned-user-section {
  display: flex;
  gap: 10px;
}

.profile-project-budget {
    color: #f9912d;
    font-size: 12px;
    margin-top: 5px;
  }

  .interioverse-project-details{
        border-top: 1px solid #cec9c9;
        padding-top: 5px;
  }



.profile-project-title {
  color: #6e665e;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 3px;
}



.profile-project-summary {
  width: 100%;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.profile-project-meta {
  margin-top: 10px;
}

.project-aassigned-member-img {
  width: 25px;
  height: 25px;
  object-fit: fill;
}

.project-aassigned-to {
  font-size: 10px;
  color: #6e665e;
}

.project-aassigned-to p {
  font-weight: 600;
}

.profile-project-timestamps {
  margin-top: 10px;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
}

.profile-project-date {
  margin-bottom: 5px;
  width: 55%;
}

.profile-project-assign-button:hover {
  background: linear-gradient(to right, #eb4819 0%, #f78f5f 49%, #ff8d1b 100%);
  color: #fff;
}

.profile-project-not-found{
  width: 100%;
    height: 100%;
    font-size: 11px;
    color: #636363;
    font-weight: 600;
}

@media screen and (max-width: 2570px) {

  .member-project-details {
    width: 49%;
}

  
  .project-created-date {
    font-size: 14px;
  }
  
  .profile-project-assign-button {
    font-size: 16px;
    padding: 4px 6px;
  }
  
  
  .profile-project-id,.profile-project-location {
      font-size: 15px;
    }
  
  
  .profile-project-budget {
      font-size: 20px;
      margin-top: 5px;
    }

  .profile-project-title {
    font-size: 20px;
    margin-bottom: 3px;
  }
  
  .project-aassigned-member-img {
    width: 40px;
    height: 40px;
  }
  
  .project-aassigned-to {
    font-size: 18px;
  }
  

  .profile-project-not-found{
      font-size: 19px;
  }
}

@media screen and (max-width: 2000px) {

  .member-project-details {
    width: 32%;
}

  
  .project-created-date {
    font-size: 9px;
  }
  
  .profile-project-assign-button {
    font-size: 11px;
    padding: 2px 6px;
  }
  
  
  .profile-project-id,.profile-project-location {
      font-size: 9px;
    }
  
  
  .profile-project-budget {
      font-size: 12px;
    }

  .profile-project-title {
    font-size: 12px;

  }
  
  .project-aassigned-member-img {
    width: 25px;
    height: 25px;
  }
  
  .project-aassigned-to {
    font-size: 11px;
  }
  

  .profile-project-not-found{
      font-size: 11px;
  }
}

@media screen and (max-width: 1400px) {

  .member-project-details {
    width: 32%;
}

  
  .project-created-date {
    font-size: 8px;
  }
  
  .profile-project-assign-button {
    font-size: 10px;
    padding: 2px 6px;
  }
  
  
  .profile-project-id,.profile-project-location {
      font-size: 9px;
    }
  
  
  .profile-project-budget {
      font-size: 12px;
    }

  .profile-project-title {
    font-size: 12px;

  }
  
  .project-aassigned-member-img {
    width: 25px;
    height: 25px;
  }
  
  .project-aassigned-to {
    font-size: 10px;
  }
  

  .profile-project-not-found{
      font-size: 11px;
  }
}

@media screen and (max-width: 950px) {

  .member-project-details {
    width: 49%;
}

  
  .project-created-date {
    font-size: 12px;
  }
  
  .profile-project-assign-button {
    font-size: 14px;
    padding: 4px 6px;
  }
  
  
  .profile-project-id,.profile-project-location {
      font-size: 13px;
    }
  
  
  .profile-project-budget {
      font-size: 14px;
    }

  .profile-project-title {
    font-size: 16px;

  }
  
  .project-aassigned-member-img {
    width: 35px;
    height: 35px;
  }
  
  .project-aassigned-to {
    font-size: 17px;
  }
  

  .profile-project-not-found{
      font-size: 13px;
  }
}

@media screen and (max-width: 850px) {

  .project-created-date {
    font-size: 11px;
  }
  
  .profile-project-assign-button {
    font-size: 12px;
    padding: 2px 6px;
  }
  
  
  .profile-project-id,.profile-project-location {
      font-size: 12px;
    }
  
  
  .profile-project-budget {
      font-size: 12px;
    }

  .profile-project-title {
    font-size: 14px;

  }
  
  .project-aassigned-member-img {
    width: 25px;
    height: 25px;
  }
  
  .project-aassigned-to {
    font-size: 14px;
  }
  

  .profile-project-not-found{
      font-size: 12px;
  }
}

@media screen and (max-width: 600px) {

  .project-created-date {
    font-size: 9px;
  }
  
  .profile-project-assign-button {
    font-size: 10px;
    padding: 2px 6px;
  }
  
  
  .profile-project-id,.profile-project-location {
      font-size: 10px;
    }
  
  
  .profile-project-budget {
      font-size: 12px;
    }

  .profile-project-title {
    font-size: 12px;

  }
  
  .project-aassigned-member-img {
    width: 25px;
    height: 25px;
  }
  
  .project-aassigned-to {
    font-size: 11px;
  }
  

  .profile-project-not-found{
      font-size: 11px;
  }
}

@media screen and (max-width: 450px) {

  .member-project-details {
    width: 80%;
    margin: 5px auto;
}

  .project-created-date {
    font-size: 10px;
  }
  
  .profile-project-assign-button {
    font-size: 12px;
    padding: 2px 6px;
  }
  
  
  .profile-project-id,.profile-project-location {
      font-size: 12px;
    }
  
  
  .profile-project-budget {
      font-size: 13px;
    }

  .profile-project-title {
    font-size: 13px;

  }
  
  .project-aassigned-to {
    font-size: 13px;
  }
  

  .profile-project-not-found{
      font-size: 12px;
  }
}

@media screen and (max-width: 400px) {

  .member-project-details {
    width: 95%;
    margin: 5px auto;
}

  .project-created-date {
    font-size: 9px;
  }
  
  .profile-project-assign-button {
    font-size: 11px;
    padding: 2px 6px;
  }
  
  
  .profile-project-id,.profile-project-location {
      font-size: 11px;
    }
  
  
  .profile-project-budget {
      font-size: 12px;
    }

  .profile-project-title {
    font-size: 12px;

  }
  
  .project-aassigned-to {
    font-size: 12px;
  }
  

  .profile-project-not-found{
      font-size: 11px;
  }
}