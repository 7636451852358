.unity-button-container {
  width: 100%;
  text-align: center;
  margin-top: 35px;
}

.unity-explore-design-button {
  padding: 9px 35px;
  background: linear-gradient(to right, #eb4819 0%, #f78f5f 49%, #ff8d1b 100%);
  border: none;
  outline: none;
  color: #fff;
  font-weight: 600;
  border-radius: 6px;
  cursor: pointer;
  font-size: 12px;
}

.unity-button-group {
  margin-top: 5px;
}

.unity-designer-street-button {
  display: block;
  width: 250px;
  padding: 6px 20px;
  background: linear-gradient(to right, #eb4819 0%, #f78f5f 49%, #ff8d1b 100%);
  border: none;
  outline: none;
  color: #fff;
  border-radius: 6px;
  cursor: pointer;
}

.unity-designer-street-button span {
  display: block;
}

.unity-designer-street-button span:first-child {
  font-weight: 600;
  font-size: 12px;
}

.unity-designer-street-button span:last-child {
  font-size: 11px;
  font-weight: 400;
}

.unity-designing-tool-button {
  margin-top: 15px;
  padding: 6px 20px;
  width: 250px;
  background-color: #fff;
  border: 1px solid #f9912d;
  border-radius: 6px;
  color: #f9912d;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
}

.ai-prompt-section {
  display: flex;
  width: 70%;
  margin: 25px auto;
  gap: 10px;
  border: 1.5px solid #f9912d;
  padding: 15px;
  border-radius: 6px;
  box-shadow: 0px 2px 4px #bab7b7;
}

.ai-prompt-content {
  width: 90%;
}

.ai-prompt-title {
  margin-bottom: 5px;
  font-size: 17px;
  color: #f9912d;
}

.ai-prompt-textarea {
  width: 100%;
  padding: 6px 10px;
  outline: none;
  border: 1.5px dashed #f9912d;
  resize: none;
}

.ai-prompt-textarea::-webkit-scrollbar {
  display: none;
}

.design-section {
  width: 90%;
  margin: 40px auto;
  column-count: 6;
  column-gap: 30px;
}

@keyframes unityfadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px); /* Slight downward motion */
  }
  100% {
    opacity: 1;
    transform: translateY(0); /* End in the original position */
  }
}

.design-figure {
  width: 100%;
  break-inside: avoid;
  margin-bottom: 10px;
  opacity: 0; /* Start invisible */
  animation: unityfadeIn 0.5s ease-in-out forwards;
}

.design-figure:hover {
  transform: scale(1.1);
  cursor: pointer;
}

.design-image {
  width: 100%;
  max-height: 350px;
}

.design-caption {
  margin: 8px 0px;
}

.design-title {
  font-size: 13px;
  color: #5a5656;
  margin-bottom: 5px;
  text-align: center;
}

.designer-info {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.designer-profile-img {
  width: 30px;
  object-fit: fill;
}

.designer-name {
  font-size: 12px;
  color: #5a5656;
}

.screen-furniture-nav {
  width: 90%;
  margin: 0 auto;
  display: flex;
  gap: 5px;
}

.design-model-name {
  border: 1px solid #f9912d;
  border-radius: 6px;
  background-color: #fff;
  font-weight: 500;
  color: #f9912d;
  padding: 6px 25px;
  cursor: pointer;
  font-size: 11px;
  font-weight: 500;
}

.selected-design-items,
.selected-model-items,
.screen-furniture-nav p:hover {
  background: linear-gradient(to right, #eb4819 0%, #f78f5f 49%, #ff8d1b 100%);
  color: #fff;
}

.furniture-categories {
  width: 90%;
  margin: 10px auto;
  margin-top: 25px;
  display: flex;
  gap: 5px;
  justify-content: space-between;
  animation: unityfadeIn 0.5s ease-in-out forwards;
}

.furniture-categories p {
  border: 1px solid #f9912d;
  border-radius: 6px;
  background-color: #fff;
  font-weight: 500;
  color: #f9912d;
  padding: 6px 10px;
  cursor: pointer;
  font-size: 11px;
}

.selected-screen-furniture,
.furniture-categories p:hover {
  background: linear-gradient(to right, #eb4819 0%, #f78f5f 49%, #ff8d1b 100%);
  color: #fff;
}

.search-design-furniture {
  width: 45%;
  margin: 10px auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-design-furniture-input {
  width: 100%;
  padding: 6px 10px;
  padding-left: 20px;
  color: #f9912d;
  font-size: 11px;
  border-radius: 5px;
  border: 1.5px solid #f9912d;
  outline: none;
}

.search-design-furniture-icon{
  margin-right: -20px;
  z-index: 1;
  color: #f9912d;
  font-size: 17px;
}

.search-design-furniture-input::placeholder {
  color: #f9912d;
}

.design-model-container{
  margin-top: 50px;
}
