

.interioverse-rate-section {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  z-index: 999;
  padding: 30px;
}

.interioverse-rate-content {
  background-color: rgba(249, 248, 248, 0.5); /* light gray with 80% opacity */
  border-radius: 15px;
  backdrop-filter: blur(10px); /* glass effect */
  -webkit-backdrop-filter: blur(10px); /* for Safari */
  width: 98%;
  height: 98%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.interioverse-rate-container {
  padding: 30px;
  border-radius: 15px;
  background-color: #fff;
  width: 450px;
  max-height: 450px;
}

.base-execution-price {
  font-weight: 500;
  color: #5a5656;
  font-size: 12.5px;
  text-align: center;
}

.base-execution-price-value {
  font-size: 13px;
  font-weight: 600;
}

.executing-by-gst-exclusion {
  font-size: 10px;
  font-weight: 400;
}

.interioverse-quotation-value {
  font-size: 13px;
  color: #5a5656;
  font-weight: 500;
  text-align: center;
  margin: 25px 0;
}

.interioverse-quotation-value span:first-child {
  font-size: 16px;
  color: #f18506;
  font-weight: 600;
}
.my-discount-section {
  margin: 30px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.my-discount-info {
  font-size: 9px;
  color: #000000;
  width: 150px;
}

.range-bar-section {
  width: 250px;
}

.range-bar-value {
  text-align: center;
  font-size: 11px;
  font-weight: 500;
}

.range-bar {
  position: relative;
  height: 10px; /* Adjust height as needed */
  width: 100%;
}

.custom-range {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  outline: none;
}

.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 2px; /* Adjust track height as needed */
  cursor: pointer;
  background: #aaa;
}

.custom-range::-webkit-slider-thumb {
  position: relative;
  width: 14px; /* Adjust thumb size as needed */
  height: 14px; /* Adjust thumb size as needed */
  background: #ff8d1b;
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 50%;
  border: 1px solid #ff8d1b;
  margin-top: -6px; /* Adjust vertical alignment */
  z-index: 2;
}

.custom-range::-moz-range-track {
  width: 100%;
  height: 2px; /* Adjust track height as needed */
  cursor: pointer;
  background: #aaa;
}

.custom-range::-moz-range-thumb {
  width: 10px; /* Adjust thumb size as needed */
  height: 10px; /* Adjust thumb size as needed */
  background: #ff8d1b;
  cursor: pointer;
  border-radius: 50%;
  border: none;
}

.range-bar-fill {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  height: 4px; /* Adjust fill height as needed */
  width: 0; /* Initial width is 0, will be updated dynamically */
  background-color: #ff8d1b;
}

.max-range {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
}

.max-range p {
  font-size: 12px;
  color: black;
}

.my-commission-section {
  margin-top: 30px;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0px 3px 6px #ccc;
  height: 110px;
}

.my-commission-info {
  font-size: 14px;
  color: #5a5656;
  font-weight: 500;
}

.my-commission-value {
  font-weight: 600;
  color: #ff8d1b;
}

.my-commission-details {
  font-size: 10px;
  color: #000000;
  margin-top: 10px;
}

.view-new-quotation-button {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.view-new-quotation-button button {
  font-weight: 500;
  color: #fff;
  background: linear-gradient(to right, #eb4819 0%, #f78f5f 49%, #ff8d1b 100%);
  padding: 8px 15px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
}

.block-to-type {
  background: transparent;
  width: 210mm;
  height: 297mm;
  position: absolute;
  margin-top: 0;
  z-index: 999;
}

.subtotal-container {
  background-color: #fff;
}

.unedit-subtotal {
  padding: 6px;
  font-size: 12px;
}

.version-container {
  display: flex;
  align-items: center;
  gap: 2px;
  flex-wrap: wrap;
  margin-top: -20px;
  margin-bottom: 15px;
  box-shadow: 0 3px 6px #ccc;
  padding: 10px;
  border-radius: 0 0 15px 15px;
  animation: dropdown 0.5s ease-out;
}

@keyframes fadeScaleDropdown {
  0% {
    opacity: 0;
    transform: scale(0.9);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}


.version-item {
  display: flex;
  align-items: center;
  padding: 2px 10px;
  background-color: #ffffff;
  border-radius: 10px 10px 0 0;
  border: 1px solid #ddd;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.selected-version-item {
  background-color: #28a745;
  color: #fff;
}

.version-item:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.version-item span {
  margin-right: 10px;
  font-size: 11px;
  font-weight: 500;
  cursor: pointer;
  transition: color 0.3s ease;
}

.version-input {
  padding: 2px 10px;
  font-size: 11px;
  border-radius: 5px;
  border: 1px solid #ccc;
  outline: none;
  transition: border 0.3s ease;
}

.version-input:focus {
  border-color: #f18506;
}

.version-icon {
  cursor: pointer;
  font-size: 16px;
  transition: color 0.3s ease, transform 0.3s ease;
}

.version-remove-icon:hover {
  color: #c82333;
  transform: scale(1.1);
}

.version-add-icon {
  color: #28a745;
}

.version-add-icon:hover {
  color: #218838;
  transform: scale(1.1);
}

.version-delete-popup {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  z-index: 999;
  opacity: 0;
  animation: fadeIn 0.5s forwards;
}

.version-delete-container {
  width: auto;
  margin: 20px auto 20px auto;
  background-color: rgb(117 115 115 / 50%);
  padding: 20px;
  border-radius: 20px;
  position: relative;
}

.version-delete-content {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 20px;
  max-width: 100%;
  margin: 0 auto;
  font-family: "Arial", sans-serif;
}

.version-delete-label {
  font-size: 14px;
  color: #333;
  margin-bottom: 20px;
  font-weight: 500;
  text-align: center;
}

.version-button-group {
  display: flex;
  justify-content: center;
  gap: 15px;
}

.version-delete-button,
.version-cancel-button {
  padding: 6px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.version-delete-button {
  background: linear-gradient(to right, #eb4819 0%, #f78f5f 49%, #ff8d1b 100%);
  color: #fff;
}

.version-delete-button:hover {
  background: linear-gradient(to right, #eb4819 0%, #f78f5f 49%, #ff8d1b 100%);
  /* transform: translateY(-2px); */
}

.version-cancel-button {
  background-color: #d3d3d3;
  color: #333;
}

.version-cancel-button:hover {
  background-color: #bcbcbc;
  /* transform: translateY(-2px); */
}

.version-input-container {
  width: 100%;
  margin-bottom: 15px;
  position: relative;
}

.version-input-label {
  font-size: 11px;
  margin-bottom: 5px;
  color: #636363;
  font-weight: 500;
  text-align: start;
}

.version-input-field {
  padding: 5px;
  outline: none;
  border: 1.5px solid #636363;
  border-radius: 5px;
  width: 100%;
  font-size: 12px;
}

.version-list-container {
  margin-top: 20px;
  margin-bottom: 15px;
}

.version-list-title {
  font-size: 0.7rem;
  color: #636363;
  margin-bottom: 10px;
  font-weight: 500;
}

.version-list-items {
  display: grid;
  grid-template-columns: 1fr; 
  gap: 5px;
}

.version-list-item {
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  background-color: #f9f9f9;
  transition: background-color 0.3s ease, transform 0.3s ease;
  font-size: 11px;
  font-weight: 500;
  color: #636363;
}

.version-list-item:hover {
  background: linear-gradient(to right, #eb4819 0%, #f78f5f 49%, #ff8d1b 100%);
  color: #fff;
  transform: scale(1.05);
}

.version-list-item-selected,
.version-list-item-selected:hover {
  background: linear-gradient(to right, #eb4819 0%, #f78f5f 49%, #ff8d1b 100%);
  color: #fff;
}

.new-quotation-warning{
  position: absolute;
  z-index: 998;
  margin-bottom: 5px;
  top: 0;
  left: 100%;
}





