.service-project-content {
  padding-bottom: 0;
}

.service-heading {
  font-weight: 500;
  margin-bottom: 3px;
  font-size: 20px;
  color: #636363;
}

.service-cities-list {
  font-size: 10px;
  font-weight: 500;
  color: #636363;
}

.service-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.service-map {
  width: 40%;
  padding: 0px 10px;
}

.service-map-image {
  object-fit: contain;
  width: 100%;
}

.service-details {
  width: 60%;
  padding: 10px;
  text-align: center;
  padding-bottom: 0;
  background: linear-gradient(to bottom, #ffeddd, #fff);
  border-radius: 8px;
}

.service-title {
  text-align: center;
  background: linear-gradient(to right, #eb4819 0%, #f78f5f 49%, #ff8d1b 100%);
  padding: 5px 10px;
  border-radius: 7px 7px 0px 0px;
  color: #fff;
  font-weight: 600;
  margin-bottom: 8px;
  margin-left: auto;
  font-size: 12px;
}

.service-table {
  width: 100%;
  border-collapse: collapse;
  margin: 10px 0;
  font-size: 14px;
  text-align: left;
  color: #636363;
}

.service-table thead {
  background-color: #fa8e44;
  border: 1px solid #d5d4d4;
}

.service-header-cell {
  padding: 6px 10px;
  font-weight: 600;
  color: #4f4f4f;
  font-size: 11px;
  border: 1px solid #d5d4d4;
}

.service-cell {
  padding: 6px 10px;
  color: #636363;
  font-size: 11px;
  border: 1px solid #c4c4c4;
}

.selected-price-cell {
  background-color: #f88f5a;
  color: #fff;
}

.price-cell:hover {
  background-color: #f88f5a;
  color: #fff;
  cursor: pointer;
}

.service-row:nth-child(even) {
  background-color: #fff;
}

.service-row:nth-child(odd) {
  background-color: #fdefe7;
}

.service-sending-button {
  cursor: pointer;
  padding: 6px 10px;
  border-radius: 5px;
  border: none;
  color: #fff;
  background: linear-gradient(to right, #eb4819 0%, #f78f5f 49%, #ff8d1b 100%);
  outline: none;
  width: 120px;
}

.quotation-service-title,
.quotation-service-table {
  width: 80%;
  margin-right: auto;
  margin-left: auto;
}

.quotation-service-row td {
  background-color: #fff;
}

.quotation-service-button {
  border: 1px solid #f9912d;
  background: #fff;
  color: #f9912d;
}

.quotation-service-button:hover {
  border: none;
  color: #fff;
  background: linear-gradient(to right, #eb4819 0%, #f78f5f 49%, #ff8d1b 100%);
}

.service-sending-button span {
  display: block;
  font-size: 11px;
  font-weight: 500;
}

.service-sending-button span:last-child {
  font-size: 9px;
}

.quotation-service-or {
  margin: 15px 0px;
  color: #636363;
  font-weight: 500;
  font-size: 15px;
}

.quotation-service-partner-text {
  margin-top: 0;
  margin-bottom: 10px;
  color: #636363;
  font-weight: 500;
  font-size: 13px;
}

/* Container for the entire service selector */
.service-selector {
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.site-city-selection-container,
.site-service-description-container {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.site-service-description-container {
  align-items: flex-end;
}

.site-city-select-label {
  font-size: 11px;
  color: #636363;
  font-weight: 500;
  text-align: left;
}

.site-city-select-label span {
  font-size: 8px;
}

.site-service-description {
  font-size: 10px;
  color: #636363;
  font-weight: 500;
  text-align: right;
}

.site-city-select-dropdown {
  width: 100%;
}

.error-site {
  text-align: right;
  margin-top: 0px;
}

.debris-details-content {
  display: flex;
  flex-direction: column;
  gap: 70px;
}

.debris-input-group {
  display: flex;
  justify-content: space-between;
  align-items: start;
  margin-top: 20px;
}

.debris-input-item {
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 6px;
  align-items: flex-start;
  width: 300px;
}

.debris-input-field {
  display: flex;
  align-items: flex-end;
}

.debris-load-input {
  color: #f9912d;
  border: 1.5px solid #f9912d;
  padding: 4px 10px;
  background-color: #fff;
  font-size: 12px;
  outline: none;
  border-radius: 4px;
  margin-right: 5px;
}

.debris-load-unit {
  font-weight: 500;
  font-size: 13px;
  color: #636363;
}
.debris-rate-group {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 5px;
}

.debris-rate-button {
  color: #fff;
  background: linear-gradient(to right, #eb4819 0%, #f78f5f 49%, #ff8d1b 100%);
  padding: 6px 10px;
  font-size: 11px;
  border: none;
  outline: none;
  border-radius: 6px;
}

.debris-selection-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 100px;
}

.debris-selection-summary {
  font-weight: 500;
  font-size: 10px;
  color: #636363;
}

.debris-selection-summary span {
  font-weight: 700;
}

.deep-cleaning-selection-container {
  width: 30%;
}

.deep-cleaning-area-container {
  width: 30%;
  text-align: left;
}

.deep-cleaning-area-input {
  padding: 6px 10px;
  outline: none;
  border: 1.5px solid #f9912d;
  color: #f9912d;
  width: 100%;
  border-radius: 5px;
  font-size: 11px;
  font-weight: 500;
}

.floor-data-item-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: space-around;
  margin-top: 5px;
}

.floor-details-content {
  gap: 15px;
}

.floor-data-item-input {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 31.5%;
  padding: 10px 5px;
  box-shadow: 0 2px 4px #ccc;
}

.floor-input-item {
  width: auto;
  margin-top: 10px;
}

.floor-data-item {
  font-size: 10px;
  padding: 5px 10px;
}

.selected-floor-data-item {
  color: #fff;
  background: linear-gradient(to right, #eb4819 0%, #f78f5f 49%, #ff8d1b 100%);
}

.render-input-field {
  width: 100px;
}

.render-selection-group {
  gap: 10px;
  justify-content: center;
}

.render-input-item {
  width: 150px;
}

.service-customer-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  align-items: start;
  padding: 20px;
  width: 100%;
}

.service-customer-details {
  width: 55%;
  padding: 10px;
}

.service-customer-payment {
  width: 45%;
}

.service-customer-info {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: start;
  margin-bottom: 15px;
}

.service-customer-info label {
  color: #636363;
  font-size: 11px;
  font-weight: 500;
}

.service-customer-input {
  padding: 6px 10px;
  font-size: 12px;
  color: #636363;
  border: 1.5px solid #636363;
  outline: none;
  border-radius: 6px;
  resize: none;
}

.service-customer-input::-webkit-scrollbar {
  display: none;
}

.service-customer-payment {
  background: linear-gradient(to bottom, #ffeddd, #fff);
  padding: 10px;
  border-radius: 10px;
}

.service-customer-coupon {
  border: 1px solid #636363;
  padding: 15px;
  border-radius: 10px;
}

.service-customer-payment-title {
  color: #636363;
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 10px;
}

.service-customer-coupon-input {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 80%;
  margin: 0 auto;
}

.service-customer-coupon-input label {
  color: #636363;
  font-size: 12px;
  font-weight: 500;
}

.service-customer-input-coupon {
  padding: 6px 10px;
  font-size: 11px;
  outline: none;
  border-radius: 6px;
  width: 250px;
  position: absolute;
}

.service-customer-apply-coupon {
  z-index: 1;
  position: relative;
  margin-left: 185px;
  margin-top: 8px;
  font-size: 11px;
  color: #f99e33;
  font-weight: 500;
  letter-spacing: 1px;
  cursor: pointer;
  margin-bottom: 20px;
}

.service-customer-apply-coupon:hover {
  font-weight: 600;
}

.service-customer-subtotal {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  margin: 5px auto;
  font-size: 11px;
  color: #636363;
  font-weight: 600;
}

.service-customer-total {
  width: 80%;
  margin: 15px auto;
  border: 1.5px solid #f99e33;
  color: #f99e33;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 6px;
  background-color: #fff;
  padding: 6px;
  font-size: 12px;
}

.service-customer-proceed-btn {
  display: flex;
  justify-content: center;
  text-align: center;
}

.service-customer-proceed-btn button {
  width: 80%;
  padding: 6px 10px;
  font-size: 11px;
  color: #fff;
  background: linear-gradient(to right, #eb4819 0%, #f78f5f 49%, #ff8d1b 100%);
  border: none;
  outline: none;
  border-radius: 6px;
  cursor: pointer;
}

.service-customer-privacy-text {
  font-size: 9px;
  color: #636363;
  font-weight: 500;
  width: 80%;
  margin: 10px auto;
  text-align: justify;
}

.selected-service-icon {
  color: #fff;
  border-radius: 50%;
  font-size: 15px;
  width: 15px;
  margin-left: 5px;
  margin-bottom: -3px;
  float: right;
}

@media screen and (max-width: 2570px) {
  .service-heading {
    font-size: 30px;
  }

  .service-cities-list {
    font-size: 18px;
  }

  .service-title {
    font-size: 23px;
  }

  .service-table {
    font-size: 18px;
  }

  .service-header-cell {
    font-size: 18px;
  }

  .service-cell {
    font-size: 18px;
  }

  .service-sending-button {
    width: 200px;
    font-size: 23px;
  }

  .service-sending-button span {
    font-size: 20px;
  }

  .service-sending-button span:last-child {
    font-size: 18px;
  }

  .quotation-service-or {
    font-size: 20px;
  }

  .quotation-service-partner-text {
    font-size: 19px;
  }

  .site-service-description {
    font-size: 16px;
  }

  .service-map-image {
    height: 267px;
  }

  .site-city-select-label {
    font-size: 19px;
  }

  .floor-data-item {
    font-size: 18px;
    padding: 10px;
  }

  .debris-load-unit {
    font-size: 16px;
  }

  .debris-selection-summary {
    font-size: 17px;
  }

  .debris-input-item {
    width: 400px;
  }

  .floor-input-item {
    width: 100%;
  }

  .render-input-field {
    width: 100px;
    font-size: 18px;
  }

  .site-city-select-label span {
    font-size: 14px;
  }

  .debris-rate-button {
    font-size: 22px;
  }

  .deep-cleaning-area-input {
    font-size: 22px;
  }

  .render-selection-group {
    gap: 10px;
  }

  .render-input-item {
    width: 150px;
  }

  .service-customer-info label {
    font-size: 20px;
  }

  .service-customer-input {
    padding: 10px 10px;
    font-size: 21px;
  }

  .service-customer-payment-title {
    font-size: 23px;
  }

  .service-customer-coupon-input label {
    font-size: 22px;
  }

  .service-customer-input-coupon {
    font-size: 22px;
    width: 366px;
  }

  .service-customer-apply-coupon {
    font-size: 22px;
    margin-left: 250px;
  }

  .service-customer-subtotal {
    font-size: 21px;
  }

  .service-customer-total {
    font-size: 22px;
  }

  .service-customer-proceed-btn button {
    font-size: 23px;
  }

  .service-customer-privacy-text {
    font-size: 18px;
  }

  .service-booking-button {
    font-size: 22px;
  }
}

@media screen and (max-width: 2000px) {
  .service-heading {
    font-size: 20px;
  }

  .service-cities-list {
    font-size: 14px;
  }

  .service-title {
    font-size: 16px;
  }

  .service-table {
    font-size: 14px;
  }

  .service-header-cell {
    font-size: 12px;
  }

  .service-cell {
    font-size: 12px;
  }

  .service-sending-button {
    width: 140px;
    font-size: 14px;
  }

  .service-sending-button span {
    font-size: 13px;
  }

  .service-sending-button span:last-child {
    font-size: 13px;
  }

  .quotation-service-or {
    font-size: 16px;
  }

  .quotation-service-partner-text {
    font-size: 16px;
  }

  .site-service-description {
    font-size: 13px;
  }

  .service-map-image {
    height: 230px;
  }

  .site-city-select-label {
    font-size: 13px;
  }

  .floor-data-item {
    font-size: 13px;
    padding: 6px 10px;
  }

  .render-data-item {
    padding: 6px;
  }

  .debris-load-unit {
    font-size: 12px;
  }

  .debris-selection-summary {
    font-size: 12px;
  }

  .debris-input-item {
    width: 400px;
  }

  .floor-input-item {
    width: 100%;
  }

  .render-input-field {
    width: 100px;
    font-size: 14px;
  }

  .site-city-select-label span {
    font-size: 14px;
  }

  .debris-rate-button {
    font-size: 13px;
  }

  .deep-cleaning-area-input {
    font-size: 13px;
  }

  .render-selection-group {
    gap: 10px;
  }

  .render-input-item {
    width: 150px;
  }

  .service-customer-info label {
    font-size: 12px;
  }

  .service-customer-input {
    padding: 6px 10px;
    font-size: 13px;
  }

  .service-customer-payment-title {
    font-size: 13px;
  }

  .service-customer-coupon-input label {
    font-size: 12px;
  }

  .service-customer-input-coupon {
    font-size: 13px;
    width: 300px;
  }

  .service-customer-apply-coupon {
    font-size: 13px;
    margin-left: 230px;
  }

  .service-customer-subtotal {
    font-size: 13px;
  }

  .service-customer-total {
    font-size: 14px;
  }

  .service-customer-proceed-btn button {
    font-size: 13px;
  }

  .service-customer-privacy-text {
    font-size: 11px;
  }

  .service-booking-button {
    font-size: 13px;
  }
}

@media screen and (max-width: 1400px) {
  .service-heading {
    font-size: 16px;
  }

  .service-cities-list {
    font-size: 11px;
  }

  .service-title {
    font-size: 13px;
  }

  .service-table {
    font-size: 12px;
  }

  .service-header-cell {
    font-size: 11px;
  }

  .service-cell {
    font-size: 11px;
  }

  .service-sending-button {
    width: 120px;
  }

  .service-sending-button span {
    font-size: 12px;
  }

  .service-sending-button span:last-child {
    font-size: 11px;
  }

  .quotation-service-or {
    font-size: 14px;
  }

  .quotation-service-partner-text {
    font-size: 12px;
  }

  .site-service-description {
    font-size: 11px;
  }

  .service-map-image {
    height: 230px;
  }

  .site-city-select-label {
    font-size: 11px;
  }

  .floor-data-item {
    font-size: 12px;
  }

  .render-data-item {
    padding: 6px;
  }

  .debris-load-unit {
    font-size: 10px;
  }

  .debris-selection-summary {
    font-size: 12px;
  }

  .debris-input-item {
    width: 300px;
  }

  .floor-input-item {
    width: 100%;
  }

  .render-input-field {
    width: 100px;
    font-size: 12px;
  }

  .site-city-select-label span {
    font-size: 10px;
  }

  .debris-rate-button {
    font-size: 12px;
  }

  .deep-cleaning-area-input {
    font-size: 11.5px;
  }

  .render-selection-group {
    gap: 10px;
  }

  .render-input-item {
    width: 95%;
  }

  .service-customer-info label {
    font-size: 11px;
  }

  .service-customer-input {
    font-size: 12px;
  }

  .service-customer-payment-title {
    font-size: 12px;
  }

  .service-customer-coupon-input label {
    font-size: 12px;
  }

  .service-customer-input-coupon {
    font-size: 12px;
    width: 250px;
  }

  .service-customer-apply-coupon {
    font-size: 12px;
    margin-left: 186px;
  }

  .service-customer-subtotal {
    font-size: 12px;
  }

  .service-customer-total {
    font-size: 13px;
  }

  .service-customer-proceed-btn button {
    font-size: 12px;
  }

  .service-customer-privacy-text {
    font-size: 9px;
  }

  .service-booking-button {
    font-size: 11px;
  }
}

@media screen and (max-width: 1100px) {
  .service-customer-input-coupon {
    font-size: 12px;
    width: 230px;
}

.service-customer-apply-coupon {
  margin-left: 168px;
}
}

@media screen and (max-width: 950px) {
  .service-container {
    flex-direction: column-reverse;
  }

  .service-details {
    width: 100%;
  }

  .service-map {
    width: 100%;
  }

  .service-map-image {
    height: 280px;
    margin-top: 20px;
  }

  .service-heading {
    font-size: 22px;
    text-align: center;
  }

  .service-cities-list {
    font-size: 15.5px;
    text-align: center;
  }

  .service-title {
    font-size: 21px;
  }

  .service-table {
    font-size: 18px;
  }

  .service-header-cell {
    font-size: 18px;
  }

  .service-cell {
    font-size: 17px;
  }

  .service-sending-button {
    width: 150px;
    padding: 8px 10px;
    font-size: 20px;
  }

  .service-sending-button span {
    font-size: 17px;
  }

  .service-sending-button span:last-child {
    font-size: 15px;
  }

  .quotation-service-or {
    font-size: 17px;
  }

  .quotation-service-partner-text {
    font-size: 18px;
  }

  .site-city-selection-container {
    width: 50%;
  }

  .service-selector {
    margin: 25px auto;
    align-items: flex-end;
  }

  .site-service-description {
    font-size: 16.5px;
  }

  .debris-input-item {
    width: auto;
  }

  .floor-input-item {
    width: 100%;
  }

  .site-city-select-label {
    font-size: 18px;
  }

  .site-city-select-label span {
    font-size: 15px;
  }

  .debris-load-input {
    padding: 8px 10px;
    font-size: 20px;
    border-radius: 6px;
  }

  .debris-load-unit {
    font-size: 17px;
  }

  .debris-rate-button {
    padding: 8px 10px;
    font-size: 20px;
    border-radius: 6px;
  }

  .service-booking-button {
    padding: 7px 18px;
    font-size: 20px;
    border-radius: 6px;
  }

  .debris-selection-group {
    gap: 49px;
    margin-top: 25px;
  }

  .debris-selection-summary {
    font-size: 16.5px;
  }

  .floor-data-item-list {
    gap: 20px;
    margin-top: 13px;
  }

  .service-customer-container {
    flex-direction: column;
    padding: 0;
    gap: 0;
  }

  .service-customer-details {
    width: 100%;
  }

  .service-customer-payment {
    width: 100%;
  }

  .service-customer-info {
    gap: 10px;
    margin-bottom: 15px;
  }

  .service-customer-info label {
    font-size: 18px;
  }

  .service-customer-input {
    padding: 10px;
    font-size: 18px;
  }

  .service-customer-payment-title {
    font-size: 20px;
  }

  .service-customer-coupon-input label {
    font-size: 18px;
  }

  .service-customer-input-coupon {
    font-size: 18px;
    padding: 6px 10px;
    width: 360px;
  }

  .service-customer-apply-coupon {
    font-size: 18px;
    margin-left: 275px;
  }

  .service-customer-subtotal {
    font-size: 18px;
  }

  .service-customer-total {
    font-size: 19px;
  }

  .service-customer-proceed-btn button {
    font-size: 20px;
  }

  .service-customer-privacy-text {
    font-size: 16px;
  }

  .render-data-item-list{
    gap: 10px;
  }

  .render-data-item-input{
width: 48%;
  }

  .render-input-item{
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: 850px) {
  .service-map-image {
    height: 280px;
  }

  .service-heading {
    font-size: 18px;
  }

  .service-cities-list {
    font-size: 14px;
  }

  .service-title {
    font-size: 16px;
  }

  .service-table {
    font-size: 16px;
  }

  .service-header-cell {
    font-size: 14.5px;
  }

  .service-cell {
    font-size: 15px;
  }

  .service-sending-button {
    font-size: 16px;
  }

  .service-sending-button span {
    font-size: 16px;
  }

  .service-sending-button span:last-child {
    font-size: 14.5px;
  }

  .quotation-service-or {
    font-size: 16px;
  }

  .quotation-service-partner-text {
    font-size: 16px;
  }

  .service-selector {
    margin: 17px auto;
  }

  .site-service-description {
    font-size: 14px;
  }

  .site-city-select-label {
    font-size: 14px;
  }

  .site-city-select-label span {
    font-size: 13px;
  }

  .debris-load-input {
    padding: 6px 10px;
    font-size: 15px;
    border-radius: 6px;
  }

  .debris-load-unit {
    font-size: 14px;
  }

  .debris-rate-button {
    padding: 6px 10px;
    font-size: 15px;
    border-radius: 6px;
  }

  .service-booking-button {
    padding: 6px 18px;
    font-size: 15px;
    border-radius: 6px;
  }

  .debris-selection-group {
    gap: 49px;
  }

  .debris-selection-summary {
    font-size: 14px;
    text-align: left;
  }

  .service-customer-info label {
    font-size: 15px;
  }

  .service-customer-input {
    padding: 10px;
    font-size: 15px;
  }

  .service-customer-payment-title {
    font-size: 17px;
  }

  .service-customer-coupon-input label {
    font-size: 16px;
  }

  .service-customer-input-coupon {
    font-size: 15px;
    width: 300px;
  }

  .service-customer-apply-coupon {
    font-size: 15px;
    margin-left: 225px;
  }

  .service-customer-subtotal {
    font-size: 15px;
  }

  .service-customer-total {
    font-size: 16px;
  }

  .service-customer-proceed-btn button {
    font-size: 16px;
  }

  .service-customer-privacy-text {
    font-size: 12px;
  }
}

@media screen and (max-width: 600px) {
  .service-map-image {
    height: 210px;
  }

  .service-heading {
    font-size: 14px;
  }

  .service-cities-list {
    font-size: 12px;
  }

  .service-title {
    font-size: 12px;
  }

  .service-table {
    font-size: 12px;
  }

  .service-header-cell {
    font-size: 11px;
  }

  .service-cell {
    font-size: 11px;
  }

  .service-sending-button {
    width: 120px;
  }

  .service-sending-button span {
    font-size: 12px;
  }

  .service-sending-button span:last-child {
    font-size: 11px;
  }

  .quotation-service-or {
    font-size: 12px;
  }

  .quotation-service-partner-text {
    font-size: 12px;
  }

  .site-service-description {
    font-size: 12px;
  }

  .site-city-select-label {
    font-size: 12px;
  }

  .site-city-select-label span {
    font-size: 10.5px;
  }

  .debris-load-input {
    font-size: 12px;
  }

  .debris-load-unit {
    font-size: 11px;
  }

  .debris-rate-button {
    font-size: 12px;
  }

  .service-booking-button {
    font-size: 10.5px;
  }

  .service-sending-button,
  .debris-selection-summary {
    font-size: 11px;
  }

  .debris-details-content {
    gap: 29px;
  }

  .floor-details-content {
    gap: 10px;
  }

  .floor-data-item-list {
    gap: 5px;
    margin-top: 6px;
  }

  .render-selection-group {
    margin-top: 10px;
  }

  .service-customer-container {
    flex-direction: column;
    padding: 0;
    gap: 0;
  }

  .service-customer-details {
    width: 100%;
  }

  .service-customer-payment {
    width: 100%;
  }

  .service-customer-info {
    gap: 5px;
    margin-bottom: 10px;
  }

  .service-customer-input-coupon {
    width: 310px;
  }

  .service-customer-apply-coupon {
    margin-left: 255px;
  }

  .service-customer-coupon {
    padding: 10px;
  }

  .service-customer-info label {
    font-size: 12px;
  }

  .service-customer-input {
    padding: 6px 10px;
    font-size: 12px;
  }

  .service-customer-payment-title {
    font-size: 13px;
  }

  .service-customer-coupon-input label {
    font-size: 12px;
  }

  .service-customer-input-coupon {
    font-size: 12px;
    width: 300px;
  }

  .service-customer-apply-coupon {
    font-size: 12px;
    margin-left: 240px;
  }

  .service-customer-coupon-input {
    gap: 5px;
  }

  .service-customer-subtotal {
    font-size: 12px;
  }

  .service-customer-total {
    margin: 10px auto;
  }

  .service-customer-total {
    font-size: 13px;
  }

  .service-customer-proceed-btn button {
    font-size: 13px;
  }

  .service-customer-privacy-text {
    font-size: 11px;
  }

  .deep-service-selector {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 450px) {
  .service-details {
    padding: 5px;
  }

  .service-heading {
    font-size: 14px;
  }

  .service-cities-list {
    font-size: 11px;
  }

  .service-title {
    font-size: 12.5px;
  }

  .service-table {
    font-size: 12.5px;
  }

  .service-header-cell {
    font-size: 11.5px;
  }

  .service-cell {
    font-size: 11.5px;
  }

  .service-sending-button {
    width: 120px;
  }

  .service-sending-button span {
    font-size: 12.5px;
  }

  .service-sending-button span:last-child {
    font-size: 11.5px;
  }

  .quotation-service-or {
    font-size: 12.5px;
  }

  .quotation-service-partner-text {
    font-size: 12.5px;
  }

  .debris-selection-group {
    gap: 30px;
  }

  .service-customer-input-coupon {
    width: 260px;
  }

  .service-customer-apply-coupon {
    margin-left: 200px;
  }

  .render-input-group {
    justify-content: space-evenly;
    margin-top: 5px;
    gap: 10px;
    flex-wrap: wrap;
  }

  .site-service-description {
    font-size: 11px;
  }
}

@media screen and (max-width: 400px) {
  .service-heading {
    font-size: 12px;
  }

  .service-map-image {
    height: 155px;
    margin-top: 10px;
    object-fit: fill;
  }

  .service-cities-list {
    font-size: 11px;
  }

  .service-title {
    font-size: 12px;
  }

  .service-table {
    font-size: 12px;
  }

  .service-header-cell {
    font-size: 11px;
  }

  .service-cell {
    font-size: 11px;
  }

  .service-sending-button {
    width: 120px;
  }

  .service-sending-button span {
    font-size: 12px;
  }

  .service-sending-button span:last-child {
    font-size: 11px;
  }

  .quotation-service-or {
    font-size: 12px;
  }

  .quotation-service-partner-text {
    font-size: 12px;
  }

  .service-customer-coupon-input label {
    font-size: 11px;
  }

  .service-customer-input-coupon {
    font-size: 11.5px;
    width: 246px;
  }

  .service-customer-apply-coupon {
    margin-left: 185px;
    margin-bottom: 10px;
  }

  .service-customer-subtotal {
    font-size: 11px;
  }

  .service-customer-total {
    margin: 10px auto;
  }

  .service-customer-total {
    font-size: 11.5px;
  }

  .service-customer-proceed-btn button {
    font-size: 12px;
  }

  .service-customer-privacy-text {
    font-size: 9px;
    margin: 2px auto;
  }

  .render-details-content{
    gap: 5px;
  }

  .render-selection-group{
    margin-top: 5px;
    margin-bottom: -5px;
  }
}
