.partner-container {
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0;
}

.partner-cover-img {
  position: fixed;
  width: 100%;
  top: 0;
  right: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.partner-section {
  position: relative;
  width: 450px;
  margin: -30px auto 20px auto;
  background-color: rgba(117, 115, 115, 0.5);
  padding: 20px;
  border-radius: 20px;
}

.partner-content {
  background-color: #ffffff;
  padding: 0px;
  border-radius: 20px;
  z-index: 1;
}

.partner-title {
  text-align: center;
  padding: 10px;
  background: linear-gradient(to right, #eb4819 0%, #f78f5f 49%, #ff8d1b 100%);
  border-radius: 20px 20px 0 0;
  font-size: 15px;
  color: #fff;
  font-weight: 500;
}

.partner-form {
  padding: 20px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: auto;
}

.partner-form::-webkit-scrollbar {
  display: none;
}

.partner-form-group {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  margin-bottom: 15px;
}

.partner-form-label {
  font-size: 14px;
  color: #636363;
  width: 100%;
  margin-bottom: 7px;
  font-weight: 500;
}

.partner-radio-selection {
  display: flex;
  align-items: start;
  justify-content: start;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 10px;
}

.partner-radio-option {
  display: flex;
  width: 45%;
  justify-content: start;
  align-items: center;
  margin-bottom: 8px;
}

.partner-radio-option-width {
  width: 100%;
}

.partner-radio-label {
  font-size: 13px;
  color: #5e5e5e;
}

.partner-form-input {
  width: 100%;
  border: 1px solid #636363;
  border-top: none;
  border-right: none;
  border-left: none;
  font-size: 13px;
  outline: none;
  padding: 3px 0px;
  color: #9b9c9c;
  letter-spacing: 0.6px;
}

.partner-input {
  color: #636363;
  letter-spacing: 0.5px;
}

.partner-form-input::placeholder {
  font-size: 12px;
}

.partner-submit-btn button {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  color: #fff;
  background: linear-gradient(to right, #eb4819 0%, #f78f5f 49%, #ff8d1b 100%);
  font-weight: 500;
  letter-spacing: 0.6px;
  font-family: "montserrat" sans-serif;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  margin-top: 15px;
}

.partner-close-icon {
  display: flex;
  position: absolute;
  cursor: pointer;
  z-index: 999;
  color: #f9912d;
  border: 1px solid #f9912d;
  font-size: 22px;
  width: 22px;
  background-image: linear-gradient(to left, #f8f5ea, #f8eee6);
  border-radius: 50%;
  padding: 1px;
  margin-top: -23px;
  margin-right: -2px;
  right: 0;
}

.partner-close-icon:hover {
  color: #fff;
  background-image: linear-gradient(to left, #f9912d, #f9912d);
}

.partner-verified-container {
  padding: 15px;
  margin-bottom: 80px;
}

.partner-verified-content {
  padding: 10px 20px;
}

.partner-verified-close {
  font-size: 20px;
  width: 20px;
  margin-top: -7px;
  margin-right: -4px;
}

.partner-verified-success-heading {
  font-size: 16px;
}

.partner-verification-notice {
  width: 80%;
}

.partner-verification-processing-time {
  font-size: 10px;
}

.schedule-link-text{
  font-weight: 600;
  color: #636363;
  font-size: 12.5px;
  font-style: normal;
}

.schedule-link{
  text-decoration: none;
  color: var(--primary-color, rgb(0, 105, 255));
  font-weight: 700;
  cursor: pointer;
}

@media screen and (max-width: 2570px) {
  .partner-container {
    height: auto;
  }

  .partner-section {
    width: 650px;
    margin: 30px auto;
    padding: 30px;
  }

  .partner-title {
    font-size: 30px;
  }

  .partner-form {
    padding: 20px;
  }

  .partner-form-group {
    margin-bottom: 18px;
  }

  .partner-form-label {
    font-size: 22px;
    margin-bottom: 12px;
  }

  .partner-radio-option {
    margin-bottom: 18px;
    width: 50%;
  }

  .partner-radio-option-width {
    width: 100%;
  }

  .partner-radio-label {
    font-size: 22px;
  }

  .partner-form-input {
    font-size: 24px;
    padding: 6px 0;
    margin-bottom: 18px;
  }

  .partner-form-input::placeholder {
    font-size: 22px;
  }

  .partner-input {
    margin-bottom: 6px;
  }

  .partner-submit-btn button {
    font-size: 25px;
  }

  .partner-close-icon {
    font-size: 40px;
    width: 40px;
    margin-top: -35px;
    margin-right: -10px;
  }

  .partner-note {
    text-align: center;
    color: #636363;
    font-size: 21px;
    margin-top: 30px;
    margin-bottom: 40px;
    font-weight: 500;
  }

  .partner-verified-content {
    padding: 30px;
  }

  .partner-verified-container {
    padding: 30px;
  }

  .partner-verified-close {
    font-size: 35px;
    width: 35px;
    margin-top: -7px;
    margin-right: -4px;
  }

  .partner-verified-success-heading {
    font-size: 22px;
  }

  .partner-verification-notice {
    width: 95%;
    font-size: 21px;
  }

  .partner-verification-processing-time {
    font-size: 15px;
  }
}

@media screen and (max-width: 2000px) {
  .partner-container {
    height: auto;
  }

  .partner-section {
    width: 400px;
    margin: -30px auto 30px auto;
  }

  .partner-title {
    font-size: 18px;
  }

  .partner-form {
    padding: 20px;
  }

  .partner-form-group {
    margin-bottom: 14px;
  }

  .partner-form-label {
    font-size: 13px;
    margin-bottom: 8px;
  }

  .partner-radio-option {
    margin-bottom: 10px;
  }

  .partner-radio-label {
    font-size: 13px;
  }

  .partner-form-input {
    font-size: 14px;
    padding: 6px 0;
    margin-bottom: 10px;
  }

  .partner-form-input::placeholder {
    font-size: 14px;
  }

  .partner-submit-btn button {
    font-size: 15px;
    letter-spacing: 0.2px;
  }

  .partner-close-icon {
    font-size: 30px;
    width: 30px;
    margin-top: -35px;
    margin-right: -5px;
  }

  .partner-note {
    font-size: 12px;
    margin-top: 15px;
    margin-bottom: 20px;
  }

  .partner-verified-content {
    padding: 30px;
  }

  .partner-verified-container {
    padding: 15px;
  }

  .partner-verified-close {
    font-size: 25px;
    width: 25px;
    margin-top: -7px;
    margin-right: -4px;
  }

  .partner-verified-success-heading {
    font-size: 18px;
  }

  .partner-verification-notice {
    width: 95%;
    font-size: 17px;
  }

  .partner-verification-processing-time {
    font-size: 12px;
  }
}

@media screen and (max-width: 1400px) {
  .partner-container {
    width: 100%;
  }

  .partner-section {
    width: 320px;
    padding: 20px;
  }

  .partner-title {
    font-size: 14px;
  }

  .partner-form {
    padding: 20px;
    padding-bottom: 10px;
  }

  .partner-form-group {
    margin-bottom: 10px;
  }

  .partner-form-label {
    font-size: 10px;
    margin-bottom: 0px;
  }

  .partner-radio-selection {
    margin-top: 10px;
  }

  .partner-radio-option {
    margin-bottom: 7px;
  }

  .partner-radio-label {
    font-size: 10px;
  }

  .partner-form-input {
    font-size: 11px;
    padding: 3px 0px;
    margin-top: 3px;
    margin-bottom: 5px;
  }

  .partner-input {
    font-size: 11px;
    margin-top: 5px;
    margin-bottom: 3px;
  }

  .partner-form-input::placeholder {
    font-size: 10px;
  }

  .partner-input::placeholder {
    font-size: 10px;
  }

  .partner-submit-btn button {
    font-size: 14px;
    padding: 8px;
  }

  .partner-close-icon {
    font-size: 22px;
    width: 22px;
    margin-top: -23px;
    margin-right: -2px;
  }

  .partner-note {
    font-size: 10px;
    margin-top: 20px;
    margin-bottom: 15px;
  }

  .partner-verified-container {
    padding: 15px;
  }

  .partner-verified-content {
    padding: 10px 20px;
  }

  .partner-verified-close {
    font-size: 20px;
    width: 20px;
  }

  .partner-verified-success-heading {
    font-size: 15px;
  }

  .partner-verification-notice {
    width: 95%;
    font-size: 11.5px;
  }

  .partner-verification-processing-time {
    font-size: 10px;
  }
}

@media screen and (max-width: 1100px) {
  .partner-section {
    width: 300px;
    padding: 15px;
  }

  .partner-title {
    font-size: 13px;
  }

  .partner-form {
    padding: 15px;
    padding-bottom: 10px;
  }

  .partner-form-group {
    margin-bottom: 10px;
  }

  .partner-form-label {
    font-size: 10px;
    margin-bottom: 0px;
  }

  .partner-radio-selection {
    margin-top: 10px;
  }

  .partner-radio-option {
    margin-bottom: 7px;
  }

  .partner-radio-label {
    font-size: 9.5px;
  }

  .partner-form-input {
    font-size: 10px;
    padding: 3px 0px;
    margin-top: 3px;
    margin-bottom: 5px;
  }

  .partner-input {
    font-size: 10px;
    margin-top: 5px;
    margin-bottom: 3px;
  }

  .partner-submit-btn button {
    font-size: 14px;
    padding: 8px;
  }

  .partner-close-icon {
    font-size: 22px;
    width: 22px;
    margin-top: -20px;
    margin-right: -4px;
  }

  .partner-verified-container {
    padding: 10px;
  }

  .partner-verified-content {
    padding: 10px;
  }

  .partner-verified-close {
    font-size: 18px;
    width: 18px;
    margin-top: -7px;
    margin-right: -4px;
  }

  .partner-verified-success-heading {
    font-size: 15px;
  }

  .partner-verification-notice {
    width: 95%;
    font-size: 13px;
  }

  .partner-verification-processing-time {
    font-size: 10px;
  }
}

@media screen and (max-width: 950px) {
  .partner-container {
    width: 100%;
    height: auto;
  }

  .partner-section {
    width: 450px;
    margin: 30px auto;
    padding: 30px;
  }

  .partner-title {
    font-size: 22px;
  }

  .partner-form {
    padding: 20px;
  }

  .partner-form-group {
    margin-bottom: 15px;
  }

  .partner-form-label {
    font-size: 17px;
  }

  .partner-radio-selection {
    margin-top: 10px;
  }

  .partner-radio-option {
    margin-bottom: 5px;
  }

  .partner-radio-label {
    font-size: 17px;
    margin-bottom: 6px;
  }

  .partner-form-input {
    font-size: 17px;
    padding: 5px 0px;
  }

  .partner-input {
    font-size: 17px;
  }

  .partner-form-input::placeholder {
    font-size: 16px;
  }

  .partner-input::placeholder {
    font-size: 16px;
  }

  .partner-submit-btn button {
    font-size: 22px;
  }

  .partner-close-icon {
    font-size: 32px;
    width: 32px;
    margin-top: -35px;
    margin-right: -5px;
    z-index: 998;
  }

  .partner-note {
    font-size: 14.5px;
  }

  .partner-verified-container {
    padding: 15px;
  }

  .partner-verified-content {
    padding: 20px;
  }

  .partner-verified-close {
    font-size: 24px;
    width: 24px;
    margin-top: -7px;
    margin-right: -4px;
  }

  .partner-verified-success-heading {
    font-size: 18px;
  }

  .partner-verification-notice {
    width: 80%;
    font-size: 15px;
  }

  .partner-verification-processing-time {
    font-size: 12.5px;
  }
}

@media screen and (max-width: 850px) {
  .partner-section {
    margin: 30px auto;
    width: 380px;
  }

  .partner-title {
    font-size: 18px;
  }

  .partner-form {
    padding: 15px;
  }

  .partner-form-group {
    margin-bottom: 15px;
  }

  .partner-form-label {
    font-size: 15px;
  }

  .partner-radio-selection {
    width: 100%;
    margin-top: 7px;
  }

  .partner-radio-option {
    margin-bottom: 5px;
  }

  .partner-radio-label {
    font-size: 14px;
    margin-bottom: 6px;
  }

  .partner-form-input {
    font-size: 15px;
    padding: 5px 0px;
  }

  .partner-input {
    font-size: 15px;
  }

  .partner-form-input::placeholder {
    font-size: 15px;
  }

  .partner-input::placeholder {
    font-size: 15px;
  }

  .partner-submit-btn button {
    font-size: 20px;
  }

  .partner-close-icon {
    font-size: 30px;
    width: 30px;
    margin-top: -35px;
    margin-right: -5px;
  }

  .partner-note {
    font-size: 13px;
  }
}

@media screen and (max-width: 600px) {
  .partner-container {
    width: 100%;
    height: auto;
  }

  .partner-section {
    width: 350px;
    margin: 30px auto;
    padding: 20px;
  }

  .partner-title {
    font-size: 17px;
  }

  .partner-form-group {
    margin-bottom: 15px;
  }

  .partner-form-label {
    font-size: 14px;
  }

  .partner-radio-selection {
    width: 100%;
    margin-top: 7px;
  }

  .partner-radio-option {
    margin-bottom: 5px;
  }

  .partner-radio-label {
    font-size: 11px;
    margin-bottom: 6px;
  }

  .partner-form-input {
    font-size: 12px;
    padding: 5px 0px;
  }

  .partner-input {
    font-size: 12px;
  }

  .partner-form-input::placeholder {
    font-size: 11px;
  }

  .partner-input::placeholder {
    font-size: 11px;
  }

  .partner-submit-btn button {
    font-size: 16px;
  }

  .partner-close-icon {
    font-size: 25px;
    width: 25px;
    margin-top: -24px;
    margin-right: -4px;
  }

  .partner-note {
    font-size: 11.5px;
  }

  .partner-verified-close {
    font-size: 22px;
    width: 22px;
  }

  .partner-verified-success-heading {
    font-size: 17px;
  }

  .partner-verification-notice {
    font-size: 14px;
  }

  .partner-verification-processing-time {
    font-size: 12px;
  }
}

@media screen and (max-width: 450px) {
  .partner-section {
    width: 100%;
    margin: 10px auto 0 auto;
    padding: 20px;
  }

  .partner-title {
    font-size: 18px;
  }

  .partner-form {
    padding: 20px;
  }

  .partner-form-group {
    margin-bottom: 15px;
  }

  .partner-form-label {
    font-size: 14px;
  }

  .partner-radio-selection {
    width: 100%;
    margin-top: 7px;
  }

  .partner-radio-label {
    font-size: 14px;
    margin-bottom: 6px;
  }

  .partner-form-input {
    font-size: 14px;
  }

  .partner-input {
    font-size: 14px;
  }

  .partner-form-input::placeholder {
    font-size: 13px;
  }

  .partner-input::placeholder {
    font-size: 13px;
  }

  .partner-submit-btn button {
    font-size: 18px;
  }

  .partner-close-icon {
    font-size: 28px;
    width: 28px;
    margin-top: -26px;
    margin-right: 2px;
  }

  .partner-note {
    font-size: 14px;
  }

  .partner-verified-content {
    padding: 15px;
  }

  .partner-verified-close {
    font-size: 22px;
    width: 22px;
  }

  .partner-verified-success-heading {
    font-size: 16px;
  }

  .partner-verification-notice {
    font-size: 13px;
  }

  .partner-verification-processing-time {
    font-size: 12px;
  }
}

@media screen and (max-width: 400px) {
  .partner-title {
    font-size: 17px;
  }

  .partner-form {
    padding: 12px;
  }

  .partner-form-group {
    margin-bottom: 7px;
  }

  .partner-form-label {
    font-size: 13px;
    margin-bottom: 6px;
  }

  .partner-radio-selection {
    width: 100%;
    margin-top: 5px;
  }

  .partner-radio-label {
    font-size: 14px;
    margin-bottom: 4px;
  }

  .partner-form-input {
    font-size: 13px;
  }

  .partner-input {
    font-size: 13px;
  }

  .partner-form-input::placeholder {
    font-size: 13px;
  }

  .partner-input::placeholder {
    font-size: 13px;
  }

  .partner-submit-btn button {
    font-size: 16px;
  }

  .partner-note {
    font-size: 13px;
  }

  .partner-verified-success-heading {
    font-size: 14px;
  }

  .partner-verification-notice {
    font-size: 12px;
  }

  .partner-verification-processing-time {
    font-size: 11px;
  }
}

@media screen and (max-width: 350px) {
  .partner-form-label {
    font-size: 15px;
  }

  .partner-radio-label {
    font-size: 12px;
  }

  .partner-note {
    font-size: 12px;
  }
}
