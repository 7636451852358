.city-template-container {
  margin: 10px auto;
  height: 75vh;
  border-radius:10px;
  overflow-y: auto;
  width: 75%;
    padding: 15px;
    background-color: rgb(117 115 115 / 20%);
    border-radius: 10px;
}

.city-template-container::-webkit-scrollbar {
  display: none;
}

.city-template-section {
  border-radius: 10px;
    background-color: #fff;
    padding: 15px;
    height: 100%;
}

.city-template-title {
  text-align: center;
  font-weight: 500;
  color: #636363;
  font-size: 18px;
}

.city-template-content {
  padding:10px;
}

.city-template-list {
  display: flex;
  gap: 1.6%;
  flex-wrap: wrap;
  list-style-type: none;
  cursor: pointer;
  position: relative;

}



.city-template-item {
  height: 120px;
  width: 15.33%;
  border: 1.5px solid #f9912d;
  border-radius: 6px;
  margin-bottom: 1.6%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

}

.hovered-template{
    background: linear-gradient(45deg, #f8f4f0, #facda2);
}

.hovered-template:hover{
    background: linear-gradient(45deg, #facda2, #f8f4f0);
}

.new-city-template {
    flex-direction: column;
    justify-content: space-between;
    background-color: #fff;
  }

.new-city-icon {
  width: 40px;
  font-size: 40px;
  border-radius: 50%;
  border: 2px solid #f9912d;
  color: #f9912d;
  padding: 3px;
}

.new-city-text {
  padding: 4px;
  border-top: 1.5px solid #f9912d;
  font-weight: 600;
  font-size: 11.5px;
  letter-spacing: 0.3px;
  width: 100%;
  text-align: center;
  color: #5a5656;
}

.delete-city-template-icon{
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
    color: #fff;
}

.hovered-template:hover .delete-city-template-icon{
    color: #f9912d;
}

.city-template-name{
    font-size: 12px;
    font-weight: 500;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.city-template-input-container{
  display: flex;
  gap: 5px;
}

.city-template-field{
  width: 35%;
}

@media screen and (max-width: 2570px) {
  .city-template-container {
    margin: 10% auto;
    height: 50vh;
  }

  
  .city-template-title {
    font-size: 40px;
  }

  .delete-city-template-icon {
   font-size: 25px;
   width: 25px;
}
  
  
  .city-template-item {
    height: 200px;
  }
  
  .new-city-text {
    font-size: 17px;
  }
  
  .city-template-name{
      font-size: 20px;
  }
  
}

@media screen and (max-width: 2000px) {
  .city-template-container {
    margin: 10px auto;
    height: 60vh;
  }

  
  .city-template-title {
    font-size: 20px;
  }

  .delete-city-template-icon {
   font-size: 20px;
   width: 20px;
}
  
  
  .city-template-item {
    height: 120px;
  }
  
  .new-city-text {
    font-size: 12px;
  }
  
  .city-template-name{
      font-size: 13px;
  }
  
}

@media screen and (max-width: 1400px) {
  .city-template-container {
    margin: 10px auto;
    height: 75vh;
  }

  
  .city-template-title {
    font-size: 15px;
  }

  .delete-city-template-icon {
   font-size: 14px;
   width: 14px;
}
  
  
  .city-template-item {
    height: 120px;
  }
  
  .new-city-text {
    font-size: 11px;
  }
  
  .city-template-name{
      font-size: 11.5px;
  }
  
}

@media screen and (max-width: 1100px) {
  .city-template-container {
    margin: 10px auto;
    height: 75vh;
  }

  
  .city-template-title {
    font-size: 15px;
  }

  .delete-city-template-icon {
   font-size: 12px;
   width: 12px;
}
  
  
  .city-template-item {
    height: 100px;
    width: 18%;
  }
  
  .new-city-text {
    font-size: 11px;
  }
  
  .city-template-name{
      font-size: 11.5px;
  }
  
}

@media screen and (max-width: 950px) {
  .city-template-container {
    margin: 10% auto;
    height: 60vh;
    width: 80%;
  }

  
  .city-template-title {
    font-size: 20px;
  }

  .delete-city-template-icon {
   font-size: 14px;
   width: 14px;
}
  
  
  .city-template-item {
    height: 120px;
    width: 23.4%;
  }
  
  .new-city-text {
    font-size: 12px;
  }
  
  .city-template-name{
      font-size: 13.5px;
  }
  
}

@media screen and (max-width: 850px) {
  .city-template-container {
    width: 90%;
  }

}

@media screen and (max-width: 600px) {
  .city-template-container {
    margin: 10px auto;
    height: 75vh;
    width: 100%;
  }

  
  .city-template-title {
    font-size: 16px;
  }

  .delete-city-template-icon {
   font-size: 12px;
   width: 12px;
}
  
.city-template-list {
  gap: 2%;
}
  
  .city-template-item {
    height: 120px;
    width: 31%;
    margin-bottom: 2%;
  }
  
  .new-city-text {
    font-size: 11px;
  }
  
  .city-template-name{
      font-size: 12px;
  }
  
}

@media screen and (max-width: 450px) {
  .city-template-section {
    padding:10px 5px;
}

.city-template-list {
  gap: 4%;
}
  
  .city-template-item {
    height: 110px;
    width: 46%;
    margin-bottom: 4%;
  }
  
  
}

